<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar confort térmico </h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #editResilienceForm="ngForm" (ngSubmit)="save()">
      <div class="form-row">
        <div class="form-group col-md-6">
          <label for="heat_reduction">Reducción de la temperatura del aire bajo dosel (%)</label>
          <input type="number" [(ngModel)]="specie.air_temp_reduction_pct" class="form-control" id="heat_reduction" name="heat_reduction" min="0">
        </div>
        <div class="form-group col-md-6">
          <label for="humidity">Incremento de la humedad relativa (%)</label>
          <input type="number" [(ngModel)]="specie.humidity_increase_pct" class="form-control" id="humidity" name="humidity" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3 col-sm-6">
          <label for="heat_reduction_pavement">Reducción de la temperatura de la acera (%)</label>
          <input type="number" [(ngModel)]="specie.pavement_temp_reduction_pct" class="form-control" id="heat_reduction_pavement" name="heat_reduction_pavement" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="heat_reduction_asphalt">Reducción de la temperatura del asfalto (%)</label>
          <input type="number" [(ngModel)]="specie.asphalt_temp_reduction_pct" class="form-control" id="heat_reduction_asphalt" name="heat_reduction_asphalt" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="heat_reduction_grass">Reducción de la temperatura del césped (%)</label>
          <input type="number" [(ngModel)]="specie.grass_temp_reduction_pct" class="form-control" id="heat_reduction_grass" name="heat_reduction_grass" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="heat_reduction_pipeclay">Reducción de la temperatura del albero (%)</label>
          <input type="number" [(ngModel)]="specie.pipeclay_temp_reduction_pct" class="form-control" id="heat_reduction_pipeclay" name="heat_reduction_pipeclay" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3 col-sm-6">
          <label for="radiation_pavement">Reducción de la temperatura radiante en acera (%)</label>
          <input type="number" [(ngModel)]="specie.pavement_temp_radiation_reduction_pct" class="form-control" id="radiation_pavement" name="radiation_pavement" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="radiation_asphalt">Reducción de la temperatura radiante en asfalto (%)</label>
          <input type="number" [(ngModel)]="specie.asphalt_temp_radiation_reduction_pct" class="form-control" id="radiation_asphalt" name="radiation_asphalt" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="radiation_grass">Reducción de la temperatura radiante en césped (%)</label>
          <input type="number" [(ngModel)]="specie.grass_temp_radiation_reduction_pct" class="form-control" id="radiation_grass" name="radiation_grass" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="radiation_pipeclay">Reducción de la temperatura radiante en albero (%)</label>
          <input type="number" [(ngModel)]="specie.pipeclay_temp_radiation_reduction_pct" class="form-control" id="radiation_pipeclay" name="radiation_pipeclay" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3 col-sm-6">
          <label for="radiation_pavement">Reducción del índice de estrés térmico en acera</label>
          <input type="number" [(ngModel)]="specie.wgtb_pavement" class="form-control" id="radiation_pavement" name="radiation_pavement" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="radiation_pavement">Reducción del índice de estrés térmico en asfalto</label>
          <input type="number" [(ngModel)]="specie.wgtb_asphalt" class="form-control" id="radiation_asphalt" name="radiation_asphalt" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="radiation_pavement">Reducción del índice de estrés térmico en césped</label>
          <input type="number" [(ngModel)]="specie.wgtb_grass" class="form-control" id="radiation_grass" name="radiation_grass" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="radiation_pavement">Reducción del índice de estrés térmico en albero</label>
          <input type="number" [(ngModel)]="specie.wgtb_pipeclay" class="form-control" id="radiation_pipeclay" name="radiation_pipeclay" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3 col-sm-6">
          <label for="uva_reduction">Reducción de rayos UVA (400-315 nm) (%)</label>
          <input type="number" [(ngModel)]="specie.uva_reduction_pct" class="form-control" id="uva_reduction" name="uva_reduction" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="uvbc_reduction">Reducción de rayos UV(B+C) (315-100 nm) (%)</label>
          <input type="number" [(ngModel)]="specie.uvbc_reduction_pct" class="form-control" id="uvbc_reduction" name="uvbc_reduction" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="solar_energy_reduction">Reducción de la radiación total (400-800 nm) (%)</label>
          <input type="number" [(ngModel)]="specie.solar_energy_reduction_pct" class="form-control" id="solar_energy_reduction" name="solar_energy_reduction" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="hf_em_radiation_attenuation">Reducción de radiaciones electromagnéticas de alta frecuencia (%)</label>
          <input type="number" [(ngModel)]="specie.hf_em_radiation_attenuation_pct" class="form-control" id="hf_em_radiation_attenuation" name="hf_em_radiation_attenuation" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3 col-sm-6">
          <label for="out_radiation_reduction_pavement_pct">Reducción de la radiación de salida en acera (%)</label>
          <input type="number" [(ngModel)]="specie.pavement_out_radiation_reduction_pct" class="form-control" id="out_radiation_reduction_pavement_pct" name="out_radiation_reduction_pavement_pct" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="out_radiation_reduction_asphalt_pct">Reducción de la radiación de salida en asfalto (%)</label>
          <input type="number" [(ngModel)]="specie.asphalt_out_radiation_reduction_pct" class="form-control" id="out_radiation_reduction_asphalt_pct" name="out_radiation_reduction_asphalt_pct" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="out_radiation_reduction_grass_pct">Reducción de la radiación de salida en césped (%)</label>
          <input type="number" [(ngModel)]="specie.grass_out_radiation_reduction_pct" class="form-control" id="out_radiation_reduction_grass_pct" name="out_radiation_reduction_grass_pct" min="0">
        </div>
        <div class="form-group col-md-3 col-sm-6">
          <label for="out_radiation_reduction_pipeclay_pct">Reducción de la radiación de salida en albero (%)</label>
          <input type="number" [(ngModel)]="specie.pipeclay_out_radiation_reduction_pct" class="form-control" id="out_radiation_reduction_pipeclay_pct" name="out_radiation_reduction_pipeclay_pct" min="0">
        </div>
      </div>
      
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
  </div>
</div>
