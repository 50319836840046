<div class="container-fluid d-flex">
  <div class="row d-flex login-page">
    <div class="col col-md-6 col-12 d-flex align-items-center">
      <img src="../../../assets/plat-logo.jpg" class="logoplat mx-auto">
      
      
    </div>
    <div class="col col-md-6 col-12 d-flex flex-column justify-content-center px-5">
      
      <div class="d-flex align-self-center my-5">
        <img src="../../../assets/logomarca.png" class="logomarca">
      </div>
      <div>
        <h1>Acceso</h1>
        <div>
          <p>Introduce tus datos de acceso</p>
        </div>
      </div>
      <form #loginForm="ngForm" (ngSubmit)="onSubmit()">
        <div class="form-group">
          <label for="username">E-mail</label>
          <input type="email" name="username" id="username" class="form-control" [(ngModel)]="credentials.email"
          required>
        </div>
        <div class="form-group">
          <label for="password">Contraseña</label>
          <input type="password" name="password" id="password" class="form-control" [(ngModel)]="credentials.password"
          required>
        </div>
        <button type="submit" class="btn btn-outline-success" [disabled]="!loginForm.form.valid">Acceder</button>
      </form>
    </div>
  </div>
  <div class="logofooter">
    <img src="../../../assets/logo_europa.jpg">
    <img src="../../../assets/logo_cdti.png">
  </div>
</div>
