<div class="container-fluid">
  <div class="row userlist--create-user align-items-center">
    <div class="col-auto justify-content-end">
      <button type="button" class="btn btn-outline-success" (click)="openCreateUserModal(createusermodal)" *ngIf="authUser.permissions.indexOf('authentication.add_user') >= 0">Dar
        de alta
        nuevo usuario</button>
    </div>
  </div>
  <div class="row">
    <div class="col">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Email</th>
            <th scope="col">Nombre</th>
            <th scope="col">Apellidos</th>
            <th scope="col">Acciones</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let user of users; index as i">
            <th scope="row">{{ i + 1 }}</th>
            <td>{{user.email}}</td>
            <td>{{user.firstName}}</td>
            <td>{{user.lastName}}</td>
            <td>
              <a class="btn btn-outline-primary" uiSref="home.permissions" [uiParams]="{userId: user.id}" *ngIf="authUser.permissions.indexOf('authentication.edit_permissions') >= 0">Ver
                permisos</a>
              <button type="button" class="btn btn-outline-danger" (click)="deleteUser(user)" *ngIf="authUser.permissions.indexOf('authentication.delete_user') >= 0">Dar
                de baja</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
<ng-template #createusermodal let-modal let-user>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Dar de alta nuevo usuario</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #createUserForm="ngForm" (ngSubmit)="modal.close(user)">
      <div class="form-group">
        <label for="userEmail">Email</label>
        <input type="email" [(ngModel)]="user.email" class="form-control" id="userEmail" name="email" placeholder="juan@gmail.com">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="modal.close(user)">Crear</button>
  </div>
</ng-template>

<ng-template #editPermissions let-modal let-permissions>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Dar de alta nuevo usuario</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #createUserForm="ngForm" (ngSubmit)="modal.close(user)">
      <div class="form-group">
        <label for="userEmail">Email</label>
        <input type="email" [(ngModel)]="user.email" class="form-control" id="userEmail" name="email" placeholder="juan@gmail.com">
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="modal.close(user)">Crear</button>
  </div>
</ng-template>
