<div class="welcome-page">
  <div class="welcome-holder">
    <div class="welcome-logo">
      <img src="../../../assets/logomarca.png">
    </div>
    <div class="welcome-message">
      <h1>Bienvenido</h1>
      <div *ngIf="!updated">

        <p>Bienvenido a la plataforma de planificación y gestión de espacios verdes de CESYT. Para completar el
          registro
          necesitamos los siguientes datos
        </p>
      </div>
      <div *ngIf="updated">
        <p>Introduce la contraseña que quieres usar para acceder al sistema. Debe tener:</p>
          <ul>
            <li>Al menos 8 caracteres</li>
            <li>Al menos un caracter no numérico</li>
            <li>No debe ser parecida a tu correo, nombre o apellidos</li>
          </ul>

      </div>
    </div>
    <div class="welcome-form">
      <form #createUserForm="ngForm" (ngSubmit)="saveUser()" *ngIf="!updated">
        <div class="form-group">
          <label for="userName">Nombre</label>
          <input type="text" [(ngModel)]="user.firstName" class="form-control" id="userName" placeholder="Juan" name="firstName">
        </div>
        <div class="form-group">
          <label for="userLastName">Apellidos</label>
          <input type="text" [(ngModel)]="user.lastName" class="form-control" id="userLastName" placeholder="Pérez"
            name="lastName">
        </div>
        <button type="submit" class="btn btn-outline-success">Continuar</button>
      </form>
      <form #setPasswordForm="ngForm" (ngSubmit)="setPassword()" *ngIf="updated">
        <div class="form-group">
          <label for="password1">Contraseña</label>
          <input type="password" [(ngModel)]="password1" class="form-control" id="password1" name="password1">
        </div>
        <div class="form-group">
          <label for="password2">Repite la contraseña</label>
          <input type="password" [(ngModel)]="password2" class="form-control" id="password2" name="password2">
        </div>
        <button type="submit" class="btn btn-outline-success">Crear contraseña</button>
      </form>
    </div>
  </div>
</div>
