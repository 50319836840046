import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Specie } from '../specie';
import { SpecieBiophilia } from '../specie-biophilia';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-editor-biophilia',
  templateUrl: './specie-editor-biophilia.component.html',
  styleUrls: ['./specie-editor-biophilia.component.sass']
})
export class SpecieEditorBiophiliaComponent implements OnInit {

  @Input() specie: Specie;
  @Input() biophiliaData: SpecieBiophilia;
  constructor(
    private activeModal: NgbActiveModal,
    private speciesService: SpecieService
  ) { }

  ngOnInit() {
  }
  save() {
      this.speciesService.updateBiophilia(this.biophiliaData).subscribe(
        biophiliaData => this.activeModal.close(biophiliaData),
        () => {}
      );
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
