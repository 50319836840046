import { Component, OnInit, Input } from '@angular/core';
import { SpecieService } from '../specie.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-specie-editor-base',
  templateUrl: './specie-editor-base.component.html',
  styleUrls: ['./specie-editor-base.component.sass']
})
export class SpecieEditorBaseComponent implements OnInit {
  @Input() specie;

  requiered = [
  ];

  requiered_list = [
    "Nombre cientifico",
    "Nombre común",
    "Tipo",
    "Tipo de hoja"
  ]

  treeTypes = [
    'Árbol',
    'Arbusto',
    'Herbácea',
    'Trepadora'
  ];
  leafKinds = [
    'Perenne',
    'Caduca'
  ];
  allergenicityLevels = [
    {value: 'BAJA', label: 'Baja'},
    {value: 'MEDIA', label: 'Media'},
    {value: 'ALTA', label: 'Alta'},
    {value: 'NULA', label: 'Nula'}
  ];
  constructor(
    private activeModal: NgbActiveModal,
    private speciesService: SpecieService
  ) { }

  ngOnInit() {
    this.specie.leaf_area = (Math.round(this.specie.leaf_area * 10000) / 10000).toFixed(4)
    this.specie.biomass_gr = (Math.round(this.specie.biomass_gr * 10000) / 10000).toFixed(3)
  }
  save(form: any) {
    if (!form.valid){
      return 
    }
    if (this.specie.id !== undefined) {
      this.specie.leaf_area = this.specie.leaf_area*10000
      this.specie.biomass_gr = this.specie.biomass_gr*1000
      this.speciesService.updateSpecie(this.specie).subscribe(
        specie => this.activeModal.close(specie),
        () => {}
      );
    } else {
        if (this.specie.leaf_area == 'NaN'){
          this.specie.leaf_area = null
        }
        if (this.specie.biomass_gr == 'NaN'){
          this.specie.biomass_gr = null
        }
        this.speciesService.createSpecie(this.specie).subscribe(
          specie => this.activeModal.close(specie),
          () => {}
        );
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  delete() {
    this.speciesService.deleteSpecie(this.specie).subscribe(
      () => this.activeModal.close(null)
    );
  }
}
