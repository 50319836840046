<div class="container-fluid">
    <div class="row userlist--create-user align-items-center">
      <div class="col-auto justify-content-end">
        <p>
          Editar permisos del usuario {{user.firstName}} {{user.lastName}}
        </p>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <table class="table table-striped">
          <thead>
            <tr>
              <th scope="col">Permiso</th>
              <th scope="col">Acciones</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let permission of permissionList; index as i">
              <td>{{permission}}</td>
              <td>
                <button type="button" class="btn btn-outline-primary" (click)="grant(permission)" *ngIf="userPermissions?.indexOf(permission) < 0">Otorgar</button>
                <button type="button" class="btn btn-outline-danger" (click)="revoke(permission)" *ngIf="userPermissions?.indexOf(permission) > -1">Revocar</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>


<div>


  <table class="table table-stripped">

  </table>
</div>
