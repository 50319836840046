import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { UIRouterModule } from '@uirouter/angular';

import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

const homeState = {name: 'home', url : '/home', component: HomeComponent, data: {requiresAuth: true}};

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    NgbDropdownModule,
    UIRouterModule.forChild({ states: [ homeState ]})
  ]
})
export class HomeModule { }
