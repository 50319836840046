import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { Specie } from '../specie';
import { SpecieEditorTermalComponent } from '../specie-editor-termal/specie-editor-termal.component';
import { SpecieThermalComfort } from '../specie-thermal-comfort';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-efficiency',
  templateUrl: './specie-efficiency.component.html',
  styleUrls: ['./specie-efficiency.component.sass']
})
export class SpecieEfficiencyComponent implements OnInit {
  @Input() specie: Specie;

  user: User;
  shadow_area: number;
  efficiencyData: SpecieThermalComfort;

  constructor(
      private modalService: NgbModal,
      private speciesService: SpecieService,
      private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.user;
    this.speciesService.getEfficiency(this.specie.id).subscribe(
      (data => this.efficiencyData = data)
    );
    this.shadow_area = this.specie.calculate_shadow_area();
  }

  editEfficiency() {
    const modalRef = this.modalService.open(SpecieEditorTermalComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.specie = Object.assign({}, this.efficiencyData);
    modalRef.result.then(
      (newSpecie) => Object.assign(this.efficiencyData, newSpecie),
      (reason: any) => {}
    );
  }
}
