<div class="home">
  <nav class="navbar navbar-expand-lg ">
    <a class="navbar-brand" href="javascript:void(0);">
        <img src="../../assets/plat-logo.jpg" height="45" alt="">
      </a>
    <ul class="navbar-nav mr-auto">
      <li class="nav-item" uiSrefActive="active" *ngIf="user.permissions.indexOf('authentication.read_users') >= 0">
        <a class="nav-link" uiSref="home.userlist"><i class="fas fa-user-friends"></i> Usuarios</a>
      </li>
      <li class="nav-item" uiSrefActive="active" >
        <a class="nav-link" uiSref="home.ranges"><i class="fa fa-compass"></i> Rangos</a>
      </li>
      <li class="nav-item" uiSrefActive="active" >
        <a class="nav-link" uiSref="home.species"><i class="fab fa-pagelines"></i> Especies</a>
      </li>
      <li class="nav-item" uiSrefActive="active" >
        <a class="nav-link" uiSref="home.spaces"><i class="far fa-map"></i> Infraestructuras verdes</a>
      </li>
    </ul>
    <div class="nav-item dropdown" ngbDropdown>
      <span class="navbar-nav">
        <a class="nav-link clickable" href="javascript:void(0);" ngbDropdownToggle><i class="fas fa-user-circle"></i> {{user.firstName}} {{user.lastName}}</a>
        <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
          <span class="dropdown-item clickable nav-link" (click)="logout()">Salir</span>
        </div>
      </span>
      </div>
  </nav>
  <ui-view name="content"></ui-view>
</div>
