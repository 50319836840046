import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecieService } from '../specie.service';
import { Specie } from '../specie';
import { SpecieCo2 } from '../specie-co2-absorption';

@Component({
  selector: 'app-specie-editor-co2-absorption',
  templateUrl: './specie-editor-co2-absorption.component.html',
  styleUrls: ['./specie-editor-co2-absorption.component.sass']
})
export class SpecieEditorCo2AbsorptionComponent implements OnInit {

  @Input() specie: Specie;
  @Input() co2AbsorptionData: SpecieCo2;
  constructor(
    private activeModal: NgbActiveModal,
    private specieService: SpecieService
  ) { }

  ngOnInit(): void {
  }

  save() {
    this.specieService.updateCo2Absorption(this.co2AbsorptionData).subscribe(
      co2AbsorptionData => this.activeModal.close(co2AbsorptionData),
      () => {}
    );
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
