import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpaceService } from '../space.service';
import { Zone } from '../zone';

@Component({
  selector: 'app-zone-editor',
  templateUrl: './zone-editor.component.html',
  styleUrls: ['./zone-editor.component.sass']
})
export class ZoneEditorComponent implements OnInit {
  @Input() zone: Zone;
  plantationFrames = [
    {value: 'ALI_VIA', label: 'Alineaciones en viario: distancia de 6-7 metros entre árboles'},
    {value: 'ALI_PRQ', label: 'Alineaciones en parques: distancia de 4-5 metros entre árboles'},
    {value: 'BOS_PRQ', label: 'Bosquetes en parques: distancia de 2 m entre árboles'},
    {value: 'SET_TOD', label: 'Setos (tanto en parques como en viarios): 3 árboles/metro lineal'},
    {value: 'MAZ_PRQ', label: 'Macizos de arbustos en parques: 2 arbustos/ metro lineal'}
  ];
  constructor(
    private activeModal: NgbActiveModal,
    private spacesService: SpaceService
  ) { }

  ngOnInit(): void {
  }
  dismiss() {
    this.activeModal.dismiss();
  }

  save() {
    if (!this.zone.id) {
      this.spacesService.createZone(this.zone).subscribe(
        zone => this.activeModal.close(zone),
        () => {}
      );
    } else {
      this.spacesService.updateZone(this.zone).subscribe(
        zone => this.activeModal.close(zone),
        () => {}
      );
    }
  }

}
