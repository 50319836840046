import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../message.service';
import { Distribution } from './distribution';
import { Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { tap, catchError, map } from 'rxjs/operators';
import { Message } from '../message';
import { SpecieService } from '../species/specie.service';

@Injectable({
  providedIn: 'root'
})
export class DistributionService {

  constructor(
    private http: HttpClient,
    private specieService: SpecieService,
    private messagesService: MessageService
  ) {
    this.specieService.listSpecies();
  }

  createDistribution(distribution: Distribution): Observable<any> {
    const url = `${environment.serverUrl}/distributions/`;
    return this.http.post<Distribution>(url, distribution.toServer()).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Creado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  updateDistribution(distribution: Distribution): Observable<Distribution> {
    const url = `${environment.serverUrl}/distributions/${distribution.id}/`;
    return this.http.patch<Distribution>(url, distribution.toServer()).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  removeDistribution(distribution: Distribution): Observable<any> {
    const url = `${environment.serverUrl}/distributions/${distribution.id}/`;
    return this.http.delete(url).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Eliminado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  loadZoneDistribution(zoneId: number): Observable<any> {
    const url = `${environment.serverUrl}/distributions/zone/${zoneId}/`;
    return this.http.get(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  getAsimilatedSpecies(): Observable<any> {
    const url = `${environment.serverUrl}/species/asimilated`;
    return this.http.get(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  hidrateDistribution(jsonData: any): Observable<Distribution> {
    return new Distribution(this.specieService).fromServer(jsonData);
  }
}
