<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Datos de la infraestructura</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #editSpaceForm="ngForm" (ngSubmit)="save()">
      <div class="form-row">
        <div class="form-group col-sm-12">
          <label for="name">Nombre</label>
          <input type="text" [(ngModel)]="space.name" class="form-control" id="name" name="name"
            placeholder="Parcela Villaverde">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-3 col-sm-12">
          <label for="space.kind">Tipo</label>
          <select class="form-control" required [(ngModel)]="space.kind" name="kind">
            <option value="NEW">Nueva</option>
            <option value="EXIS">Existente</option>
          </select>
        </div>
        <div class="form-group col-md-3 col-sm-12">
          <label for="country">País</label>
          <input type="text" [(ngModel)]="space.country" class="form-control" id="country" name="country"
            placeholder="España">
        </div>
        <div class="form-group col-md-3 col-sm-12">
          <label for="region">Región</label>
          <input type="text" [(ngModel)]="space.region" class="form-control" id="region" name="region"
            placeholder="Madrid">
        </div>
        <div class="form-group col-md-3 col-sm-12">
          <label for="city">Ciudad</label>
          <input type="text" [(ngModel)]="space.city" class="form-control" id="city" name="city" placeholder="Madrid">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="altitude">Altitud (m)</label>
          <input type="number" step="any" [(ngModel)]="space.height" class="form-control" id="altitude" name="altitude"
            placeholder="654">
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="latitude">Latitud (º)</label>
          <input type="number" step="any" [(ngModel)]="space.lat" class="form-control" id="latitude" name="latitude"
            placeholder="43.5">
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="longitude">Longitud (º)</label>
          <input type="number" step="any" [(ngModel)]="space.lon" class="form-control" id="longitude" name="longitude"
            placeholder="43.5">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="area">Superficie (m<sup>2</sup>)</label>
          <input type="number" step="any" [(ngModel)]="space.area" class="form-control" id="area" name="area"
            placeholder="43.5">
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="useful_surface">Superficie útil (m<sup>2</sup>)</label>
          <input type="number" step="any" [(ngModel)]="space.useful_surface" class="form-control" id="useful_surface" name="useful_surface"
              placeholder="43.5">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="plantation_frame">Modelo de plantación</label>
          <select class="form-control" id="plantation_frame" required [(ngModel)]="space.plantation_frame" name="plantation_frame">
            <option *ngFor="let frame of plantationFrames" [value]="frame.value">{{frame.label}}</option>
          </select>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" *ngIf="space.id" (click)="delete()">Eliminar</button>
    <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
    <div *ngIf="errorMessage" class="alert alert-danger">
      {{ errorMessage }}
    </div>
  </div>
</div>
