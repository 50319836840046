import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { Credentials } from './credentials';
import { User } from './user';
import { Observable, of } from 'rxjs';
import { MessageService } from '../message.service';
import { Message } from '../message';


const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' })
};

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  user: User;

  constructor(
      private http: HttpClient,
      private messageService: MessageService
  ) { }

  authenticate(credentials: Credentials): Observable<User> {
    const url = environment.serverUrl + '/auth/authenticate/';
    return this.http.post<User>(url, credentials, httpOptions).pipe(
      tap((user: User) => {
        this.user = user;
        localStorage.setItem('token', user.token);
        return this.user;
      }),
      catchError((httpError: HttpErrorResponse) => {
        httpError.error.non_field_errors.forEach(error => {
          this.messageService.add(new Message('error', error));
        });
        throw httpError;
      })
    );
  }

  logout(): void {
    this.user = undefined;
    localStorage.removeItem('token');
  }

  isAuthenticated(): boolean {
    return this.user !== undefined;
  }

  getToken(): string {
    if (this.isAuthenticated()) {
      return this.user.token;
    }
  }

  activateUser(userToken: string): Observable<User> {
    const url = environment.serverUrl + '/auth/activate/';
    const data = {
      token: userToken
    };
    return this.http.post<User>(url, data).pipe(
      tap((user: User) => {
        this.user = user;
        localStorage.setItem('token', user.token);
        this.messageService.add(new Message('ok', 'Cuenta activada'));
        return this.user;
      }),
      catchError((httpError: HttpErrorResponse) => {
        httpError.error.non_field_errors.forEach(error => this.messageService.add(new Message('error', error)));
        throw httpError;
      })
    );
  }

  saveUser(user: User) {
    const url = environment.serverUrl + '/auth/update/';
    return this.http.patch(url, user).pipe(
      tap((updatedUser: User) => {
        this.user.email = updatedUser.email;
        this.user.firstName = updatedUser.firstName;
        this.user.lastName = updatedUser.lastName;
        this.messageService.add(new Message('ok', 'Usuario actualizado'));
        return this.user;
      }),
      catchError((httpError: HttpErrorResponse) => {
        Object.keys(httpError.error).forEach(errorType =>
          httpError.error[errorType].forEach(message =>
            this.messageService.add(new Message('error', message))
          )
        );
        throw httpError;
      })
    );
  }

  setPassword(newPassword1: string, newPassword2: string) {
    const url = environment.serverUrl + '/auth/setpassword/';
    const data = {new_password1: newPassword1, new_password2: newPassword2};
    return this.http.post(url, data).pipe(
      tap(() => this.messageService.add(new Message('ok', 'Contraseña guardada'))),
      catchError((httpError: HttpErrorResponse) => {
        Object.keys(httpError.error).forEach(errorType =>
          httpError.error[errorType].forEach(message =>
            this.messageService.add(new Message('error', message))
          )
        );
        throw httpError;
      })
    );

  }
  verify(): Observable<User> {
    const url = `${environment.serverUrl}/auth/verify/`;
    const data = {token: localStorage.getItem('token')};
    return this.http.post<User>(url, data).pipe(
      tap((user: User) => {
        this.user = user;
        localStorage.setItem('token', user.token);
        return user;
      })
    );
  }
}
