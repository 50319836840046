<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar resiliencia de {{ specie.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #editResilienceForm="ngForm" (ngSubmit)="save()">
      <div class="form-row">
        <div class="form-group col-md-3">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="freeze_tolerance" [(ngModel)]="specie.freeze_tolerance"
              name="freeze_tolerance">
            <label class="custom-control-label" for="freeze_tolerance">Tolerante a las heladas</label>
          </div>
        </div>
        <div class="form-group col-md-3">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="shadow_tolerance" [(ngModel)]="specie.shadow_tolerance"
              name="shadow_tolerance">
            <label class="custom-control-label" for="shadow_tolerance">Tolerante a la sombra</label>
          </div>
        </div>
        <div class="form-group col-md-3">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="compact_terrain_tolerance" [(ngModel)]="specie.compact_terrain_tolerance"
              name="compact_terrain_tolerance">
            <label class="custom-control-label" for="compact_terrain_tolerance">Tolerante a suelos pobres</label>
          </div>
        </div>
        <div class="form-group col-md-3">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="saline_terrain_tolerance" [(ngModel)]="specie.saline_terrain_tolerance"
              name="saline_terrain_tolerance">
            <label class="custom-control-label" for="saline_terrain_tolerance">Tolerante a suelos salinos</label>
          </div>
        </div>
        <div class="form-group col-md-12">
          <label for="plague_tolerance">Tolerancia a plagas y enfermedades</label>
          <textarea class="form-control" rows="4" [(ngModel)]="specie.plague_tolerance" name="plague_tolerance" id="plague_tolerance"></textarea>
        </div>
      </div>
      <div class="form-row">
      <div class="form-group col-md-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="drought_resistance_se" [(ngModel)]="specie.drought_resistance_se"
            name="drought_resistance_se">
          <label class="custom-control-label" for="drought_resistance_se">Tolerante a la sequedad elevada</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="drought_resistance_sm" [(ngModel)]="specie.drought_resistance_sm"
            name="drought_resistance_sm">
          <label class="custom-control-label" for="drought_resistance_sm">Tolerante a la sequedad media</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="drought_resistance_sd" [(ngModel)]="specie.drought_resistance_sd"
            name="drought_resistance_sd">
          <label class="custom-control-label" for="drought_resistance_sd">Tolerante a la sequedad débil</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="drought_resistance_hd" [(ngModel)]="specie.drought_resistance_hd"
            name="drought_resistance_hd">
          <label class="custom-control-label" for="drought_resistance_hd">Tolerante a la humedad débil</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="drought_resistance_hm" [(ngModel)]="specie.drought_resistance_hm"
            name="drought_resistance_dm">
          <label class="custom-control-label" for="drought_resistance_hm">Tolerante a la humedad media</label>
        </div>
      </div>
      <div class="form-group col-md-2">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" id="drought_resistance_he" [(ngModel)]="specie.drought_resistance_he"
            name="drought_resistance_he">
          <label class="custom-control-label" for="drought_resistance_he">Tolerante a la humedad elevada</label>
        </div>
      </div>
    </div>
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <label for="heat_tolerance_10">Tolerancia térmica (10ºC)</label>
          <input type="number" [(ngModel)]="specie.heat_tolerance_10" class="form-control" id="heat_tolerance_10" name="heat_tolerance_10" min="0" max="5">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="heat_tolerance_20">Tolerancia térmica (20ºC)</label>
          <input type="number" [(ngModel)]="specie.heat_tolerance_20" class="form-control" id="heat_tolerance_20" name="heat_tolerance_20" min="0" max="5">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="heat_tolerance_30">Tolerancia térmica (30ºC)</label>
          <input type="number" [(ngModel)]="specie.heat_tolerance_30" class="form-control" id="heat_tolerance_30" name="heat_tolerance_30" min="0" max="5">
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <label for="wue_10">WUE (10ºC)</label>
          <input type="number" [(ngModel)]="specie.wue_10" class="form-control" id="wue_10" name="wue_10" min="0" max="5">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="wue_20">WUE (20ºC)</label>
          <input type="number" [(ngModel)]="specie.wue_20" class="form-control" id="wue_20" name="wue_20" min="0" max="5">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="wue_30">WUE (30ºC)</label>
          <input type="number" [(ngModel)]="specie.wue_30" class="form-control" id="wue_30" name="wue_30" min="0" max="5">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <label for="stability_index">Índice de estabilidad</label>
          <input type="number" [(ngModel)]="specie.stability_index" class="form-control" id="stability_index" name="stability_index" min="0" max="5">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="stability_index_ranking">Ranking índice de estabilidad</label>
          <input type="number" [(ngModel)]="specie.stability_index_ranking" class="form-control" id="stability_index_ranking" name="stability_index_ranking" min="0" max="5">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
  </div>
</div>
