<div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Datos de la zona {{zone.id}}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #editSpaceForm="ngForm" (ngSubmit)="save()">
        <div class="form-row">
          <div class="form-group col-lg-12">
            <label for="name">Nombre</label>
            <input type="text" [(ngModel)]="zone.name" class="form-control" id="name" name="name"
              placeholder="Zona 1">
          </div>
        </div>
        
        <div class="form-row">
          <div class="form-group col-lg-6 ">
            <label for="area">Superficie total(m<sup>2</sup>)</label>
            <input type="number" step="any" [(ngModel)]="zone.total_surface" class="form-control" id="area" name="area"
              placeholder="43.5">
          </div>
          <div class="form-group col-lg-6">
            <label for="useful_surface">Superficie útil (m<sup>2</sup>)</label>
            <input type="number" step="any" [(ngModel)]="zone.useful_surface" class="form-control" id="useful_surface" name="useful_surface"
                placeholder="43.5">
          </div>
          <div class="form-group col-lg-4">
            <label for="plantation_frame">Modelo de plantación</label>
            <select class="form-control" id="plantation_frame" required [(ngModel)]="zone.plantation_frame" name="plantation_frame">
              <option *ngFor="let frame of plantationFrames" [value]="frame.value">{{frame.label}}</option>
            </select>
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
    </div>
  </div>
  