import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { Specie } from '../specie';
import { SpecieService } from '../specie.service';
import { SpecieCo2 } from '../specie-co2-absorption';
import { SpecieEditorCo2AbsorptionComponent } from '../specie-editor-co2-absorption/specie-editor-co2-absorption.component';

@Component({
  selector: 'app-specie-co2-absorption',
  templateUrl: './specie-co2-absorption.component.html',
  styleUrls: ['./specie-co2-absorption.component.sass']
})
export class SpecieCo2AbsorptionComponent implements OnInit {
  @Input() specie: Specie;

  co2AbsorptionData: SpecieCo2;
  user: User;

  constructor(
    private modalService: NgbModal,
    private specieService: SpecieService,
    private authService: AuthService
  ) { }

  ngOnInit(): void {
    this.user = this.authService.user;
    this.specieService.getCo2Absorption(this.specie.id).subscribe(
      (data => this.co2AbsorptionData = data)
    );
  }

  editCo2Absorption() {
    const modalRef = this.modalService.open(SpecieEditorCo2AbsorptionComponent, { size: 'xl' as 'lg' });
    modalRef.componentInstance.specie = Object.assign({}, this.specie);
    modalRef.componentInstance.co2AbsorptionData = Object.assign({}, this.co2AbsorptionData);
    modalRef.result.then(
      (co2AbsorptionData: SpecieCo2) => Object.assign(this.co2AbsorptionData, co2AbsorptionData),
      (reason: any) =>{}
    );
  }
}
