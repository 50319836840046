import { Component, OnInit, Input } from '@angular/core';
import { SpaceService } from '../space.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpaceDistributionComponent } from '../space-distribution/space-distribution.component';
import { Distribution } from '../distribution';
import { SpaceEditorComponent } from '../space-editor/space-editor.component';
import { StateService } from '@uirouter/core';
import { Installation } from '../installation';
import { InstallationLoaderComponent } from '../installation-loader/installation-loader.component';
import { SpaceClimaEditorComponent } from '../space-clima-editor/space-clima-editor.component';
import { SpacePollutionEditorComponent } from '../space-pollution-editor/space-pollution-editor.component';
import { SpecieAbsorption } from 'src/app/species/specie-absorption';
import { DistributionService } from '../distribution.service';
import { SpecieService } from 'src/app/species/specie.service';
import { Zone } from '../zone';
import { ZoneEditorComponent } from '../zone-editor/zone-editor.component';
import * as XLSX from 'xlsx';
import { MessageService } from 'src/app/message.service';
import { Message } from 'src/app/message';
import { DistributionPreviewComponent } from '../distribution-preview/distribution-preview.component';
import { SpecieCo2 } from 'src/app/species/specie-co2-absorption';
import { ReportDownloaderComponent } from '../report-downloader/report-downloader.component';

@Component({
  selector: 'app-space-detail',
  templateUrl: './space-detail.component.html',
  styleUrls: ['./space-detail.component.scss']
})
export class SpaceDetailComponent implements OnInit {
  @Input() space;
  plantationFrames = {
    ALI_VIA: 'Alineaciones en viario: distancia de 6-7 metros entre árboles',
    ALI_PRQ: 'Alineaciones en parques: distancia de 4-5 metros entre árboles',
    BOS_PRQ: 'Bosquetes en parques: distancia de 2 m entre árboles',
    SET_TOD: 'Setos (tanto en parques como en viarios): 3 árboles/metro lineal',
    MAZ_PRQ: 'Macizos de arbustos en parques: 2 arbustos/ metro lineal'
  };
  zonesDistribution: any = {};
  selectedZone = 'total';
  infraTotal: SpecieAbsorption;
  zoneTotal: any = {};
  installations: Installation[];
  visualization = 0;
  dataView = -1;
  speciesInventory: any[] = [];
  zones: Zone[] = [];
  active = 'characteristics';
  showZones = false;
  units: string = 'g/m2'; 

  constructor(
    private distributionService: DistributionService,
    private spaceService: SpaceService,
    private modalService: NgbModal,
    private $state: StateService,
    private specieService: SpecieService,
    private messagesService: MessageService
  ) { }

  ngOnInit() {
    this.spaceService.listSpaceZones(this.space.id).subscribe(
      (zones: []) => {
        this.zones.push(...zones);
        this.showZones = this.zones.length > 1;
        this.calculateDistributionSum();
      }
    );
  }

  calculateDistributionSum() {
    this.infraTotal = new SpecieAbsorption();
    this.zoneTotal = {};
    this.zonesDistribution = {
      total: {
        distribution: [],
        absorption: new SpecieAbsorption(),
        co2: new SpecieCo2(0,0,0,0,0,0,0,0,0,0),
        amount: 0,
        leaf_area: 0,
        biomass_gr: 0,
        shadow_area: 0
      }
    };

    this.speciesInventory.splice(0);
    this.zones.forEach((zone: Zone) => {
      this.zonesDistribution[zone.id] = {
        distribution: [],
        absorption: new SpecieAbsorption(),
        co2: new SpecieCo2(0,0,0,0,0,0,0,0,0,0),
        amount: 0,
        leaf_area: 0,
        biomass_gr: 0,
        shadow_area: 0
      };
      this.distributionService.loadZoneDistribution(zone.id).subscribe(
        zoneDistribution => {
          zoneDistribution.forEach(specieDistribution => {
            this.distributionService.hidrateDistribution(specieDistribution).subscribe((distribution: Distribution) => {
              distribution.zone = zone.name;
              this.zonesDistribution[zone.id].absorption.co_absorption += distribution.co_absorption;
              this.zonesDistribution[zone.id].absorption.no_absorption += distribution.no_absorption;
              this.zonesDistribution[zone.id].absorption.no2_absorption += distribution.no2_absorption;
              this.zonesDistribution[zone.id].absorption.so2_absorption += distribution.so2_absorption;
              this.zonesDistribution[zone.id].absorption.pm1_absorption += distribution.pm1_absorption;
              this.zonesDistribution[zone.id].absorption.pm2_5_absorption += distribution.pm2_5_absorption;
              this.zonesDistribution[zone.id].absorption.pm10_absorption += distribution.pm10_absorption;
              this.zonesDistribution[zone.id].absorption.cov_emission +=  distribution.cov_emission;
              
              this.zonesDistribution[zone.id].co2.co2_absorption +=  distribution.co2_absorption;
              this.zonesDistribution[zone.id].co2.co2_5_years_projection += distribution.co2_5_years_projection;
              this.zonesDistribution[zone.id].co2.co2_10_years_projection += distribution.co2_10_years_projection;
              this.zonesDistribution[zone.id].co2.co2_15_years_projection += distribution.co2_15_years_projection;
              this.zonesDistribution[zone.id].co2.co2_20_years_projection += distribution.co2_20_years_projection;
              this.zonesDistribution[zone.id].co2.co2_25_years_projection += distribution.co2_25_years_projection;
              this.zonesDistribution[zone.id].co2.co2_30_years_projection += distribution.co2_30_years_projection;
              this.zonesDistribution[zone.id].co2.co2_35_years_projection += distribution.co2_35_years_projection;
              this.zonesDistribution[zone.id].co2.co2_40_years_projection += distribution.co2_40_years_projection;
              
              

              this.zonesDistribution[zone.id].distribution.push(distribution);
              this.zonesDistribution[zone.id].amount += distribution.amount;
              this.zonesDistribution[zone.id].leaf_area += distribution.leaf_area;
              this.zonesDistribution[zone.id].biomass_gr += distribution.biomass_gr;
              this.zonesDistribution[zone.id].shadow_area += distribution.shadow_area;
              distribution.inventory.forEach((specieUnit: any) => this.zonesDistribution[zone.id].distribution.push(specieUnit));


              this.zonesDistribution.total.absorption.co_absorption += distribution.co_absorption;
              this.zonesDistribution.total.absorption.no_absorption += distribution.no_absorption;
              this.zonesDistribution.total.absorption.no2_absorption += distribution.no2_absorption;
              this.zonesDistribution.total.absorption.so2_absorption += distribution.so2_absorption;
              this.zonesDistribution.total.absorption.pm1_absorption += distribution.pm1_absorption;
              this.zonesDistribution.total.absorption.pm2_5_absorption += distribution.pm2_5_absorption;
              this.zonesDistribution.total.absorption.pm10_absorption += distribution.pm10_absorption;
              this.zonesDistribution.total.absorption.cov_emission +=  distribution.cov_emission;
              
              this.zonesDistribution.total.co2.co2_absorption +=  distribution.co2_absorption;
              this.zonesDistribution.total.co2.co2_5_years_projection += distribution.co2_5_years_projection;
              this.zonesDistribution.total.co2.co2_10_years_projection += distribution.co2_10_years_projection;
              this.zonesDistribution.total.co2.co2_15_years_projection += distribution.co2_15_years_projection;
              this.zonesDistribution.total.co2.co2_20_years_projection += distribution.co2_20_years_projection;
              this.zonesDistribution.total.co2.co2_25_years_projection += distribution.co2_25_years_projection;
              this.zonesDistribution.total.co2.co2_30_years_projection += distribution.co2_30_years_projection;
              this.zonesDistribution.total.co2.co2_35_years_projection += distribution.co2_35_years_projection;
              this.zonesDistribution.total.co2.co2_40_years_projection += distribution.co2_40_years_projection;
              
              

              this.zonesDistribution.total.distribution.push(distribution);
              this.zonesDistribution.total.amount += distribution.amount;
              this.zonesDistribution.total.leaf_area += distribution.leaf_area;
              this.zonesDistribution.total.biomass_gr += distribution.biomass_gr;
              this.zonesDistribution.total.shadow_area += distribution.shadow_area;
              distribution.inventory.forEach((specieUnit: any) => this.zonesDistribution.total.distribution.push(specieUnit));
            });
          });
      });
    });
    if (this.zones.length === 1) {
      this.selectedZone = this.zones[0].id.toString();
    }
  }

  openEditSpecieDistributionModal(distribution = new Distribution(this.specieService)) {
    const modal = this.modalService.open(SpaceDistributionComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.distribution = distribution;
    modal.componentInstance.spaceId = this.space.id;
    modal.result.then((dist: Distribution) => {
      if (dist) {
        this.spaceService.addDistribution(parseInt(this.selectedZone, 10), dist.id).subscribe(data => {
          this.calculateDistributionSum();
        });
      } else {
        this.calculateDistributionSum();
      }
    },
    () => {});
  }

  editPollution() {
    const modalRef = this.modalService.open(SpacePollutionEditorComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.space = Object.assign({}, this.space);
    modalRef.result.then(
      space => this.space = Object.assign({}, space),
      () => {}
    );
  }

  editClima() {
    const modalRef = this.modalService.open(SpaceClimaEditorComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.space = Object.assign({}, this.space);
    modalRef.result.then(
      space => this.space = Object.assign({}, space),
      () => {}
    );
  }
  editCaracterization() {
    const modalRef = this.modalService.open(SpaceEditorComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.space = Object.assign({}, this.space);
    modalRef.result.then(
      space => {
        if (space === null) {
          this.$state.go('home.spaces');
        } else {
          this.space = Object.assign({}, space);
        }
      },
      () => {}
    );
  }

  showInstallations() {
    const modalRef = this.modalService.open(InstallationLoaderComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.space = Object.assign({}, this.space);
    modalRef.result.then(
      space => this.space = Object.assign({}, space),
      () => {}
    );
  }


  workInZones() {
    this.showZones = true;
  }

  openEditZoneModal(zone = new Zone()) {
    zone.space = this.space.id;
    const modal = this.modalService.open(ZoneEditorComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.zone = zone;
    modal.result.then(
      (updatedZone: Zone) => {
        let found = false;
        this.zones.forEach((zoneInList, i) => {
          if (zoneInList.id === updatedZone.id) {
            this.zones[i] = updatedZone;
            found = true;
            return;
          }
        });
        if (!found) {
          this.zones.push(updatedZone);
        }
      },
      () => {}
    );
  }
  deleteZone(zone) {
    this.spaceService.deleteZone(zone).subscribe(
      () => {
        this.zones.splice(this.zones.indexOf(zone), 1);
      }
    );
  }

  onSelectDistributionFile($event: any) {
    const target: DataTransfer = ($event.target);
    if (target.files.length !== 1) {
      this.messagesService.add(new Message('error', 'No se puede cargar más de un fichero a la vez.'));
    } else {
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        /* read workbook */
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

        /* grab first sheet */
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];

        /* save data */
        const data: any[] = XLSX.utils.sheet_to_json(ws);
        const modal = this.modalService.open(DistributionPreviewComponent, { size: 'xl' as 'lg' });
        modal.componentInstance.data = data;
        if ( this.workInZones) {
          modal.componentInstance.zoneId = parseInt(this.selectedZone, 10);
        } else {
          modal.componentInstance.zoneId = this.zones[0].id;
        }
        modal.result.then(
          () => this.calculateDistributionSum(),
          () => this.calculateDistributionSum());
      };
      reader.readAsBinaryString(target.files[0]);
    }

  }

  getDistributionReport() {
    const modal = this.modalService.open(ReportDownloaderComponent, { size: 'xl' as 'lg' });
    modal.componentInstance.units = this.units;
    modal.componentInstance.space = this.space;
    modal.componentInstance.selectedZone = this.selectedZone;
    modal.componentInstance.zones = this.zones;
    modal.componentInstance.download_options = 1; // All zones
    modal.result.then(
      () => {},
      () => {}
    );
  }
  toggleRadio(value: number): void {
    if (this.dataView === value) {
      this.dataView = -1;
    } else {
      this.dataView = value;
    }
  }
}
