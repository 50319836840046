import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { Specie } from '../specie';
import { SpecieBiodiversity } from '../specie-biodiversity';
import { SpecieEditorBiodiversityComponent } from '../specie-editor-biodiversity/specie-editor-biodiversity.component';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-biodiversity',
  templateUrl: './specie-biodiversity.component.html',
  styleUrls: ['./specie-biodiversity.component.sass']
})
export class SpecieBiodiversityComponent implements OnInit {
  @Input() specie: Specie;

  biodiversityData: SpecieBiodiversity;
  user: User;

  constructor(
    private modalService: NgbModal,
    private speciesService: SpecieService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.user;
    this.speciesService.getBiodiversity(this.specie.id).subscribe(
      (data => this.biodiversityData = data)
    );
  }
  editBiodiversity() {
    const modalRef = this.modalService.open(SpecieEditorBiodiversityComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.specie = Object.assign({}, this.specie);
    modalRef.componentInstance.biodiversityData = Object.assign({}, this.biodiversityData);
    modalRef.result.then(
      (updatedBiodiversity: SpecieBiodiversity) => Object.assign(this.biodiversityData, updatedBiodiversity),
      (reason: any) => {}
    );
  }
}
