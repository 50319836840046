<div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Datos de contaminantes</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #editSpaceForm="ngForm" (ngSubmit)="save()">
        <div class="form-row">
          <div class="form-group col-md-2 col-sm-12">
            <label for="NO">NO(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.NO" class="form-control" id="NO" name="NO" placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="NO2">NO<sub>2</sub>(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.NO2" class="form-control" id="NO2" name="NO2"
              placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="SO2">SO<sub>2</sub>(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.SO2" class="form-control" id="SO2" name="SO2"
              placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="CO">CO(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.CO" class="form-control" id="CO" name="CO" placeholder="5">
          </div>
  
        </div>
        <div class="form-row">
          <div class="form-group col-md-2 col-sm-12">
            <label for="CO2">CO<sub>2</sub>(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.CO2" class="form-control" id="CO2" name="CO2"
              placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="PM1">PM<sub>1</sub>(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.PM1" class="form-control" id="PM1" name="PM1"
              placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="PM2_5">PM<sub>2.5</sub>(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.PM2_5" class="form-control" id="PM2_5" name="PM2_5"
              placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="PM10">PM<sub>10</sub>(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.PM10" class="form-control" id="PM10" name="PM10"
              placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="PST">PST(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.PST" class="form-control" id="PST" name="PST"
              placeholder="5">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-2 col-sm-12">
            <label for="O3">O<sub>3</sub>(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.O3" class="form-control" id="O3" name="O3" placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="COV">COV(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.COV" class="form-control" id="COV" name="COV"
              placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="Pb">Pb(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.Pb" class="form-control" id="Pb" name="Pb" placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="Cd">Cd(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.Cd" class="form-control" id="Cd" name="Cd" placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="As">As(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.As" class="form-control" id="As" name="As" placeholder="5">
          </div>
          <div class="form-group col-md-2 col-sm-12">
            <label for="Ni">Ni(&#181;g/m<sup>3</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.Ni" class="form-control" id="Ni" name="Ni" placeholder="5">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
    </div>
  </div>
  