import { SpecieAggregator } from '../species/specie-aggregator';
import { RangesService } from '../ranges/ranges.service';
import { Range } from '../ranges/ranges';

export class ResilienceFilter {

    numeric_fields = [
        'heat_tolerance_10', 'heat_tolerance_20', 'heat_tolerance_30'
    ];
    boolean_fields = [
        'freeze_tolerance', 'shadow_tolerance', 'compact_terrain_tolerance', 'saline_terrain_tolerance'
    ];
    one_of_fields = [
        'drought_resistance_se', 'drought_resistance_sm', 'drought_resistance_sd',
        'drought_resistance_hd', 'drought_resistance_hm', 'drought_resistance_he'
    ];
    possible_rangeable_fields = [
        'wue_10', 'wue_20', 'wue_30','heat_tolerance_10', 'heat_tolerance_20', 'heat_tolerance_30'
    ];
    fields_with_index_etiquete = [
        'wue_10', 'wue_20', 'wue_30'
    ]

    constructor(
        public id?: number,
        public heat_tolerance_10: number = 1,
        public heat_tolerance_20: number = 1,
        public heat_tolerance_30: number = 1,
        public freeze_tolerance: boolean = false,
        public shadow_tolerance: boolean = false,
        public compact_terrain_tolerance: boolean = false,
        public saline_terrain_tolerance: boolean = false,
        public wue_10: number = 1,
        public wue_20: number = 1,
        public wue_30: number = 1,  
        public drought_resistance_se: boolean = false,
        public drought_resistance_sm: boolean = false,
        public drought_resistance_sd: boolean = false,
        public drought_resistance_hd: boolean = false,
        public drought_resistance_hm: boolean = false,
        public drought_resistance_he: boolean = false,
        public species: SpecieAggregator[] = []
    ) {}

    meetsFilters(specie: SpecieAggregator, null_include_parameters: { [key: string]: boolean } ): boolean {
        
        for (const field of this.possible_rangeable_fields) {
            let simulation_range = this[field]

            let null_flag = null_include_parameters[field]
            let specie_range = specie["resilience"][field]

            if (null_flag && specie_range == null){
                specie_range = 1
            }
            if (simulation_range > specie_range ){
                return false
            }

        }
        
        for (const field of this.boolean_fields) {
            if (this[field] && !specie.resilience[field]) {
                return false;
            }
        }        
        
        return this.meetsOneOf(specie);
    }
    meetsOneOf(specie: SpecieAggregator): boolean {
        let any_selected = false;
        for (const field of this.one_of_fields) {
            any_selected = any_selected || this[field];
            if (this[field] && specie.resilience[field]) {
                return true;
            }
        }
        return !any_selected;
    }
}
export class ComfortFilters {
    constructor(
        public id?: number,
        public air_temp_reduction_pct: number = 1,
        public pavement_temp_reduction_pct: number = 1,
        public asphalt_temp_reduction_pct: number = 1,
        public grass_temp_reduction_pct: number = 1,
        public pipeclay_temp_reduction_pct: number = 1,
        public uva_reduction_pct: number = 1,
        public uvbc_reduction_pct: number = 1,
        public humidity_increase_pct: number = 1,
        public pavement_out_radiation_reduction_pct: number = 1,
        public asphalt_out_radiation_reduction_pct: number = 1,
        public grass_out_radiation_reduction_pct: number = 1,
        public pipeclay_out_radiation_reduction_pct: number = 1,
        public pavement_temp_radiation_reduction_pct: number = 1,
        public asphalt_temp_radiation_reduction_pct: number = 1,
        public grass_temp_radiation_reduction_pct: number = 1,
        public pipeclay_temp_radiation_reduction_pct: number = 1,
        public wgtb_pavement: number = 1,
        public wgtb_grass: number = 1,
        public wgtb_pipeclay: number = 1,
        public wgtb_asphalt: number = 1,
        public species: SpecieAggregator[] = []
    ) {}

    fields = [
        'air_temp_reduction_pct',
        'pavement_temp_reduction_pct',
        'asphalt_temp_reduction_pct',
        'grass_temp_reduction_pct',
        'pipeclay_temp_reduction_pct',
        'uva_reduction_pct',
        'uvbc_reduction_pct',
        'humidity_increase_pct',
        'pavement_out_radiation_reduction_pct',
        'asphalt_out_radiation_reduction_pct',
        'grass_out_radiation_reduction_pct',
        'pipeclay_out_radiation_reduction_pct',
        'pavement_temp_radiation_reduction_pct',
        'asphalt_temp_radiation_reduction_pct',
        'grass_temp_radiation_reduction_pct',
        'pipeclay_temp_radiation_reduction_pct',
        'wgtb_pavement',
        'wgtb_grass',
        'wgtb_pipeclay',
        'wgtb_asphalt',
    ];
    meetsFilters(specie: SpecieAggregator, null_include_parameters: { [key: string]: boolean } ): boolean {
        for (const field of this.fields) {

            let simulation_range = this[field]

            let null_flag = null_include_parameters[field]
            let specie_range = specie["thermalComfort"][field]

            if (null_flag && specie_range == null){
                specie_range = 1
            }
            if (simulation_range > specie_range ){
                return false
            }

        }
        return true;

        
    }
}
export class QualityFilters {

    targets = [
        'co_absorption',
        'no_absorption',
        'no2_absorption',
        'so2_absorption',
        'pm1_absorption',
        'pm2_5_absorption',
        'pm10_absorption',
    ];

    constructor(
        public id?: number,
        public co_absorption: number = 1,
        public no_absorption: number = 1,
        public no2_absorption: number = 1,
        public so2_absorption: number = 1,
        public pm1_absorption: number = 1,
        public pm2_5_absorption: number = 1,
        public pm10_absorption: number = 1,
        public species: SpecieAggregator[] = []
    ) {}

    meetsFilters(specie: SpecieAggregator, null_include_parameters: { [key: string]: boolean } ): boolean {
        for (const field of this.targets) {

            let simulation_range = this[field]

            let null_flag = null_include_parameters[field]
            let specie_range = specie["airQuality"][field]

            if (null_flag && specie_range == null){
                specie_range = 1
            }
            if (simulation_range > specie_range ){
                return false
            }
        }
        return true;
        
    }

}

export class CO2Filters {

    targets = [
        'co2_absorption',
        'co2_5_years_projection',
        'co2_10_years_projection',
        'co2_15_years_projection',
        'co2_20_years_projection',
        'co2_25_years_projection',
        'co2_30_years_projection',
        'co2_35_years_projection',
        'co2_40_years_projection'
    ];

    constructor(
        public id?: number,
        public co2_absorption: number = 1,
        public co2_5_years_projection: number = 1,
        public co2_10_years_projection: number = 1,
        public co2_15_years_projection: number = 1,
        public co2_20_years_projection: number = 1,
        public co2_25_years_projection: number = 1,
        public co2_30_years_projection: number = 1,
        public co2_35_years_projection: number = 1,
        public co2_40_years_projection: number = 1,
        public species: SpecieAggregator[] = []
    ) {}

    meetsFilters(specie: SpecieAggregator, null_include_parameters: { [key: string]: boolean } ): boolean {
        for (const field of this.targets) {

            let simulation_range = this[field]

            let null_flag = null_include_parameters[field]
            let specie_range = specie["Co2"][field]

            if (null_flag && specie_range == null){
                specie_range = 1
            }
            if (simulation_range > specie_range ){
                return false
            }
            
        }
        return true;
    }

}


export class BioFilters {
    targets = [
        'biodiversity_shelter_resource', 'biodiversity_trophic_resource', 'biophilia_chromatism_resource',
        'biophilia_fragrance_resource', 'biophilia_ornateness_resource'
    ];
    constructor(
        public id?: number,
        public biodiversity_shelter_resource: number = 1,
        public biodiversity_trophic_resource: number = 1,
        public biophilia_chromatism_resource: number = 1,
        public biophilia_fragrance_resource: number = 1,
        public biophilia_ornateness_resource: number = 1,
        public species: SpecieAggregator[] = []

    ) {}

    meetsFilters(specie: SpecieAggregator, null_include_parameters: { [key: string]: boolean } ): boolean {
        for (const field of this.targets) {

            let simulation_range = this[field]

            let null_flag = null_include_parameters[field]
            let specie_range = specie["biophilia"][field]

            if (null_flag && specie_range == null){
                specie_range = 1
            }
            if (simulation_range > specie_range ){
                return false
            }
        }
        return true;
    }

}

export class SimulationFilter {

    resilienceFilters: ResilienceFilter;
    comfortFilters: ComfortFilters;
    qualityFilters: QualityFilters;
    bioFilters: BioFilters;
    Co2Filters: CO2Filters;

    constructor() {
        this.resilienceFilters = new ResilienceFilter();
        this.comfortFilters = new ComfortFilters();
        this.qualityFilters = new QualityFilters();
        this.bioFilters = new BioFilters();
        this.Co2Filters = new CO2Filters();
    }


    shouldInclude(specie: SpecieAggregator , null_include_parameters: { [key: string]: boolean }  ): boolean {
        return this.resilienceFilters.meetsFilters(specie, null_include_parameters) && this.comfortFilters.meetsFilters(specie, null_include_parameters)
        && this.qualityFilters.meetsFilters(specie, null_include_parameters) && this.bioFilters.meetsFilters(specie, null_include_parameters) && this.Co2Filters.meetsFilters(specie, null_include_parameters);
    }

}
