export class Range {
    constructor(
        public id?: number,
        public parameter?: string,
        public type_of_range?: string,
        public table_of_ranges?: number[],
        public label?: string,
        public has_null?: boolean
    ) {
    }
}
