import { Component, OnInit } from '@angular/core';
import { Range } from '../ranges';
import { RangesService } from '../ranges.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { HttpErrorResponse } from '@angular/common/http';
import { RangeTableEditorComponent } from '../range-table-editor/range-table-editor.component';
import { StateService } from '@uirouter/core';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-ranges-list',
  templateUrl: './ranges-list.component.html',
  styleUrls: ['./ranges-list.component.sass']
})
export class RangesListComponent implements OnInit {

  range: Range;
  listRanges: Range[];
  type_of_range: string[];
  parameters_air_quality_ranges: Range[] = [];
  parameters_thermal_confort_ranges: Range[] = [];
  parameters_biodiversity_biophilia_ranges: Range[] = [];
  parameters_resilience_ranges: Range[] = [];
  parameters_characteristics_ranges: Range[] = [];
  parameter_co2_ranges: Range[] = [];

  active = 'airQuality';

  lastSelectedValue: string;

  parameters_air_quality = [
    'co_absorption',
    'no_absorption',
    'no2_absorption',
    'so2_absorption',
    'pm1_absorption',
    'pm2_5_absorption',
    'pm10_absorption',
    'cov_emission'
]

  parameters_thermal_confort = [
    'air_temp_reduction_pct',
    'humidity_increase_pct',
    'pavement_temp_reduction_pct',
    'asphalt_temp_reduction_pct',
    'grass_temp_reduction_pct',
    'pipeclay_temp_reduction_pct',
    'uva_reduction_pct',
    'uvbc_reduction_pct',
    'solar_energy_reduction_pct',
    'pavement_out_radiation_reduction_pct',
    'asphalt_out_radiation_reduction_pct',
    'grass_out_radiation_reduction_pct',
    'pipeclay_out_radiation_reduction_pct',
    'asphalt_temp_radiation_reduction_pct',
    'grass_temp_radiation_reduction_pct',
    'pipeclay_temp_radiation_reduction_pct',
    'wgtb_pavement',
    'wgtb_grass',
    'wgtb_pipeclay',
    'wgtb_asphalt'
  ]


  parameters_biodiversity_biophilia = [
    'biodiversity_trophic_resource',
    'biodiversity_shelter_resource',
    'biophilia_chromatism_resource',
    'biophilia_fragrance_resource',
    'biophilia_ornateness_resource',
    'biophilia_total'
  ]


  parameters_resilience = [
    'freeze_tolerance',
    'plague_tolerance',
    'compact_terrain_tolerance',
    'saline_terrain_tolerance',
    'drought_resistance_se',
    'drought_resistance_sm',
    'drought_resistance_sd',
    'drought_resistance_he',
    'drought_resistance_hm',
    'drought_resistance_hd',
    'stability_index',
    'heat_tolerance_10',
    'heat_tolerance_20',
    'heat_tolerance_30',
    'wue_10',
    'wue_20',
    'wue_30'
  ]


  parameters_characteristics = [
    'lai'
  ] 

  parameter_co2 = [
    'co2_absorption',
    'co2_5_years_projection',
    'co2_10_years_projection',
    'co2_15_years_projection',
    'co2_20_years_projection',
    'co2_25_years_projection',
    'co2_30_years_projection',
    'co2_35_years_projection',
    'co2_40_years_projection'
  ]



  constructor(    
    private modalService: NgbModal,
    private rangesService: RangesService,
    private $state: StateService
  ) { }

  ngOnInit(): void {
    this.rangesService.listRanges().subscribe(
      (ranges: Range[]) => {
        this.listRanges = ranges.sort((a, b) => {
          return a.label.localeCompare(b.label);
        });
        this.processRanges(this.listRanges)
      },
      (error: HttpErrorResponse) => {
      }
    );
  }

  fillTypeOfRange() {
    for (const range of this.listRanges) {
      this.type_of_range.push(range.type_of_range);
    }
  }

  typeChange(type: string, range: Range) {
    this.range = range;
    this.range.type_of_range = type;
    if (type === 'NON_LINEAR') {
      this.editTable(this.range);
    }
    else {
      this.rangesService.updateRangeTypeOfRange(this.range).subscribe();
    }
  }
  

  editTable(range: Range) {
    this.range = range;
    const modalRef = this.modalService.open(RangeTableEditorComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.range = Object.assign({}, this.range);
    modalRef.result.then(
      (newRange) => {
        if (newRange === null) {
          this.$state.go('home.ranges');
        }
        else {
          Object.assign(this.range, newRange);
        }
      },
      (reason: any) => {}
    );
  }

  processRanges(ranges: Range[]) {
    ranges.forEach(range => {
      if (this.parameters_air_quality.includes(range.parameter)) {
        this.parameters_air_quality_ranges.push(range);
      } else if (this.parameters_thermal_confort.includes(range.parameter)) {
        this.parameters_thermal_confort_ranges.push(range);
      } else if (this.parameters_biodiversity_biophilia.includes(range.parameter)) {
        this.parameters_biodiversity_biophilia_ranges.push(range);
      } else if (this.parameters_resilience.includes(range.parameter)) {
        this.parameters_resilience_ranges.push(range);
      } else if (this.parameters_characteristics.includes(range.parameter)) {
        this.parameters_characteristics_ranges.push(range);
      } else if (this.parameter_co2.includes(range.parameter)) {
        this.parameter_co2_ranges.push(range);
      }
    });
  }

}


