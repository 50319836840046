import { Injectable } from '@angular/core';
import { Range } from './ranges';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { MessageService } from '../message.service';
import { environment } from 'src/environments/environment';
import { tap, catchError, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { Message } from '../message';

@Injectable({
  providedIn: 'root'
})
export class RangesService {

  ranges: Range[] = [];  

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) { } 

  getRanges(): Observable<Range[]> {
    const url = environment.serverUrl + '/ranges/';
    return this.http.get<Range[]>(url).pipe(
      tap((ranges: Range[]) => {
        this.ranges = ranges;
        return ranges;
      })
    );
  }

  updateRangeTypeOfRange(range: Range): Observable<Range> {
    const url = `${environment.serverUrl}/ranges/${range.id}/`;
    return this.http.patch<Range>(url, range).pipe(
      tap(() => this.messageService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messageService.add(new Message('error', httpError.error));
        throw httpError;
      })
    );
  }

  updateRangeTableOfRanges(range: Range): Observable<Range> {
    const url = `${environment.serverUrl}/ranges/${range.id}/`;
    return this.http.patch<Range>(url, range).pipe(
      tap(() => this.messageService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messageService.add(new Message('error', httpError.error));
        throw httpError;
      })
    );
  }

  listRanges(): Observable<Range[]> {
    const url = `${environment.serverUrl}/ranges/`;
    return this.http.get(url).pipe(
      map((json: any) => {
        const result: Range[] = [];
        for (const itm of json) {
          result.push(Object.assign(new Range(), itm));
        }
        return result;
      }),
      tap((range: Range[]) => {
        this.ranges.splice(0);
        [].push.apply(this.ranges, range);
        return range;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messageService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  //Function to return the list of ranges as a dictionary key(parameter) value([type_of_range, table_of_ranges, has_null])
  getRangesDict(): { [key: string]: [string, number[], boolean] } {
    const rangesDict: { [key: string]: [string, number[], boolean] } = {};
    this.getRanges().subscribe(ranges => this.ranges = ranges);
    for (const range of this.ranges) {
      rangesDict[range.parameter] = [range.type_of_range, range.table_of_ranges, range.has_null];
    }
    return rangesDict;
  }


}
