import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { User } from '../auth/user';
import { environment } from 'src/environments/environment';
import { tap, catchError } from 'rxjs/operators';
import { Message } from '../message';
import { MessageService } from '../message.service';

@Injectable({
  providedIn: 'root'
})
export class PermissionsService {
  permissions: string[];
  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) { }


  retrieveUserPermissions(user: User): Observable<string[]> {
    const url = `${environment.serverUrl}/users/${user.id}/permissions/`;
    return this.http.get<string[]>(url).pipe(
      tap((permissions: string[]) => permissions),
      catchError((httpError: HttpErrorResponse) => {
        this.messageService.add(new Message('error', httpError.error));
        throw httpError;
      })
    );
  }

  retrieveAvailablePermissions(): Observable<string[]> {
    if (this.permissions !== undefined) {
      return of(this.permissions);
    } else {
      const url = `${environment.serverUrl}/permissions/`;
      return this.http.get<string[]>(url).pipe(
        tap((permissions: string[]) => {
          this.permissions = permissions;
          return permissions;
        }),
        catchError((httpError: HttpErrorResponse) => {
          this.messageService.add(new Message('error', httpError.error));
          throw httpError;
        })
      );
    }
  }

  grant(user: User, perm: string): Observable<string> {
    const url = `${environment.serverUrl}/users/${user.id}/permissions/`;
    const data = {permission: perm};
    return this.http.post<string>(url, data).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messageService.add(new Message('error', httpError.error));
        throw httpError;
      })
    );
  }

  revoke(user: User, perm: string): Observable<string> {
    const url = `${environment.serverUrl}/users/${user.id}/permissions/${perm}/`;
    return this.http.delete<string>(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messageService.add(new Message('error', httpError.error));
        throw httpError;
      })
    );
  }
}
