export class Message {
  static id = 0;
  kind: string;
  text: string;
  readonly messageId: number;

  constructor(kind: string, text: string) {
    this.kind = kind;
    this.text = text;
    this.messageId = Message.id;
    Message.id++;
  }
}
