import { Component, OnInit, Input } from '@angular/core';
import { RangesService } from '../ranges.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-range-table-editor',
  templateUrl: './range-table-editor.component.html',
  styleUrls: ['./range-table-editor.component.sass']
})
export class RangeTableEditorComponent implements OnInit {

  @Input() range;
  list_of_ranges: (number | string)[] = [];
  error_flag = 0;

  constructor(
    private activeModal: NgbActiveModal,
    private RangesService: RangesService,  
  ) { }

  ngOnInit(): void {
    if (this.range.table_of_ranges) {
      for (const item of this.range.table_of_ranges) {
        if (item === -1) {
          this.list_of_ranges.push("");
        }
        else if (item === Number.MIN_VALUE) {
          this.list_of_ranges.push("");
        }
        else if (item === Number.MAX_VALUE) {
          this.list_of_ranges.push("");
        }
        else {
          this.list_of_ranges.push(item);
        }
      }
    } else {
      this.list_of_ranges.push(0,0,0,0,0,0);
      this.range.table_of_ranges = this.list_of_ranges;
    }
    this.range.table_of_ranges = this.list_of_ranges;
    this.trackByRangeId = this.trackByRangeId.bind(this)
  }

  save() {
    if (this.range.table_of_ranges != null) {
      for (let index = 0; index < this.range.table_of_ranges.length; index++) {
        if ((this.range.table_of_ranges[index] === "" || this.range.table_of_ranges[index] === null) && (index != 0 && index != 5)) {
          this.error_flag = 1;
          return;
        }
      }
      if (this.range.table_of_ranges[0] === null || this.range.table_of_ranges[0] === "" || this.range.table_of_ranges[0] === 0) {
          this.range.table_of_ranges[0] = Number.MIN_VALUE;
      }
      
      if (this.range.table_of_ranges[this.range.table_of_ranges.length - 1] === null || 
          this.range.table_of_ranges[this.range.table_of_ranges.length - 1] === "" || 
          this.range.table_of_ranges[this.range.table_of_ranges.length - 1] === 0) {
          this.range.table_of_ranges[this.range.table_of_ranges.length - 1] = Number.MAX_VALUE;
      }
      for (let i = 1; i < this.range.table_of_ranges.length; i++) {
        if (this.range.table_of_ranges[i] <= this.range.table_of_ranges[i - 1]) {
            this.error_flag = 1;
            return;
        }
      }
      this.error_flag = 0;
      this.activeModal.close(this.range);
      this.RangesService.updateRangeTableOfRanges(this.range).subscribe();
    }
    else {
      this.error_flag = 1;
    }
  }

  addRow(){
    if (this.list_of_ranges.length == 6){
      return;
    }
    this.list_of_ranges.push(0);
    this.range.table_of_ranges = this.list_of_ranges;
  }

  deleteRow(){
    if (this.list_of_ranges.length == 4){
      return;
    }
    this.list_of_ranges.pop();
    this.range.table_of_ranges = this.list_of_ranges;
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  trackByRangeId(index, item) {
    if (this.list_of_ranges !== undefined) {
      this.list_of_ranges[index] = item;
    }
    this.range.table_of_ranges = this.list_of_ranges;
    return item.id;
  }
}
