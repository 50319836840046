<div class="container-fluid" *ngIf="efficiencyData">
  <div class="edition_area" *ngIf="user.permissions.indexOf('species.edit_efficiency_data') > -1">
    <button type="button" class="btn btn-outline-success float-right" (click)="editEfficiency()">Editar</button>
  </div>
  <table class="table">
    <tbody>
      <tr class="no-border">
        <td><strong>Superficie de sombreamiento (m<sup>2</sup>)</strong></td>
        <td>{{shadow_area | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Incremento de la Humedad Relativa (%)</strong></td>
        <td>{{efficiencyData.humidity_increase_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Reducción de la temperatura del aire bajo dosel (%)</strong></td>
        <td>{{efficiencyData.air_temp_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <!-- <tr>
      <td><strong>Reducción de la temperatura absoluta bajo dosel (ºC)</strong></td>
      <td>{{efficiencyData.heat_reduction_absolute | number : '1.2-2' : 'es'}}</td>
    </tr> -->
      <tr>
        <td><strong>Reducción de la temperatura de la acera (%)</strong></td>
        <td>{{efficiencyData.pavement_temp_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <!-- <tr>
      <td><strong>Reducción de la temperatura absoluta en acera (ºC)</strong></td>
      <td>{{efficiencyData.heat_reduction_pavement_absolute | number : '1.2-2' : 'es'}}</td>
    </tr> -->
      <tr>
        <td><strong>Reducción de la temperatura del asfalto (%)</strong></td>
        <td>{{efficiencyData.asphalt_temp_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <!--  <tr>
      <td><strong>Reducción de la temperatura absoluta en asfalto (ºC)</strong></td>
      <td>{{efficiencyData.heat_reduction_asphalt_absolute | number : '1.2-2' : 'es'}}</td>
    </tr> -->
      <tr>
        <td><strong>Reducción de la temperatura del césped (%)</strong></td>
        <td>{{efficiencyData.grass_temp_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <!-- <tr>
      <td><strong>Reducción de la temperatura absoluta en césped (ºC)</strong></td>
      <td>{{efficiencyData.heat_reduction_grass_absolute | number : '1.2-2' : 'es'}}</td>
    </tr> -->
      <tr>
        <td><strong>Reducción de la temperatura del albero (%)</strong></td>
        <td>{{efficiencyData.pipeclay_temp_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <!--   <tr>
      <td><strong>Reducción de la temperatura absoluta en albero (ºC)</strong></td>
      <td>{{efficiencyData.heat_reduction_pipeclay_absolute | number : '1.2-2' : 'es'}}</td>
    </tr> -->

      <tr>
        <td><strong>Reducción de la Tª radiante en acera (%)</strong></td>
        <td>{{efficiencyData.pavement_temp_radiation_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr> 
      <tr>
        <td><strong>Reducción de la Tª radiante en asfalto (%)</strong></td>
        <td>{{efficiencyData.asphalt_temp_radiation_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr> 
      <tr>
        <td><strong>Reducción de la Tª radiante en césped (%)</strong></td>
        <td>{{efficiencyData.grass_temp_radiation_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr> 
      <tr>
        <td><strong>Reducción de la Tª radiante en albero (%)</strong></td>
        <td>{{efficiencyData.pipeclay_temp_radiation_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr> 

      <tr>
        <td><strong>Reducción del índice de estrés térmico en acera (%)</strong></td>
        <td>{{efficiencyData.wgtb_pavement | number : '1.2-2' : 'es'}}</td>
      </tr> 
      <tr>
        <td><strong>Reducción del índice de estrés térmico en asfalto (%)</strong></td>
        <td>{{efficiencyData.wgtb_asphalt | number : '1.2-2' : 'es'}}</td>
      </tr> 
      <tr>
        <td><strong>Reducción del índice de estrés térmico en césped (%)</strong></td>
        <td>{{efficiencyData.wgtb_grass | number : '1.2-2' : 'es'}}</td>
      </tr> 
      <tr>
        <td><strong>Reducción del índice de estrés térmico en albero (%)</strong></td>
        <td>{{efficiencyData.wgtb_pipeclay | number : '1.2-2' : 'es'}}</td>
      </tr> 

      <tr>
        <td><strong>Reducción de rayos UVA (400-315 nm) (%)</strong></td>
        <td>{{efficiencyData.uva_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Reducción de rayos UV(B+C) (315-100 nm) (%)</strong></td>
        <td>{{efficiencyData.uvbc_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Reducción de la radiación total de entrada (400-800 nm) (%)</strong></td>
        <td>{{efficiencyData.solar_energy_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Atenuación de radiaciones electromagnéticas de alta frecuencia (%)</strong></td>
        <td>{{efficiencyData.hf_em_radiation_attenuation_pct | number : '1.2-2' : 'es'}}</td>
      </tr>

      <!--     <tr>
      <td><strong>Reducción de la radiación de salida (W/m<sup>2</sup>) en acera</strong></td>
      <td>{{efficiencyData.out_radiation_reduction_pavement | number : '1.2-2' : 'es'}}</td>
    </tr> -->
      <tr>
        <td><strong>Reducción de la radiación de salida en acera (%)</strong></td>
        <td>{{efficiencyData.pavement_out_radiation_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <!-- <tr>
      <td><strong>Reducción de la radiación de salida (W/m<sup>2</sup>) en asfalto</strong></td>
      <td>{{efficiencyData.out_radiation_reduction_asphalt | number : '1.2-2' : 'es'}}</td>
    </tr> -->
      <tr>
        <td><strong>Reducción de la radiación de salida en asfalto (%)</strong></td>
        <td>{{efficiencyData.asphalt_out_radiation_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <!-- <tr>
      <td><strong>Reducción de la radiación de salida (W/m<sup>2</sup>) en césped</strong></td>
      <td>{{efficiencyData.out_radiation_reduction_grass | number : '1.2-2' : 'es'}}</td>
    </tr> -->
      <tr>
        <td><strong>Reducción de la radiación de salida en césped (%)</strong></td>
        <td>{{efficiencyData.grass_out_radiation_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr>
      <!-- <tr>
      <td><strong>Reducción de la radiación de salida (W/m<sup>2</sup>) en albero</strong></td>
      <td>{{efficiencyData.out_radiation_reduction_pipeclay | number : '1.2-2' : 'es'}}</td>
    </tr> -->
      <tr>
        <td><strong>Reducción de la radiación de salida en albero (%)</strong></td>
        <td>{{efficiencyData.pipeclay_out_radiation_reduction_pct | number : '1.2-2' : 'es'}}</td>
      </tr> 

    </tbody>
  </table>
</div>
