<div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Editar absorción CO2 de {{ specie.name }}</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #editCO2Absorption="ngForm" (ngSubmit)="save()">
        <div class="form-row">
          <div class="form-group col-md-6 col-sm-6">
            <label for="co2_absorption">Almacenamiento de CO2<sub>2</sub> (Kg/año)</label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_absorption" class="form-control" id="co2_absorption" name="co2_absorption" min="0" step="0.01">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-3 col-sm-3">
            <label for="co2_5_years_projection">Almacenamiento CO2<sub>2</sub> en 5 años (Kg) </label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_5_years_projection" class="form-control" id="co2_5_years_projection" name="co2_5_years_projection" min="0" step="0.01">
          </div>
          <div class="form-group col-md-3 col-sm-3">
            <label for="co2_10_years_projection">Almacenamiento CO2<sub>2</sub> en 10 años (Kg) </label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_10_years_projection" class="form-control" id="co2_10_years_projection" name="co2_10_years_projection" min="0" step="0.01">
          </div>
          <div class="form-group col-md-3 col-sm-3">
            <label for="co2_15_years_projection">Almacenamiento CO2<sub>2</sub> en 15 años (Kg) </label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_15_years_projection" class="form-control" id="co2_15_years_projection" name="co2_15_years_projection" min="0" step="0.01">
          </div>
          <div class="form-group col-md-3 col-sm-3">
            <label for="co2_20_years_projection">Almacenamiento CO2<sub>2</sub> en 20 años (Kg) </label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_20_years_projection" class="form-control" id="co2_20_years_projection" name="co2_20_years_projection" min="0" step="0.01">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-3 col-sm-3">
            <label for="co2_25_years_projection">Almacenamiento CO2<sub>2</sub> en 25 años (Kg)</label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_25_years_projection" class="form-control" id="co2_25_years_projection" name="co2_25_years_projection" min="0" step="0.01">
          </div>
          <div class="form-group col-md-3 col-sm-3">
            <label for="co2_30_years_projection">Almacenamiento CO2<sub>2</sub> en 30 años (Kg)</label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_30_years_projection" class="form-control" id="co2_30_years_projection" name="co2_30_years_projection" min="0" step="0.01">
          </div>
          <div class="form-group col-md-3 col-sm-3">
            <label for="co2_35_years_projection">Almacenamiento CO2<sub>2</sub> en 35 años (Kg)</label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_35_years_projection" class="form-control" id="co2_35_years_projection" name="co2_35_years_projection" min="0" step="0.01">
          </div>
          <div class="form-group col-md-3 col-sm-3">
            <label for="co2_40_years_projection">Almacenamiento CO2<sub>2</sub> en 40 años (Kg)</label>
            <input type="number" [(ngModel)]="co2AbsorptionData.co2_40_years_projection" class="form-control" id="co2_40_years_projection" name="co2_40_years_projection" min="0" step="0.01">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
    </div>
  </div>
  