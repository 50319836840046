<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar calidad del aire de {{ specie.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #editResilienceForm="ngForm" (ngSubmit)="save()">
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <label for="co_absorption">Absorción de CO (µg/cm<sup>2</sup>/día)</label>
          <input type="number" [(ngModel)]="specie.co_absorption" class="form-control" id="co_absorption" name="co_absorption" min="0">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="so2_absorption">Absorción de SO<sub>2</sub> (µg/cm<sup>2</sup>/día)</label>
          <input type="number" [(ngModel)]="specie.so2_absorption" class="form-control" id="so2_absorption" name="so2_absorption" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <label for="no_absorption">Absorción de NO (µg/cm<sup>2</sup>/día)</label>
          <input type="number" [(ngModel)]="specie.no_absorption" class="form-control" id="no_absorption" name="no_absorption" min="0">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="no2_absorption">Absorción de NO<sub>2</sub> (µg/cm<sup>2</sup>/día)</label>
          <input type="number" [(ngModel)]="specie.no2_absorption" class="form-control" id="no2_absorption" name="no2_absorption" min="0">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="pm1_absorption">Absorción de PM<sub>1</sub> (µg/cm<sup>2</sup>/día)</label>
          <input type="number" [(ngModel)]="specie.pm1_absorption" class="form-control" id="pm1_absorption" name="pm1_absorption" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <label for="pm2_5_absorption">Absorción de PM<sub>2,5</sub> (µg/cm<sup>2</sup>/día)</label>
          <input type="number" [(ngModel)]="specie.pm2_5_absorption" class="form-control" id="pm2_5_absorption" name="pm2_5_absorption" min="0">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="pm10_absorption">Absorción de PM<sub>10</sub> (µg/cm<sup>2</sup>/día)</label>
          <input type="number" [(ngModel)]="specie.pm10_absorption" class="form-control" id="pm10_absorption" name="pm10_absorption" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="cov_emission">Emisión de COVbs totales(ppm/g/15 minutos)</label>
          <input type="number" [(ngModel)]="specie.cov_emission" class="form-control" id="cov_emission" name="cov_emission" min="0">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
  </div>
</div>
