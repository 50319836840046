import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';
import { Specie } from 'src/app/species/specie';
import { SpecieService } from 'src/app/species/specie.service';
import { Distribution } from '../distribution';
import { DistributionService } from '../distribution.service';
import { Inventory } from '../inventory';
import { SpaceService } from '../space.service';
import { or } from '@uirouter/core';
import { Key } from 'protractor';

@Component({
  selector: 'app-distribution-preview',
  templateUrl: './distribution-preview.component.html',
  styleUrls: ['./distribution-preview.component.sass']
})
export class DistributionPreviewComponent implements OnInit {
  @Input() data: any[];
  @Input() zoneId: number;

  availableSpecies: any[] = [];
  headers: string[] = [];
  processedRows: any[] = [];
  nameCol: string;
  dbhCol: string;
  dbh_options: string = '';
  heightCol: string;
  unitIDCol: string;

  dictionary_asimilated: { [key: string]: number } = {};	

  errorCount = 0;
  processing = false;

  constructor(
    private activeModal: NgbActiveModal,
    private speciesService: SpecieService,
    private distributionService: DistributionService,
    private spaceService: SpaceService
    ) { }

  ngOnInit(): void {
    this.speciesService.listSpecies().subscribe((species: Specie[]) => this.availableSpecies = species.sort((obj1, obj2) => {
      if (obj1.science_name > obj2.science_name) {
          return 1;
      }
      if (obj1.science_name < obj2.science_name) {
          return -1;
      }
      return 0;
  }));
    this.headers = Object.keys(this.data[0]);
    this.distributionService.getAsimilatedSpecies().subscribe(
      (data: { unknown_species_name: string, real_specie: number }[]) => {      
        data.forEach(item => {
          this.dictionary_asimilated[item.unknown_species_name.toUpperCase()] = item.real_specie;
        });
      }
    );
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  processRows() {
  
    this.processedRows.splice(0);
    this.data.forEach((row: any) => {
      const name = row[this.nameCol].toLowerCase().trim();
      let found = false;
      this.processedRows.forEach((existing: any) => {
        if (existing.specieName === name){
          existing.rows.push(row);
          found = true;
        }
      });
      if (!found) {
        this.availableSpecies.forEach((specie: Specie) => {
          if (specie.science_name.toLowerCase().trim() === name){
            this.processedRows.push({specieName: name, status: 'ok', rows: [row, ], spec: specie});
            found = true;
          }
        });
        if (!found) {
          this.processedRows.push({specieName: name, status: 'error', rows: [row, ], originalName: name});
        }
      }
    });
    this.checkErrors();
  }
  updateRow(index) {
    this.availableSpecies.forEach((specie: Specie) => {
      if (specie.science_name.toLowerCase().trim() === this.processedRows[index].specieName.toLowerCase().trim()){
        this.processedRows[index].status = 'updated';
        this.processedRows[index].spec = specie;
        this.checkErrors();
      }
    });

  }

  deleteRow(index) {
    this.processedRows.splice(index, 1);
    this.checkErrors();
  }

  checkErrors() {
    this.errorCount = 0;
    this.processedRows.forEach(row => {
      if (row.status === 'error') {
        this.errorCount++;
      }
    });
  }

  import() {
    this.processing = true;
    this.processedRows.forEach((row: any) => {
      if (row.status !== 'processed' && row.status !== 'error'){
        const distribution = new Distribution(this.speciesService);
        distribution.updateSpecie(row.spec).subscribe(() => {
          row.rows.forEach((invRow: any) => {
            const inv = new Inventory();
            inv.dbh = invRow[this.dbhCol];
            if (inv.dbh === 0 || inv.dbh == null) {
              if (this.dbh_options == 'minimum') {
                inv.dbh = row.spec.min_dbh;
              } else if (this.dbh_options == 'medium') {
                inv.dbh = row.spec.dbh;
              }
            }
            if (this.unitIDCol !== ''){
              inv.unit_identification = invRow[this.unitIDCol];
            }
            if (this.heightCol !== '') {
              inv.height = invRow[this.heightCol];
            }
            inv.calculateFromDBH(distribution.specie);
            distribution.addUnit(inv);
          });
          this.distributionService.createDistribution(distribution).subscribe(
            (dist) => this.spaceService.addDistribution(this.zoneId, dist.id).subscribe(() => {
              row.status = 'processed';
              this.checkComplete();
            })
          );
        });
      }
    });
  }

  checkComplete() {
    this.processedRows.forEach((row: any) => {
      this.processing = this.processing || row.status !== 'processed';
    });
  }

  getAvaliableSpeciesBasedOnAsimilated(specie_name: string){
    
    let id = -1;

    specie_name = specie_name.toUpperCase()

    if (this.dictionary_asimilated[specie_name]!=null){
      id = this.dictionary_asimilated[specie_name];
    }

    let list_of_order_species = this.availableSpecies
    const index = list_of_order_species.findIndex(item => item.id === id);
    const [item] = list_of_order_species.splice(index, 1)
    list_of_order_species.unshift(item)
    return list_of_order_species

  }

}
