export class SpecieThermalComfort {
    constructor(
        public id: number,
        public air_temp_reduction_pct?: number,
        public humidity_increase_pct?: number,
        public pavement_temp_reduction_pct?: number,
        public pavement_temp_reduction_ranking?: number,
        public asphalt_temp_reduction_pct?: number,
        public asphalt_temp_reduction_ranking?: number,
        public grass_temp_reduction_pct?: number,
        public grass_temp_reduction_ranking?: number,
        public pipeclay_temp_reduction_pct?: number,
        public pipeclay_temp_reduction_ranking?: number,
        public pavement_temp_radiation_reduction_pct?: number,
        public pavement_temp_radiation_reduction_ranking?: number,
        public asphalt_temp_radiation_reduction_pct?: number,
        public asphalt_temp_radiation_reduction_ranking?: number,
        public grass_temp_radiation_reduction_pct?: number,
        public grass_temp_radiation_reduction_ranking?: number,
        public pipeclay_temp_radiation_reduction_pct?: number,
        public pipeclay_temp_radiation_reduction_ranking?: number,
        public wgtb_pavement?: number,
        public wgtb_asphalt?: number,
        public wgtb_grass?: number,
        public wgtb_pipeclay?: number,
        public uva_reduction_pct?: number,
        public uvbc_reduction_pct?: number,
        public solar_energy_reduction_pct?: number,
        public hf_em_radiation_attenuation_pct?: number,
        public pavement_out_radiation_reduction_pct?: number,
        public pavement_out_radiation_reduction_ranking?: number,
        public asphalt_out_radiation_reduction_pct?: number,
        public asphalt_out_radiation_reduction_ranking?: number,
        public grass_out_radiation_reduction_pct?: number,
        public grass_out_radiation_reduction_ranking?: number,
        public pipeclay_out_radiation_reduction_pct?: number,
        public pipeclay_out_radiation_reduction_ranking?: number
    ) { }
}
