import { Component, OnInit } from '@angular/core';
import { UsersService } from '../users.service';
import { User } from 'src/app/auth/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss']
})
export class UserListComponent implements OnInit {
  users: User[];
  authUser: User;
  constructor(
    private usersService: UsersService,
    private modalService: NgbModal,
    private authService: AuthService) {
      this.authUser = this.authService.user;
    }

  ngOnInit() {
    this.usersService.loadUsers().subscribe((users: User[]) => {
      this.users = users;
    });
  }

  openCreateUserModal(content) {
    this.modalService.open(content).result.then((user) => {
      this.usersService.createUser(user).subscribe(
        () => {},
        () => {}
      );
    });
  }

  deleteUser(user) {
    this.usersService.deleteUser(user).subscribe(
      () => {},
      () => {}
    );
  }
}
