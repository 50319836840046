import { Component, OnInit, Input } from '@angular/core';
import { User } from 'src/app/auth/user';
import { PermissionsService } from '../permissions.service';
import { HttpErrorResponse } from '@angular/common/http';

@Component({
  selector: 'app-user-permissions',
  templateUrl: './user-permissions.component.html',
  styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent implements OnInit {
  @Input() user: User;

  permissionList: string[];
  userPermissions: string[];

  constructor(
    private permissionsService: PermissionsService
  ) { }



  ngOnInit() {
    this.permissionsService.retrieveAvailablePermissions().subscribe(
      (permissions) => this.permissionList = permissions
    );
    this.permissionsService.retrieveUserPermissions(this.user).subscribe(
      (userPerms) => this.userPermissions = userPerms
    );
  }

  grant(permission: string) {
    this.permissionsService.grant(this.user, permission).subscribe(
      (grantedPermission: string) => this.userPermissions.push(grantedPermission),
      () => {}
    );
  }

  revoke(permission: string) {
    this.permissionsService.revoke(this.user, permission).subscribe(
      () => this.userPermissions.splice(this.userPermissions.indexOf(permission), 1),
      () => {}
    );
  }
}
