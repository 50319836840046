export class SpecieResilience {
    constructor(
        public id: number,
        public heat_tolerance_10?: number,
        public heat_tolerance_20?: number,
        public heat_tolerance_30?: number,
        public freeze_tolerance?: boolean,
        public plague_tolerance?: boolean,
        public shadow_tolerance?: boolean,
        public compact_terrain_tolerance?: boolean,
        public saline_terrain_tolerance?: boolean,
        public water_efficiency_10?: number,
        public water_efficiency_20?: number,
        public water_efficiency_30?: number,
        public wue_10?: number,
        public wue_20?: number,
        public wue_30?: number,
        public drought_resistance?: number,
        public stability_index?: number,
        public stability_index_ranking?: number
    ) {
    }
}
