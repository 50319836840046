import { TargetState, Transition, TransitionService } from '@uirouter/core';
import { AuthService } from './auth.service';
import { MessageService } from '../message.service';
import { Message } from '../message';


/**
 * This file contains a Transition Hook which protects a
 * route that requires authentication.
 *
 * This hook redirects to /login when both:
 * - The user is not authenticated
 * - The user is navigating to a state that requires authentication
 */
export function requiresAuthHook(transitionService: TransitionService) {
  // Matches if the destination state's data property has a truthy 'requiresAuth' property
  const requiresAuthCriteria = {
    to: (state) => state.data && state.data.requiresAuth
  };

  // Function that returns a redirect for the current transition to the login state
  // if the user is not currently authenticated (according to the AuthService)
  function redirectToLogin(transition: Transition) {
    const authService: AuthService = transition.injector().get(AuthService);
    const messageService: MessageService = transition.injector().get(MessageService);

    const $state = transition.router.stateService;
    if (!authService.isAuthenticated()) {
      return authService.verify().toPromise().then<void>().catch<TargetState>(
        () => {
          return $state.target('login', undefined, { location: true });
        }
      );
    }
  }
  // Register the "requires auth" hook with the TransitionsService
  transitionService.onBefore(requiresAuthCriteria, redirectToLogin, {priority: 10});
}
