import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpaceService } from '../space.service';

@Component({
  selector: 'app-space-clima-editor',
  templateUrl: './space-clima-editor.component.html',
  styleUrls: ['./space-clima-editor.component.scss']
})
export class SpaceClimaEditorComponent implements OnInit {
  @Input() space;

  constructor(
    private activeModal: NgbActiveModal,
    private spacesService: SpaceService
  ) { }

  ngOnInit() {
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  save() {
    if (this.space.id !== undefined) {
      this.spacesService.updateSpace(this.space).subscribe(
        space => this.activeModal.close(space),
        () => {}
      );
    } else {
        this.spacesService.createSpace(this.space).subscribe(
          space => this.activeModal.close(space),
          () => {}
        );
    }
  }
}
