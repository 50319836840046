import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpaceService } from '../space.service';
import { SpaceEditorComponent } from '../space-editor/space-editor.component';
import { forEach, StateService } from '@uirouter/core';


@Component({
  selector: 'app-spaces-list',
  templateUrl: './spaces-list.component.html',
  styleUrls: ['./spaces-list.component.scss']
})
export class SpacesListComponent implements OnInit {
  existingSpacesList = [];
  newSpacesList = [];
  list_of_spaces = [];
  filter = 'Todas'
  filterName = '';
  alphabeticOrder = "Alfabetic";
  view = "Mosaic";
  spaceNameSpecimens: { [key: number]: number } = {};	
  times_funtion_got_called = 0;

  constructor(
    private modalService: NgbModal,
    private spaceService: SpaceService,
    private $state: StateService) { }

  ngOnInit() {
    this.loadSpaces();
  }

  openCreateSpaceModal(space = {}) {
    const modalRef = this.modalService.open(SpaceEditorComponent, { size: 'xl' as 'lg' });
    modalRef.componentInstance.space = space;
    modalRef.result.then(
      (newSpace) => this.$state.go('home.spaces.detail', {spaceId: newSpace.id}),
      () => {}
    );
  }

  loadSpaces() {
    this.spaceService.listSpaces().subscribe(
      (spaces: []) => spaces.forEach((space: any) => {
          this.list_of_spaces.push(space);

        }),
      () => {}
    );

    this.spaceService.getAllNumberOfSpecimens().subscribe({
      next: (dict) => {
        this.spaceNameSpecimens = dict;
      }
    });
    

  }

  getFilteredSpaces() {
    const filteredSpaces = this.list_of_spaces
    .filter(space => this.filter === 'Todas' || (this.filter === 'Nuevas' && space.kind === 'NEW') || (this.filter === 'Existentes' && space.kind !== 'NEW'))
    .filter(space => space.name.toLowerCase().includes(this.filterName.toLowerCase()))
    .sort((a, b) => {
      if (this.alphabeticOrder === 'Alfabetic') {
        return a.name.localeCompare(b.name);
      } else if (this.alphabeticOrder === 'Cronologic') {
        return b.id - a.id;
      }
    });

    return filteredSpaces;
  }


}
