import { Specie } from './specie';
import { SpecieAirQuality } from './specie-air-quality';
import { SpecieBiodiversity } from './specie-biodiversity';
import { SpecieBiophilia } from './specie-biophilia';
import { SpecieResilience } from './specie-resilience';
import { SpecieThermalComfort } from './specie-thermal-comfort';
import { SpecieCo2 } from './specie-co2-absorption';

export class SpecieAggregator {
    specie: Specie;
    airQuality: SpecieAirQuality;
    thermalComfort: SpecieThermalComfort;
    resilience: SpecieResilience;
    biodiversity: SpecieBiodiversity;
    biophilia: SpecieBiophilia;
    Co2: SpecieCo2;

    constructor(serverData: any) {
        this.specie = Object.assign(new Specie(), serverData);
        this.airQuality = Object.assign(new SpecieAirQuality(serverData.id), serverData);
        this.thermalComfort = Object.assign(new SpecieThermalComfort(serverData.id), serverData);
        this.resilience = Object.assign(new SpecieResilience(serverData.id), serverData);
        this.biodiversity = Object.assign(new SpecieBiodiversity(serverData.id), serverData);
        this.biophilia = Object.assign(new SpecieBiophilia(serverData.id), serverData);
        this.Co2 = Object.assign(new SpecieCo2(serverData.id), serverData);
    }

    static compareById(a: SpecieAggregator, b: SpecieAggregator): boolean {
        return a.specie.id === b.specie.id;
    }

    static isSpecieInList(specie: SpecieAggregator, list: SpecieAggregator[]): boolean {
        return list.some(item => SpecieAggregator.compareById(specie, item));
    }

    static getMaxValueOfParameter(list: SpecieAggregator[], prop1: string, prop2: string): number {

        let maxValue = list[0][prop1][prop2] as number;
    
        for (let i = 1; i < list.length; i++) {
            const currentValue = list[i][prop1][prop2] as number;
    
            if (currentValue !== undefined && currentValue!= null && currentValue > maxValue ) {
                maxValue = currentValue;
            }
        }
    
        return maxValue;
    }
    
    
    static getMinValueOfParameter(list: SpecieAggregator[], prop1: string, prop2: string): number {
        
        let minValue = list[0][prop1][prop2] as number;
    
        for (let i = 1; i < list.length; i++) {
            const currentValue = list[i][prop1][prop2] as number;
    
            if (minValue == null && currentValue != null){
                minValue=currentValue
            }
            if (currentValue !== undefined && currentValue < minValue && currentValue!=null) {
                minValue = currentValue;
            }
        }
    
        return minValue;
    }

}
