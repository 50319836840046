<div class="container-fluid" *ngIf="biophiliaData">
  <div class="edition_area" *ngIf="user.permissions.indexOf('species.edit_basic_data') > -1" >
    <button type="button" class="btn btn-outline-success float-right" (click)="editBiophilia()">Editar</button>
  </div>
  <table class="table">
    <tbody>
      <tr class="no-border">
        <td><strong>Color Hoja</strong></td>
        <td>{{ biophiliaData.biophilia_leaves_colour }}</td>
      </tr>
      <tr>
        <td><strong>Variación Cromática</strong></td>
        <td>{{biophiliaData.biophilia_chromatic_variance}}</td>
      </tr>
      <tr>
        <td><strong>Color Flores</strong></td>
        <td>{{biophiliaData.biophilia_flower_colour}}</td>
      </tr>
      <tr>
        <td><strong>Color Fruto</strong></td>
        <td>{{biophiliaData.biophilia_fruit_colour}}</td>
      </tr>
      <tr>
        <td><strong>Flores</strong></td>
        <td>{{biophiliaData.biophilia_flower_fragrance}}</td>
      </tr>
      <tr>
        <td><strong>Resto de la planta</strong></td>
        <td>{{biophiliaData.biophilia_plant_fragrance}}</td>
      </tr>
      <tr>
        <td><strong>Permanencia Hojas</strong></td>
        <td>{{biophiliaData.biophilia_leaves_permanence}}</td>
      </tr>
      <tr>
        <td><strong>Abundancia Flores</strong></td>
        <td>{{biophiliaData.biophilia_flower_abundance}}</td>
      </tr>
      <tr>
        <td><strong>Tamaño Flores</strong></td>
        <td>{{biophiliaData.biophilia_flower_size}}</td>
      </tr>
      <tr>
        <td><strong>Tamaño Fruto</strong></td>
        <td>{{biophiliaData.biophilia_fruit_size}}</td>
      </tr>
      <tr>
        <td><strong>Densidad de Copa</strong></td>
        <td>{{biophiliaData.biophilia_top_density}}</td>
      </tr>
      <tr>
        <td><strong>Forma Pendular</strong></td>
        <td>{{biophiliaData.biophilia_pendulum_shape}}</td>
      </tr>
    </tbody>
  </table>
</div>
