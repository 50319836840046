import { Specie } from '../species/specie';
import { SpecieAirQuality } from '../species/specie-air-quality';
import { SpecieBiodiversity } from '../species/specie-biodiversity';
import { SpecieBiophilia } from '../species/specie-biophilia';
import { SpecieThermalComfort } from '../species/specie-thermal-comfort';
import { SpecieCo2 } from '../species/specie-co2-absorption';

export class Inventory {
    id: number;
    distribution: number;
    unit_identification?: string;
    height?: number;
    dbh: number;
    leaf_area: number;
    biomass_gr: number;
    shadow_area: number;
    thermalComfort: SpecieThermalComfort;
    biodiversity: SpecieBiodiversity;
    biophilia: SpecieBiophilia;
    co2: SpecieCo2;

    co_absorption: number;
    no2_absorption: number;
    no_absorption: number;
    so2_absorption: number;
    pm1_absorption: number;
    pm2_5_absorption: number;
    pm10_absorption: number;

    co2_absorption: number;
    co2_projection_20: number;
    co2_projection_30: number;
    co2_projection_40: number;

    cov_emission: number;

    fromServer(serverData: any, airQuality: SpecieAirQuality, thermalComfort: SpecieThermalComfort,
               biodiversity: SpecieBiodiversity, biophilia: SpecieBiophilia, co2: SpecieCo2) {
        this.id = serverData.id;
        this.thermalComfort = thermalComfort;
        this.biodiversity = biodiversity;
        this.biophilia = biophilia;
        this.distribution = serverData.distribution;
        this.dbh = serverData.dbh;
        this.unit_identification = serverData.unit_identification;
        this.leaf_area = serverData.leaf_area;
        this.biomass_gr = serverData.biomass_gr;
        this.co2 = co2;
        this.co2_absorption = co2.co2_absorption;
        this.co2_projection_20 = co2.co2_20_years_projection;
        this.co2_projection_30 = co2.co2_30_years_projection;
        this.co2_projection_40 = co2.co2_40_years_projection;
        this.calculateAbsorption(airQuality);
    }
    calculateAbsorption(specie: SpecieAirQuality) {
        this.co_absorption = this.leaf_area * specie.co_absorption;
        this.no2_absorption = this.leaf_area * specie.no2_absorption;
        this.no_absorption = this.leaf_area * specie.no_absorption;
        this.so2_absorption = this.leaf_area * specie.so2_absorption;
        this.pm1_absorption = this.leaf_area * specie.pm1_absorption;
        this.pm2_5_absorption = this.leaf_area * specie.pm2_5_absorption;
        this.pm10_absorption = this.leaf_area * specie.pm10_absorption;

        this.cov_emission = this.biomass_gr * specie.cov_emission;
    }

    calculateFromDBH(specie: Specie) {
        if (specie.f_leaf_area_slope && specie.f_leaf_area_constant) {
            this.leaf_area = specie.calculate_leaf_area(this.dbh) * 1e4;
        } else {
            this.leaf_area = specie.leaf_area;
        }
        if (specie.f_biomass_slope && specie.f_biomass_constant) {
            this.biomass_gr = specie.calculate_biomass(this.dbh);
        } else {
            this.biomass_gr = specie.biomass_gr;
        }
        this.calculateShadowArea(specie);
    }
    calculateShadowArea(specie: Specie) {
        this.shadow_area = specie.calculate_shadow_area(this.dbh);
    }
}
