import { Component, OnInit } from '@angular/core';
import { Credentials } from '../credentials';
import { AuthService } from '../auth.service';
import { StateService } from '@uirouter/core';
import { MessageService } from 'src/app/message.service';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  credentials: Credentials;
  constructor(
    private authService: AuthService,
    private $state: StateService,
    public messageService: MessageService
  ) { }

  ngOnInit() {
    this.credentials = new Credentials();
  }

  onSubmit() {
    this.authService.authenticate(this.credentials).subscribe(
      () => this.$state.go('home.species'),
      () => {}
    );
  }
}
