import { Directive, Injectable, ElementRef, HostListener, Input, Output, EventEmitter} from '@angular/core';


@Directive({
  selector: '[appScrollSpy]'
})
export class ScrollSpyDirective {
  @Output() public sectionChange = new EventEmitter<string>();
  private currentSection: string;

  constructor(private el: ElementRef) {}

  @HostListener('scroll', ['$event'])
  onScroll($event: any) {
      let currentSection: string;
      const children = this.el.nativeElement.children;
      const scrollTop = $event.target.scrollTop;
      const parentOffset = $event.target.offsetTop;
      // tslint:disable-next-line:prefer-for-of
      for (let i = 0; i < children.length; i++) {
        const element = children[i];
        if ((element.offsetTop - parentOffset) <= scrollTop) {
            currentSection = element.id;
        }
      }
      if (currentSection !== this.currentSection) {
        this.currentSection = currentSection;
        this.sectionChange.emit(this.currentSection);
      }
  }

}
