export class SpecieCo2 {
    constructor(
        public id: number,
        public co2_absorption?: number,
        public co2_5_years_projection?: number,
        public co2_10_years_projection?: number,
        public co2_15_years_projection?: number,
        public co2_20_years_projection?: number,
        public co2_25_years_projection?: number,
        public co2_30_years_projection?: number,
        public co2_35_years_projection?: number,
        public co2_40_years_projection?: number
    ) { }
}