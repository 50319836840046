import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { CommonModule } from '@angular/common';
import { UIRouterModule, Transition, StateService } from '@uirouter/angular';
import { FormsModule } from '@angular/forms';
import { LoginComponent } from './login/login.component';
import { NewUserComponent } from './new-user/new-user.component';
import { AuthService } from './auth.service';

export function activateUser(transition: Transition) {
  const authService: AuthService = transition.injector().get(AuthService);
  const stateService: StateService = transition.router.stateService;
  return authService.activateUser(transition.params().token).toPromise()
  .then(
    () => true,
    () => stateService.target('login', undefined, { location: true })
  );
}
const loginState = {name: 'login', url : '/login', component: LoginComponent};
const welcomeState = {
  name: 'welcome',
  url: '/welcome/:token',
  component: NewUserComponent,
  deps: [Transition, AuthService],
  onEnter: activateUser
};

@NgModule({
  declarations: [LoginComponent, NewUserComponent],
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule,
    UIRouterModule.forChild({ states: [ loginState, welcomeState ]})
  ]
})
export class AuthModule { }
