import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { Specie } from '../specie';
import { SpecieAirQuality } from '../specie-air-quality';
import { SpecieEditorPollutionComponent } from '../specie-editor-pollution/specie-editor-pollution.component';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-air-quality',
  templateUrl: './specie-air-quality.component.html',
  styleUrls: ['./specie-air-quality.component.sass']
})
export class SpecieAirQualityComponent implements OnInit {

  @Input() specie: Specie;

  user: User;
  specieAirQuality: SpecieAirQuality;

  constructor(
      private modalService: NgbModal,
      private speciesService: SpecieService,
      private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.user;
    this.speciesService.getAirQuality(this.specie.id).subscribe(
      (data => this.specieAirQuality = data)
    );
  }

  editAirQuality() {
    const modalRef = this.modalService.open(SpecieEditorPollutionComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.specie = Object.assign({}, this.specieAirQuality);
    modalRef.result.then(
      (newSpecie) => Object.assign(this.specieAirQuality, newSpecie),
      (reason: any) => {}
    );
  }
}
