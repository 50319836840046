import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RangesListComponent } from './ranges-list/ranges-list.component';
import { UIRouterModule, Transition, StateService } from '@uirouter/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { RangeTableEditorComponent } from './range-table-editor/range-table-editor.component';
import { FormsModule } from '@angular/forms';
import { NgbNavModule } from '@ng-bootstrap/ng-bootstrap';


const rangesListState = {name: 'home.ranges', url: '/ranges', views: { content: {component: RangesListComponent, data: {requiresAuth: true}}}};
@NgModule({
  declarations: [
    RangesListComponent,
    RangeTableEditorComponent
  ],
  imports: [
    FormsModule,
    CommonModule,
    NgbNavModule,
    UIRouterModule.forChild({ states: [  rangesListState ]}),
  ]
})
export class RangesModule { }
