import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Simulation } from '../simulation';
import { SimulationService } from '../simulation.service';
import { Zone } from '../zone';
import { saveAs } from 'file-saver';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { val } from '@uirouter/core';

@Component({
  selector: 'app-report-downloader',
  templateUrl: './report-downloader.component.html',
  styleUrls: ['./report-downloader.component.sass']
})
export class ReportDownloaderComponent implements OnInit {
  @Input() units;
  @Input() space;
  @Input() selectedSimulation: Simulation;
  @Input() selectedZone: Zone;
  @Input() zones: Zone[];
  @Input() download_options;

  active = 'parameters_characteristics';
  selectedSimulations = {};
  availableSimulations = {};
  zone_for_distribution_download = null;
  error_flag = 0;

  parameters_air_quality = [
    {value: false, target: 'co_absorption', label: 'Absorción de CO'},
    {value: false, target: 'co_absorption_range', label: 'Rango de absorción de CO'},
    {value: false, target: 'no_absorption', label: 'Absorción de NO'},
    {value: false, target: 'no_absorption_range', label: 'Rango de absorción de NO'},
    {value: false, target: 'no2_absorption', label: 'Absorción de NO', subindex: '2'},
    {value: false, target: 'no2_absorption_range', label: 'Rango de absorción de NO', subindex: '2'},
    {value: false, target: 'so2_absorption', label: 'Absorción de SO', subindex: '2'},
    {value: false, target: 'so2_absorption_range', label: 'Rango de absorción de SO', subindex: '2'},
    {value: false, target: 'pm1_absorption', label: 'Absorción de PM', subindex: '1'},
    {value: false, target: 'pm1_absorption_range', label: 'Rango de absorción de PM', subindex: '1'},
    {value: false, target: 'pm2_5_absorption', label: 'Absorción de PM', subindex: '2,5'},
    {value: false, target: 'pm2_5_absorption_range', label: 'Rango de absorción de PM', subindex: '2,5'},
    {value: false, target: 'pm10_absorption', label: 'Absorción de PM', subindex: '10'},
    {value: false, target: 'pm10_absorption_range', label: 'Rango de absorción de PM', subindex: '10'},
    {value: false, target: 'cov_emission', label: 'Emisión de COVs'},
    {value: false, target: 'cov_emission_range', label: 'Rango de Emisión de COVs'}
  ]

  parameters_thermal_confort = [
    {value: false, target: 'specie.air_temp_reduction_pct', label: 'Reducción Tª aire (%)'},
    {value: false, target: 'air_temp_reduction_pct_range', label: 'Reducción Tª aire'},
    {value: false, target: 'specie.humidity_increase_pct', label: 'Incremento HR (%)'},
    {value: false, target: 'humidity_increase_pct_range', label: 'Incremento HR (Rango (1-5)'},
    {value: false, target: 'specie.pavement_temp_reduction_pct', label: 'Reducción de temperatura de la acera (%)'},
    {value: false, target: 'pavement_temp_reduction_pct_range', label: 'Reducción de la temperatura de la acera Rango 1-5'},
    {value: false, target: 'specie.asphalt_temp_reduction_pct', label: 'Reducción de temperatura del asfalto (%)'},
    {value: false, target: 'asphalt_temp_reduction_pct_range', label: 'Reducción de la temperatura del asfalto Rango 1-5'},
    {value: false, target: 'specie.grass_temp_reduction_pct', label: 'Reducción de temperatura del césped (%)'},
    {value: false, target: 'grass_temp_reduction_pct_range', label: 'Reducción de la temperatura del césped Rango 1-5'},
    {value: false, target: 'specie.pipeclay_temp_reduction_pct', label: 'Reducción de temperatura del albero (%)'},
    {value: false, target: 'pipeclay_temp_reduction_pct_range', label: 'Reducción de la temperatura del albero Rango 1-5'},
    {value: false, target: 'specie.uva_reduction_pct', label: 'Reducción UVA (%)'},
    {value: false, target: 'uva_reduction_pct_range', label: 'Reducción UVA (%) Rango 1-5'},
    {value: false, target: 'specie.uvbc_reduction_pct', label: 'Reducción UVBC (%)'},
    {value: false, target: 'uvbc_reduction_pct_range', label: 'Reducción UVBC (%) Rango 1-5'},
    {value: false, target: 'specie.solar_energy_reduction_pct', label: 'Reducción de energía solar (%)'},
    {value: false, target: 'specie.pavement_out_radiation_reduction_pct', label: 'Reducción de la radiación de salida en acera (%)'},
    {value: false, target: 'pavement_out_radiation_reduction_pct_range', label: 'Reducción de la radiación de salida en acera Rango 1-5'},
    {value: false, target: 'specie.asphalt_out_radiation_reduction_pct', label: 'Reducción de la radiación de salida en asfalto (%)'},
    {value: false, target: 'asphalt_out_radiation_reduction_pct_range', label: 'Reducción de la radiación de salida en asfalto Rango 1-5'},
    {value: false, target: 'specie.grass_out_radiation_reduction_pct', label: 'Reducción de la radiación de salida en césped (%)'},
    {value: false, target: 'grass_out_radiation_reduction_pct_range', label: 'Reducción de la radiación de salida en césped Rango 1-5'},
    {value: false, target: 'specie.pipeclay_out_radiation_reduction_pct', label: 'Reducción de la radiación de salida en albero (%)'},
    {value: false, target: 'pipeclay_out_radiation_reduction_pct_range', label: 'Reducción de la radiación de salida en albero Rango 1-5'},

    {value: false, target: 'specie.asphalt_temp_radiation_reduction_pct', label: 'Reducción de la temperatura radiante en acera (%)'},
    {value: false, target: 'asphalt_temp_radiation_reduction_pct_range', label: 'Reducción de la temperatura radiante en acera Rango 1-5'},
    {value: false, target: 'specie.grass_temp_radiation_reduction_pct', label: 'Reducción de la temperatura radiante en césped (%)'},
    {value: false, target: 'grass_temp_radiation_reduction_pct_range', label: 'Reducción de la temperatura radiante en césped Rango 1-5'},
    {value: false, target: 'specie.pipeclay_temp_radiation_reduction_pct', label: 'Reducción de la temperatura radiante en albero (%)'},
    {value: false, target: 'pipeclay_temp_radiation_reduction_pct_range', label: 'Reducción de la temperatura radiante en albero Rango 1-5'},

    {value: false, target: 'specie.wgtb_pavement', label: 'Índice WGTB Acera (%)'},
    {value: false, target: 'wgtb_pavement_range', label: 'Índice WGTB Acera Rango 1-5'},
    {value: false, target: 'specie.wgtb_grass', label: 'Índice WGTB Césped (%)'},
    {value: false, target: 'wgtb_grass_range', label: 'Índice WGTB Césped Rango 1-5'},
    {value: false, target: 'specie.wgtb_pipeclay', label: 'Índice WGTB Albero (%)'},
    {value: false, target: 'wgtb_pipeclay_range', label: 'Índice WGTB Albero Rango 1-5'},
    {value: false, target: 'specie.wgtb_asphalt', label: 'Índice WGTB Asfalto (%)'},
    {value: false, target: 'wgtb_asphalt_range', label: 'Índice WGTB Asfalto Rango 1-5'},
  ]

  parameters_biodiversity_biophilia = [
    {value: false, target: 'specie.biodiversity_trophic_resource', label: 'Recurso trófico'},
    {value: false, target: 'biodiversity_trophic_resource_range', label: 'Recurso trófico Rango (1-5)'},
    {value: false, target: 'specie.biodiversity_shelter_resource', label: 'Recurso de refugio'},
    {value: false, target: 'biodiversity_shelter_resource_range', label: 'Recurso de refugio Rango (1-5)'},
    {value: false, target: 'specie.biophilia_chromatism_resource', label: 'Cromatismo'},
    {value: false, target: 'biophilia_chromatism_resource_range', label: 'Cromatismo Rango (1-5)'},
    {value: false, target: 'specie.biophilia_fragrance_resource', label: 'Aroma'},
    {value: false, target: 'biophilia_fragrance_resource_range', label: 'Aroma Rango (1-5)'},
    {value: false, target: 'specie.biophilia_ornateness_resource', label: 'Vistosidad'},
    {value: false, target: 'biophilia_ornateness_resource_range', label: 'Vistosidad Rango (1-5)'},
  ]

  parameters_resilience = [
    {value: false, target: 'specie.freeze_tolerance', label: 'Tolerancia a las heladas'},
    {value: false, target: 'specie.plague_tolerance', label: 'Tolerancia a plagas y enfermedades'},
    {value: false, target: 'specie.compact_terrain_tolerance', label: 'Tolerancia a suelos pobres'},
    {value: false, target: 'specie.saline_terrain_tolerance', label: 'Tolerancia a suelos salinos'},
    {value: false, target: 'specie.drought_resistance_se', label: 'Resistencia a la sequedad elevada'},
    {value: false, target: 'specie.drought_resistance_sm', label: 'Resistencia a la sequedad media'},
    {value: false, target: 'specie.drought_resistance_sd', label: 'Resistencia a la sequedad débil'},
    {value: false, target: 'specie.drought_resistance_he', label: 'Resistencia a la humedad elevada'},
    {value: false, target: 'specie.drought_resistance_hm', label: 'Resistencia a la humedad media'},
    {value: false, target: 'specie.drought_resistance_hd', label: 'Resistencia a la humedad débil'},
    {value: false, target: 'specie.stability_index', label: 'Índice de estabilidad'},
    {value: false, target: 'specie.heat_tolerance_10', label: 'Tolerancia a la Tª (10ºC)'},
    {value: false, target: 'specie.heat_tolerance_20', label: 'Tolerancia a la Tª a (20ºC)'},
    {value: false, target: 'specie.heat_tolerance_30', label: 'Tolerancia a la Tª a (30ºC)'},
    {value: false, target: 'specie.wue_10', label: 'WUE (10ºC)'},
    {value: false, target: 'wue_10_range', label: 'Wue (10ºC) Rango (1-5)'},
    {value: false, target: 'specie.wue_20', label: 'WUE (20ºC)'},
    {value: false, target: 'wue_20_range', label: 'Wue (20ºC) Rango (1-5)'},
    {value: false, target: 'specie.wue_30', label: 'WUE (30ºC)'},
    {value: false, target: 'wue_30_range', label: 'Wue (30ºC) Rango (1-5)'},
  ]

  parameters_characteristics = [
    {value: true, target: 'specie.science_name', label: 'Nombre científico'},
    {value: true, target: 'specie.name', label: 'Nombre común'},
    {value: false, target: 'specie.id', label: 'Identificador'},
    {value: false, target: 'specie.typology', label: 'Tipo'},
    {value: false, target: 'specie.leaves_kind', label: 'Tipo de hoja'},
    {value: false, target: 'specie.growth_speed', label: 'Velocidad de crecimiento'},
    {value: false, target: 'specie.size', label: 'Porte'},
    {value: false, target: 'specie.longevity', label: 'Longevidad'},
    {value: false, target: 'specie.top_description', label: 'Copa (forma)'},
    {value: false, target: 'specie.prunable', label: 'Admisión de poda'},
    {value: false, target: 'specie.allergenicity', label: 'Alergenicidad'},
    {value: false, target: 'specie.leaves_description', label: 'Forma de hoja'},
    {value: false, target: 'specie.ornamental_properties', label: 'Propiedades ornamentales'},
    {value: false, target: 'specie.habitat', label: 'Hábitat'},
    {value: false, target: 'specie.pavement_affectation', label: 'Afectación de acera'},
    {value: false, target: 'specie.waste_description', label: 'Descripción de residuos'},
    {value: false, target: 'specie.observations', label: 'Observaciones'},
    {value: false, target: 'specie.lai', label: 'Índice de área foliar (LAI)'},
    {value: false, target: 'specie.size', label: 'Tamaño'},
    {value: false, target: 'specie.usage', label: 'Usos'},
  ] 

  parameters_biometric = [
    {value: false, target: 'specie.dbh', label: 'DBH (cm)'},
    {value: false, target: 'specie.height', label: 'Altura (m)'},
    {value: false, target: 'specie.crown_diameter', label: 'Diámetro de copa medio (m)'},
    {value: false, target: 'specie_shadow_area', label: 'Superficie de sombreamiento (m²)'},
    {value: false, target: 'specie_coberture', label: 'Cobertura (m²)'},
    {value: false, target: 'specie_leaf_area', label: 'Area foliar (m²)'},
    {value: false, target: 'biomass_gr', label: 'Biomasa (Kg)'},
  ]

  parameter_co2 = [
    {value: false, target: 'co2_absorption', label: 'Absorción de CO₂ (Kg/año)'},
    {value: false, target: 'co2_absorption_range', label: 'Absorción de CO₂ (Kg/año) Rango (1-5)'},
    {value: false, target: 'co2_5_years_projection', label: 'Almacenamiento CO₂ en 5 años (Kg)'},
    {value: false, target: 'co2_5_years_projection_range', label: 'Almacenamiento CO₂ en 5 años (Kg) Rango (1-5)'},
    {value: false, target: 'co2_10_years_projection', label: 'Almacenamiento CO₂ en 10 años (Kg)'},
    {value: false, target: 'co2_10_years_projection_range', label: 'Almacenamiento CO₂ en 10 años (Kg) Rango (1-5)'},
    {value: false, target: 'co2_15_years_projection', label: 'Almacenamiento CO₂ en 15 años (Kg)'},
    {value: false, target: 'co2_15_years_projection_range', label: 'Almacenamiento CO₂ en 15 años (Kg) Rango (1-5)'},
    {value: false, target: 'co2_20_years_projection', label: 'Almacenamiento CO₂ en 20 años (Kg)'},
    {value: false, target: 'co2_20_years_projection_range', label: 'Almacenamiento CO₂ en 20 años (Kg) Rango (1-5)'},
    {value: false, target: 'co2_25_years_projection', label: 'Almacenamiento CO₂ en 25 años (Kg)'},
    {value: false, target: 'co2_25_years_projection_range', label: 'Almacenamiento CO₂ en 25 años (Kg) Rango (1-5)'},
    {value: false, target: 'co2_30_years_projection', label: 'Almacenamiento CO₂ en 30 años (Kg)'},
    {value: false, target: 'co2_30_years_projection_range', label: 'Almacenamiento CO₂ en 30 años (Kg) Rango (1-5)'},
    {value: false, target: 'co2_35_years_projection', label: 'Almacenamiento CO₂ en 35 años (Kg)'},
    {value: false, target: 'co2_35_years_projection_range', label: 'Almacenamiento CO₂ en 35 años (Kg) Rango (1-5)'},
    {value: false, target: 'co2_40_years_projection', label: 'Almacenamiento CO₂ en 40 años (Kg)'},
    {value: false, target: 'co2_40_years_projection_range', label: 'Almacenamiento CO₂ en 40 años (Kg) Rango (1-5)'}
  ]

  parameter_diversity = [
    {value: false, target: 'percentage_over_the_total', label: '% sp sobre el total'},
    {value: false, target: 'shannon_index', label: 'Índice de Shannon'},
  ]

  constructor(
    private activeModal: NgbActiveModal,
    private simulationService: SimulationService,
  ) { }


  ngOnInit(): void {
    this.zones.forEach(zone => {
      if (zone.id !== this.selectedZone.id) {
        this.simulationService.loadSimulations(zone.id).subscribe(
          (simulations: Simulation[]) => simulations.forEach(simulation => {
            if (this.availableSimulations[simulation.zone] === undefined){
              this.availableSimulations[simulation.zone] = [];
            }
            this.availableSimulations[simulation.zone].push(simulation);
          }
        ));
      }
    });
  }
  dismiss() {
    this.activeModal.dismiss();
  }

  changeValue(parameter) {
    parameter.value = !parameter.value;
  }

  download() {
    const units = this.units.split('/')[0];
    const simulationsToInclude = [];
    for (const zoneId in this.selectedSimulations) {
      if (this.selectedSimulations[zoneId]) {
        simulationsToInclude.push(this.selectedSimulations[zoneId]);
      }
    }

    if (this.download_options === 1) {
      if (this.zone_for_distribution_download !== null){ {
        if (this.zone_for_distribution_download === "all"){
          this.zone_for_distribution_download = [];
          //add all the zones to the array
          this.zones.forEach(zone => {
            this.zone_for_distribution_download.push(zone.id);
          });
        }
        else {
          const auxliary_id = this.zone_for_distribution_download;
          this.zone_for_distribution_download = [];
          this.zone_for_distribution_download.push(auxliary_id);
        }
        this.error_flag
      }
      } 
      else {
        this.error_flag = 1;
        return;
      }
    }
    // Gather all parameters that have value true
    const parameters = [];

    this.parameters_air_quality.forEach(parameter => {
      if (parameter.value) {
        parameters.push(parameter.target);
      }
    });

    this.parameters_thermal_confort.forEach(parameter => {
      if (parameter.value) {
        parameters.push(parameter.target);
      }
    });

    this.parameters_biodiversity_biophilia.forEach(parameter => {
      if (parameter.value) {
        parameters.push(parameter.target);
      }
    });

    this.parameters_resilience.forEach(parameter => {
      if (parameter.value) {
        parameters.push(parameter.target);
      }
    });

    this.parameters_characteristics.forEach(parameter => {
      if (parameter.value) {
        parameters.push(parameter.target);
      }
    });

    this.parameters_biometric.forEach(parameter => {
      if (parameter.value) {
        parameters.push(parameter.target);
      }
    });

    this.parameter_co2.forEach(parameter => {
      if (parameter.value) {
        parameters.push(parameter.target);
      }
    });

    this.parameter_diversity.forEach(parameter => {
      if (parameter.value) {
        parameters.push(parameter.target);
      }
    });

    if (this.download_options !== 1) {
      simulationsToInclude.push(this.selectedSimulation.id);
    }
    this.simulationService.downloadReport(this.space, simulationsToInclude, units, parameters, this.download_options, this.zone_for_distribution_download).subscribe(
      (data: Blob) => {
        const blob = new Blob([data], { type: 'application/ms-excel' });
        saveAs(blob, 'simulation.xlsx');
        this.dismiss();
    });
  }

}
