import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Specie } from '../specie';
import { SpecieBiodiversity } from '../specie-biodiversity';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-editor-biodiversity',
  templateUrl: './specie-editor-biodiversity.component.html',
  styleUrls: ['./specie-editor-biodiversity.component.sass']
})
export class SpecieEditorBiodiversityComponent implements OnInit {

  @Input() specie: Specie;
  @Input() biodiversityData: SpecieBiodiversity;
  constructor(
    private activeModal: NgbActiveModal,
    private speciesService: SpecieService
  ) { }

  ngOnInit() {
  }
  save() {
      this.speciesService.updateBiodiversity(this.biodiversityData).subscribe(
        (biodiversityData: SpecieBiodiversity) => this.activeModal.close(biodiversityData),
        () => {}
      );
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
