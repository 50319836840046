<div class="container-fluid">
  <div class="col col-sm-12 col-md-12 d-flex flex-column justify-content-start titulo-parametro">
    <h2>Definición de rangos</h2>
  </div>
  <div ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs justify-content-end">
    <li [ngbNavItem]="'airQuality'">
      <a ngbNavLink>Calidad del Aire</a>
      <ng-template ngbNavContent>
        <div class="row parametros-row">
          <div class="col col-sm-12 col-md-6 col-lg-4 my-2" *ngFor="let range of parameters_air_quality_ranges">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title"><strong>{{range.label}}</strong></h5>
                <select class="form-control" (change)="typeChange($event.target.value, range)" >
                  <option value="DIRECT" [attr.selected]="range.type_of_range === 'DIRECT' ? 'selected' : null">Rango directo</option>
                  <option value="LINEAR" [attr.selected]="range.type_of_range === 'LINEAR' ? 'selected' : null" >Rango lineal</option>
                  <option value="NON_LINEAR" [attr.selected]="range.type_of_range === 'NON_LINEAR' ? 'selected' : null" >Rango no lineal</option>
                </select>
                <div class="button_range_div">
                  <button *ngIf="range.type_of_range === 'NON_LINEAR'" class="btn btn-outline-success" (click)="editTable(range)">Editar tabla</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  
    <li [ngbNavItem]="'thermalConfort'">
      <a ngbNavLink>Confort Térmico</a>
      <ng-template ngbNavContent>
        <div class="row parametros-row">
          <div class="col col-sm-12 col-md-6 col-lg-4 my-2" *ngFor="let range of parameters_thermal_confort_ranges">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title"><strong>{{range.label}}</strong></h5>
                <select class="form-control" (change)="typeChange($event.target.value, range)" >
                  <option value="DIRECT" [attr.selected]="range.type_of_range === 'DIRECT' ? 'selected' : null">Rango directo</option>
                  <option value="LINEAR" [attr.selected]="range.type_of_range === 'LINEAR' ? 'selected' : null" >Rango lineal</option>
                  <option value="NON_LINEAR" [attr.selected]="range.type_of_range === 'NON_LINEAR' ? 'selected' : null" >Rango no lineal</option>
                </select>
                <div class="button_range_div">
                  <button *ngIf="range.type_of_range === 'NON_LINEAR'" class="btn btn-outline-success" (click)="editTable(range)">Editar tabla</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  
    <li [ngbNavItem]="'biodiversity'">
      <a ngbNavLink>Biodiversidad y Biofilia</a>
      <ng-template ngbNavContent>
        <div class="row parametros-row">
          <div class="col col-sm-12 col-md-6 col-lg-4 my-2" *ngFor="let range of parameters_biodiversity_biophilia_ranges">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title"><strong>{{range.label}}</strong></h5>
                <select class="form-control" (change)="typeChange($event.target.value, range)" >
                  <option value="DIRECT" [attr.selected]="range.type_of_range === 'DIRECT' ? 'selected' : null">Rango directo</option>
                  <option value="LINEAR" [attr.selected]="range.type_of_range === 'LINEAR' ? 'selected' : null" >Rango lineal</option>
                  <option value="NON_LINEAR" [attr.selected]="range.type_of_range === 'NON_LINEAR' ? 'selected' : null" >Rango no lineal</option>
                </select>
                <div class="button_range_div">
                  <button *ngIf="range.type_of_range === 'NON_LINEAR'" class="btn btn-outline-success" (click)="editTable(range)">Editar tabla</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  
    <li [ngbNavItem]="'resilience'">
      <a ngbNavLink>Resiliencia</a>
      <ng-template ngbNavContent>
        <div class="row parametros-row">
          <div class="col col-sm-12 col-md-6 col-lg-4 my-2" *ngFor="let range of parameters_resilience_ranges">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title"><strong>{{range.label}}</strong></h5>
                <select class="form-control" (change)="typeChange($event.target.value, range)" >
                  <option value="DIRECT" [attr.selected]="range.type_of_range === 'DIRECT' ? 'selected' : null">Rango directo</option>
                  <option value="LINEAR" [attr.selected]="range.type_of_range === 'LINEAR' ? 'selected' : null" >Rango lineal</option>
                  <option value="NON_LINEAR" [attr.selected]="range.type_of_range === 'NON_LINEAR' ? 'selected' : null" >Rango no lineal</option>
                </select>
                <div class="button_range_div">
                  <button *ngIf="range.type_of_range === 'NON_LINEAR'" class="btn btn-outline-success" (click)="editTable(range)">Editar tabla</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  
    <li [ngbNavItem]="'characteristics'">
      <a ngbNavLink>Características de Especie</a>
      <ng-template ngbNavContent>
        <div class="row parametros-row">
          <div class="col col-sm-12 col-md-6 col-lg-4 my-2" *ngFor="let range of parameters_characteristics_ranges">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title"><strong>{{range.label}}</strong></h5>
                <select class="form-control" (change)="typeChange($event.target.value, range)" >
                  <option value="DIRECT" [attr.selected]="range.type_of_range === 'DIRECT' ? 'selected' : null">Rango directo</option>
                  <option value="LINEAR" [attr.selected]="range.type_of_range === 'LINEAR' ? 'selected' : null" >Rango lineal</option>
                  <option value="NON_LINEAR" [attr.selected]="range.type_of_range === 'NON_LINEAR' ? 'selected' : null" >Rango no lineal</option>
                </select>
                <div class="button_range_div">
                  <button *ngIf="range.type_of_range === 'NON_LINEAR'" class="btn btn-outline-success" (click)="editTable(range)">Editar tabla</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  
    <li [ngbNavItem]="'co2'">
      <a ngbNavLink>Absorción de CO<sub>2</sub></a>
      <ng-template ngbNavContent>
        <div class="row parametros-row">
          <div class="col col-sm-12 col-md-6 col-lg-4 my-2" *ngFor="let range of parameter_co2_ranges">
            <div class="card">
              <div class="card-body">
                <h5 class="card-title"><strong>{{range.label}}</strong></h5>
                <select class="form-control" (change)="typeChange($event.target.value, range)" >
                  <option value="DIRECT" [attr.selected]="range.type_of_range === 'DIRECT' ? 'selected' : null">Rango directo</option>
                  <option value="LINEAR" [attr.selected]="range.type_of_range === 'LINEAR' ? 'selected' : null" >Rango lineal</option>
                  <option value="NON_LINEAR" [attr.selected]="range.type_of_range === 'NON_LINEAR' ? 'selected' : null" >Rango no lineal</option>
                </select>
                <div class="button_range_div">
                  <button *ngIf="range.type_of_range === 'NON_LINEAR'" class="btn btn-outline-success" (click)="editTable(range)">Editar tabla</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    </li>
  </div>
  
  <div [ngbNavOutlet]="nav"></div>
  
  </div>