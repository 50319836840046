import { Injectable } from '@angular/core';
import { User } from '../auth/user';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { tap, catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Observable, of } from 'rxjs';
import { MessageService } from '../message.service';
import { Message } from '../message';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  users: User[];

  constructor(
    private http: HttpClient,
    private messageService: MessageService
  ) { }

  loadUsers(): Observable<User[]> {
    const url = environment.serverUrl + '/users/';
    return this.http.get<User[]>(url).pipe(
      tap((users: User[]) => {
        this.users = users;
        return users;
      })
    );
  }

  createUser(user: User): Observable<User> {
    const url = environment.serverUrl + '/users/';
    return this.http.post<User>(url, user).pipe(
      tap((newUser: User) => {
        this.users.push(newUser);
        this.messageService.add(new Message('ok', 'Usuario creado correctamente'));
        return newUser;
      }),
      catchError((httpError: HttpErrorResponse) => {
        Object.keys(httpError.error).forEach(errorType =>
          httpError.error[errorType].forEach(message =>
            this.messageService.add(new Message('error', message))
          )
        );
        throw httpError;
      })
    );
  }

  deleteUser(user: User): Observable<void> {
    const url =  environment.serverUrl + '/users/' + user.id;
    return this.http.delete<void>(url).pipe(
      tap(() => {
        const idx = this.users.indexOf(user);
        this.users.splice(idx, 1);
        this.messageService.add(new Message('ok', 'Usuario eliminado correctamente'));
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messageService.add(new Message('error', httpError.error));
        throw httpError;
      })
    );
  }
}
