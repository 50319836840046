import { Distribution } from './distribution';
import { SimulationFilter } from './simulation-filter';

export class Simulation {
    id: number;
    name: string;
    author: number;
    collaborators: number[];
    zone: number;
    distribution: Distribution[];
    filters: SimulationFilter;
    display_units: string;

    constructor() {
        this.filters = new SimulationFilter();
    }
}
