import { combineLatest, forkJoin, Observable, of } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { Specie } from '../species/specie';
import { SpecieAirQuality } from '../species/specie-air-quality';
import { SpecieBiodiversity } from '../species/specie-biodiversity';
import { SpecieBiophilia } from '../species/specie-biophilia';
import { SpecieThermalComfort } from '../species/specie-thermal-comfort';
import { SpecieService } from '../species/specie.service';
import { Inventory } from './inventory';
import { SpecieCo2 } from '../species/specie-co2-absorption';

export class Distribution {
    id: number;
    specie: Specie;
    airQuality: SpecieAirQuality;
    thermalComfort: SpecieThermalComfort;
    biodiversity: SpecieBiodiversity;
    biophilia: SpecieBiophilia;
    co2: SpecieCo2;

    amount: number;
    leaf_area: number;
    biomass_gr: number;
    shadow_area: number;
    inventory: Inventory[];

    co_absorption: number;
    no2_absorption: number;
    no_absorption: number;
    so2_absorption: number;
    pm1_absorption: number;
    pm2_5_absorption: number;
    pm10_absorption: number;

    cov_emission: number;
    co2_absorption: number;
    co2_5_years_projection: number;
    co2_10_years_projection: number;
    co2_15_years_projection: number;
    co2_20_years_projection: number;
    co2_25_years_projection: number;
    co2_30_years_projection: number;
    co2_35_years_projection: number;
    co2_40_years_projection: number;



    zone: string;

    constructor(private speciesService: SpecieService) {
        this.inventory = [];
    }

    absorptionArea(): number {
        if (this.leaf_area) {
            return this.leaf_area;
        } else {
            return this.specie.leaf_area * this.amount;
        }
    }

    fromServer(serverData: any): Observable<Distribution> {
        this.id = serverData.id;
        return forkJoin(
            this.speciesService.getAirQuality(serverData.specie).pipe(
                tap((airQuality: SpecieAirQuality) => this.airQuality = airQuality)),
            this.speciesService.getEfficiency(serverData.specie).pipe(
                tap((thermalComfort: SpecieThermalComfort) => this.thermalComfort = thermalComfort)),
            this.speciesService.getSpecie(serverData.specie).pipe(
                tap((specie: Specie) => this.specie = specie)),
            this.speciesService.getBiodiversity(serverData.specie).pipe(
                tap((biodiversiy: SpecieBiodiversity) => this.biodiversity = biodiversiy)),
            this.speciesService.getBiophilia(serverData.specie).pipe(
                tap((biophilia: SpecieBiophilia) => this.biophilia = biophilia)),
            this.speciesService.getCo2Absorption(serverData.specie).pipe(
                tap((co2Absorption: SpecieCo2) => this.co2 = co2Absorption)
            )
        ).pipe(map(() => {
            this.inventory = [];
            serverData.inventory.forEach((serverInventory: any) => {
              const inventory = new Inventory();
              inventory.fromServer(serverInventory, this.airQuality, this.thermalComfort, this.biodiversity, this.biophilia, this.co2);
              inventory.calculateShadowArea(this.specie);
              this.inventory.push(inventory);
            });
            this.calculateGlobalValues();
            this.calculateAbsorption();
            this.calculateCo2Absorption();
            return this;
        }));
    }
    calculateGlobalValues() {
        this.leaf_area = 0;
        this.biomass_gr = 0;
        this.shadow_area = 0;
        this.amount = this.inventory.length;
        this.inventory.forEach(inventoryLine => {
            this.leaf_area += inventoryLine.leaf_area;
            this.biomass_gr += inventoryLine.biomass_gr;
            this.shadow_area += inventoryLine.shadow_area;
        });
    }
    calculateAbsorption() {
        this.co_absorption = this.absorptionArea() * this.airQuality.co_absorption;
        this.no2_absorption = this.absorptionArea() * this.airQuality.no2_absorption;
        this.no_absorption = this.absorptionArea() * this.airQuality.no_absorption;
        this.so2_absorption = this.absorptionArea() * this.airQuality.so2_absorption;
        this.pm1_absorption = this.absorptionArea() * this.airQuality.pm1_absorption;
        this.pm2_5_absorption = this.absorptionArea() * this.airQuality.pm2_5_absorption;
        this.pm10_absorption = this.absorptionArea() * this.airQuality.pm10_absorption;

        this.cov_emission = this.biomass_gr *  this.airQuality.cov_emission;
    }

    calculateCo2Absorption() {
        this.co2_absorption = this.amount * this.co2.co2_absorption;
        this.co2_5_years_projection = this.co2.co2_5_years_projection;
        this.co2_10_years_projection = this.co2.co2_10_years_projection;
        this.co2_15_years_projection = this.co2.co2_15_years_projection;
        this.co2_20_years_projection = this.co2.co2_20_years_projection;
        this.co2_25_years_projection = this.co2.co2_25_years_projection;
        this.co2_30_years_projection = this.co2.co2_30_years_projection;
        this.co2_35_years_projection = this.co2.co2_35_years_projection;
        this.co2_40_years_projection = this.co2.co2_40_years_projection;
        

        

    }
    addUnit(inventory?: Inventory) {
        if (inventory === undefined) {
            inventory = new Inventory();
            inventory.thermalComfort = this.thermalComfort;
            inventory.distribution = this.id;
            if (this.specie.dbh === null) {
                inventory.dbh = 0;
            } else {
                inventory.dbh = this.specie.dbh;
            }
            inventory.calculateFromDBH(this.specie);
            inventory.calculateAbsorption(this.airQuality);
        }
        this.inventory.push(inventory);
        this.calculateGlobalValues();
        this.calculateAbsorption();
        this.calculateCo2Absorption();
    }
    popUnit(index: number= this.inventory.length - 1) {
        this.inventory.splice(index, 1);
        this.calculateGlobalValues();
        this.calculateAbsorption();
        this.calculateCo2Absorption();
    }


    updateSpecie(specie: Specie): Observable<Distribution> {
        this.specie = specie;
        return forkJoin(
            this.speciesService.getAirQuality(specie.id).pipe(
                tap((airQuality: SpecieAirQuality) => this.airQuality = airQuality)),
            this.speciesService.getEfficiency(specie.id).pipe(
                tap((thermalComfort: SpecieThermalComfort) => this.thermalComfort = thermalComfort)),
            this.speciesService.getCo2Absorption(specie.id).pipe(
                tap((co2Absorption: SpecieCo2) => this.co2 = co2Absorption)
            )
        ).pipe(map(() => {
            this.inventory = [];
            this.calculateGlobalValues();
            this.calculateAbsorption();
            return this;
        }));
    }

    toServer(): any {
        const data = {
            id: this.id,
            specie: this.specie.id,
            amount: this.amount,
            inventory: []
        };
        this.inventory.forEach((line: Inventory) => {
            data.inventory.push({
                id: line.id,
                distribution: this.id,
                dbh: line.dbh,
                leaf_area: line.leaf_area,
                biomass_gr: line.biomass_gr,
                unit_identification: line.unit_identification
            });
        });
        return data;
    }
}
