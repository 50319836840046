import { SpecieAirQuality } from './specie-air-quality';
import { SpecieThermalComfort } from './specie-thermal-comfort';

export class Specie {
     constructor(
        public id?: number,
        public name?: string,
        public science_name?: string,
        public typology?: string,
        public treeType?: string,
        public size?: string,
        public leaves_kind?: string,
        public growth_speed?: string,
        public longevity?: string,
        public usage?: string,
        public leaves_description?: string,
        public top_description?: string,
        public ornamental_properties?: string,
        public habitat?: string,
        public prunable: boolean = false,
        public pruning_observations?: string,
        public pavement_affectation?: string,
        public generates_waste: boolean = false,
        public waste_description?: string,
        public allergenicity?: string,
        public observations?: string,
        public dbh: number = 0,
        public min_dbh: number = 0,
        public lai?: number,
        public crown_diameter?: number,
        public height?: number,
        public leaf_area?: number,
        public biomass_gr?: number,
        public f_biomass_slope?: number,
        public f_biomass_constant?: number,
        public f_top_slope?: number,
        public f_top_constant?: number,
        public f_leaf_area_slope?: number,
        public f_leaf_area_constant?: number,
        public co2_storage?: number
    ) {}

    calculate_biomass(dbh: number = this.dbh) {
        const min_dbh = Math.max(dbh, this.min_dbh);
        return this.f_biomass_slope * min_dbh + this.f_biomass_constant;
    }
    calculate_top_width(dbh: number = this.dbh) {
        const min_dbh = Math.max(dbh, this.min_dbh);
        return this.f_top_slope * min_dbh + this.f_top_constant;
    }
    calculate_leaf_area(dbh: number = this.dbh) {
        const min_dbh = Math.max(dbh, this.min_dbh);
        return this.f_leaf_area_slope * min_dbh + this.f_leaf_area_constant;
    }
    calculate_shadow_area(dbh: number = this.dbh) {
        const min_dbh = Math.max(dbh, this.min_dbh);
        return Math.pow(this.calculate_top_width(min_dbh) / 2, 2 ) * Math.PI ;
    }

    // From species\specie-editor-base\specie-editor-base.component.ts
    public static readonly TYPOLOGIES: string[] = [ 
        'Árbol', 'Arbusto', 'Herbácea', 'Trepadora' 
    ];

    public static getStandarisedTypology(typology: string): string {
        if (typology != undefined)
        {
            return typology.replace(/á/gi, 'A').toUpperCase();
        }
        return "";
    }

    public static getTypologiesObject(): object {
        return Specie.TYPOLOGIES.map( ( typology: string ) => { return { 
            'value': Specie.getStandarisedTypology( typology ),
            'label': typology
        } } );
    }

}
