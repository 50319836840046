<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar {{ specie.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #editsSaceForm="ngForm" (ngSubmit)="save(editsSaceForm)">
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <label for="name">Nombre común (este campo es obligatorio)</label>
          <input type="text" [(ngModel)]="specie.name" required class="form-control" id="name" name="name" placeholder="Este campo es obligatorio">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="scientific">Nombre científico (este campo es obligatorio)</label>
          <input type="text" [(ngModel)]="specie.science_name" required class="form-control" id="scientific" name="scientific" placeholder="Este campo es obligatorio">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="typology">Tipo (este campo es obligatorio)</label>
          <select required class="form-control" id="typology"  [(ngModel)]="specie.typology" name="typology" placeholder="Este campo es obligatorio" >
            <option *ngFor="let treeType of treeTypes" [value]="treeType" >{{treeType}}</option>
          </select>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-4 col-sm-12">
          <label for="leafKind">Tipo de hoja (este campo es obligatorio)</label>
          <select class="form-control" id="leafKind" required [(ngModel)]="specie.leaves_kind" name="leafKind">
            <option *ngFor="let leafKind of leafKinds" [value]="leafKind">{{leafKind}}</option>
          </select>
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="speed">Velocidad de crecimiento</label>
          <input type="text" [(ngModel)]="specie.growth_speed" class="form-control" id="speed" name="speed"
            placeholder="Lento">
        </div>
        <div class="form-group col-md-4 col-sm-12">
          <label for="size">Porte</label>
          <input type="text" [(ngModel)]="specie.size" class="form-control" id="size" name="size"
            placeholder="Porte mediano">
        </div>

      </div>
      <div class="form-row">
        <div class="form-group col-lg-3 col-md-6 col-sm-12">
          <label for="longevity">Longevidad</label>
          <input type="text" [(ngModel)]="specie.longevity" class="form-control" id="longevity" name="longevity"
            placeholder="150 años" ngModel #longevityField="ngModel">

        </div>
        <div class="form-group col-lg-3 col-md-6 col-sm-12">
          <label for="height">Altura (m)</label>
          <input type="number" [(ngModel)]="specie.height" class="form-control" id="height" name="height"
            placeholder="">
        </div>

        <div class="form-group col-lg-3 col-md-6 col-sm-12">
          <label for="lai">LAI</label>
          <input type="number" [(ngModel)]="specie.lai" class="form-control" id="lai" name="lai" placeholder="">
        </div>
        <div class="form-group col-lg-3 col-md-6 col-sm-12">
          <label for="crown_diameter">Diámetro de copa medio (m)</label>
          <input type="number" [(ngModel)]="specie.crown_diameter" class="form-control" id="crown_diameter" name="crown_diameter" placeholder="" step="0.01">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-6 col-md-12">
          <label for="allergenicity">Alergenicidad</label>
          <textarea class="form-control" rows="4" [(ngModel)]="specie.allergenicity" name="allergenicity"
            id="allergenicity"></textarea>
        </div>

        <div class="form-group col-md-6 col-sm-12">
          <label for="pavementAffectation">Afectación al pavimento</label>
          <textarea rows="4" [(ngModel)]="specie.pavement_affectation" class="form-control" id="pavementAffectation"
            name="pavementAffectation" placeholder=""></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="top">Descripción de la copa</label>
          <textarea class="form-control" rows="4" [(ngModel)]="specie.top_description" name="top" id="top"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="leaves_description">Descripción de las hojas</label>
          <textarea class="form-control" rows="4" [(ngModel)]="specie.leaves_description" name="leaves_description"
            id="leaves_description"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="ornamental">Cualidades ornamentales</label>
          <textarea class="form-control" rows="4" [(ngModel)]="specie.ornamental_properties" name="ornamental"
            id="ornamental"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="usage">Usos potenciales</label>
          <textarea class="form-control" rows="4" [(ngModel)]="specie.usage" name="usage" id="usage"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12 col-sm-12">
          <label for="habitat">Habitat natural</label>
          <textarea class="form-control" rows="4" [(ngModel)]="specie.habitat" name="habitat" id="habitat"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12 col-lg-6">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="prunable" [(ngModel)]="specie.prunable"
              name="prunable">
            <label class="custom-control-label" for="prunable">Admite poda</label>
          </div>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="prunningObservations">Observaciones de poda</label>
          <textarea class="form-control" rows="4" [disabled]="!specie.prunable"
            [(ngModel)]="specie.pruning_observations" name="prunningObservations" id="prunningObservations"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-12 col-lg-6">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="generatesWaste" [(ngModel)]="specie.generates_waste"
              name="generatesWaste">
            <label class="custom-control-label" for="generatesWaste">Genera residuos</label>
          </div>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="wasteObservations">Descripción de los residuos</label>
          <textarea class="form-control" rows="4" [disabled]="!specie.generates_waste"
            [(ngModel)]="specie.waste_description" name="wasteObservations" id="wasteObservations"></textarea>
        </div>
      </div>

      <div class="form-row">
        <div class="form-group col-md-12 col-sm-12">
          <label for="observations">Observaciones</label>
          <textarea class="form-control" rows="4" [(ngModel)]="specie.observations" name="observations"
            id="observations"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-lg-4 col-md-6 col-sm-12" >
          <label for="dbh">DBH (cm)</label>
          <input type="number" [(ngModel)]="specie.dbh" class="form-control" id="dbh" name="dbh" placeholder="">
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="leaf_area">Superficie aérea foliar (m<sup>2</sup>)</label>
          <input type="number" [(ngModel)]="specie.leaf_area " class="form-control" id="leaf_area" name="leaf_area"
            placeholder="">
        </div>
        <div class="form-group col-lg-4 col-md-6 col-sm-12">
          <label for="biomass">Biomasa (kg)</label>
          <input type="number" [(ngModel)]="specie.biomass_gr" class="form-control" id="biomass" name="biomass"
            placeholder="">
        </div>
      </div>

      <h4 class="my-4">Fórmulas basadas en el DBH</h4>

      <div class="form-inline my-4">

        <label class="col-form-label col-form-label-sm w-25" for="biomass_formula">Biomasa</label>
        <div class="form-group">

          <div class="col">
            <small class="form-text">Pendiente</small>
            <input type="number" [(ngModel)]="specie.f_biomass_slope" class="form-control form-control-sm"
              id="biomass_formula" name="biomass_formula">
          </div>
          <div class="col">
            <small class="form-text">Constante</small>
            <input type="number" [(ngModel)]="specie.f_biomass_constant" class="form-control form-control-sm"
              id="biomass_constant" name="biomass_constant">
          </div>
          <span class="form-text">Con el valor medio de DBH ({{specie.dbh}} cm) de la especie:
            {{specie.dbh * specie.f_biomass_slope + specie.f_biomass_constant | number: '1.2-2':'es'}} g</span>
        </div>
      </div>
      <div class="form-inline my-4">

        <label class="col-form-label col-form-label-sm w-25" for="f_leaf_area_slope">Sup. foliar</label>
        <div class="form-group">

          <div class="col">
            <small class="form-text">Pendiente</small>
            <input type="number" [(ngModel)]="specie.f_leaf_area_slope" class="form-control form-control-sm"
              id="f_leaf_area_slope" name="f_leaf_area_slope">
          </div>
          <div class="col">
            <small class="form-text">Constante</small>
            <input type="number" [(ngModel)]="specie.f_leaf_area_constant" class="form-control form-control-sm"
              id="f_leaf_area_constant" name="f_leaf_area_constant">
          </div>
          <span class="form-text">Con el valor medio de DBH ({{specie.dbh}} cm)  de la especie:
            {{specie.dbh * specie.f_leaf_area_slope + specie.f_leaf_area_constant | number: '1.2-2':'es'}} m<sup>2</sup></span>
        </div>
      </div>

      <div class="form-inline my-4">

        <label class="col-form-label col-form-label-sm w-25" for="f_top_slope">Anchura de copa</label>
        <div class="form-group">

          <div class="col">
            <small class="form-text">Pendiente</small>
            <input type="number" [(ngModel)]="specie.f_top_slope" class="form-control form-control-sm"
              id="f_top_slope" name="f_top_slope">
          </div>
          <div class="col">
            <small class="form-text">Constante</small>
            <input type="number" [(ngModel)]="specie.f_top_constant" class="form-control form-control-sm"
              id="f_top_constant" name="f_top_constant">
          </div>
          <span class="form-text">Con el valor medio de DBH ({{specie.dbh}} cm) de la especie:
            {{specie.dbh * specie.f_top_slope + specie.f_top_constant | number: '1.2-2':'es'}} m</span>
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" *ngIf="specie.id" (click)="delete()">Eliminar</button>
    <button type="button" class="btn btn-outline-success" (click)="save(editsSaceForm)" [disabled]="!editsSaceForm.valid"><span *ngIf="!specie.id">Crear</span><span
        *ngIf="specie.id">Guardar</span></button>    
    <div *ngIf="!editsSaceForm.valid" class="text-danger" >
      Revisa que estén todos estos campos:
      <div *ngFor="let camp of requiered_list">
         {{camp}},
      </div>
    </div>
  </div>
</div>
