import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpacesListComponent } from './spaces-list/spaces-list.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { UIRouterModule, Transition } from '@uirouter/angular';
import { SpaceDetailComponent } from './space-detail/space-detail.component';
import { SpaceService } from './space.service';
import { SpaceDistributionComponent } from './space-distribution/space-distribution.component';
import { SpaceEditorComponent } from './space-editor/space-editor.component';
import { SpaceSimulationComponent } from './space-simulation/space-simulation.component';
import { InstallationLoaderComponent } from './installation-loader/installation-loader.component';
import { SpaceClimaEditorComponent } from './space-clima-editor/space-clima-editor.component';
import { SpacePollutionEditorComponent } from './space-pollution-editor/space-pollution-editor.component';
import { ScrollSpyDirective } from './scroll-spy.directive';
import { ZoneEditorComponent } from './zone-editor/zone-editor.component';
import { ReportDownloaderComponent } from './report-downloader/report-downloader.component';
import { DistributionPreviewComponent } from './distribution-preview/distribution-preview.component';

export function getSpace(transition: Transition, spaceService: SpaceService) {
  return spaceService.spaces.find((space) => space.id === +transition.params().spaceId);
}

const spacesListState = {
  name: 'home.spaces',
  url: '/spaces',
  views: { content: {component: SpacesListComponent, data: {requiresAuth: true}}}
};
const spaceDetailState = {
  name: 'home.spaces.detail',
  url: '/:spaceId',
  views: {'content@home': {component: SpaceDetailComponent, data: {requiresAuth: true}}},
  resolve: [
    {
      token: 'space',
      deps: [Transition, SpaceService],
      resolveFn: getSpace
    }
  ]
};
@NgModule({
  declarations: [
    SpacesListComponent,
    SpaceDetailComponent,
    SpaceDistributionComponent,
    SpaceEditorComponent,
    SpaceSimulationComponent,
    InstallationLoaderComponent,
    SpaceClimaEditorComponent,
    SpacePollutionEditorComponent,
    ScrollSpyDirective,
    ZoneEditorComponent,
    ReportDownloaderComponent,
    DistributionPreviewComponent
  ],
  entryComponents: [
    SpaceDistributionComponent,
    SpaceEditorComponent,
    InstallationLoaderComponent,
    SpaceClimaEditorComponent,
    SpacePollutionEditorComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    UIRouterModule.forChild({ states: [spacesListState, spaceDetailState]})
  ]
})
export class SpacesModule { }
