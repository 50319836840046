<div class="container-fluid" *ngIf="specie">
  <div class="row">

    <div class="col col-sm-12 my-2" id="actionBar">
      <a uiSref="home.species" class="btn btn-link"><i class="fas fa-caret-left"></i> Volver </a>
    </div>
  </div>
  <div class="row">

    <div class="col col-sm-12 col-md-6" id="specieDetail">
      <h1 class="specie_name">{{specie.name}}</h1>
      <h2 class="specie_science_name"><i>{{specie.science_name | lowercase}}</i></h2>
    </div>
   <!--  <div class="col col-sm-12 col-md-6">
      <img class="img-fluid img-thumbnail" [src]="specie.image">
    </div> -->
  </div>
  <div class="row">
    <div class="col col-sm-12">
      <ul ngbNav [(activeId)]="active" #nav="ngbNav" class="nav nav-tabs justify-content-end">
        <li [ngbNavItem]="'characteristics'">
          <a ngbNavLink>Características</a>
          <ng-template ngbNavContent>
            <div class="edition_area" *ngIf="user.permissions.indexOf('species.edit_basic_data') > -1" >
              <button type="button" class="btn btn-outline-success float-right" (click)="editBase()">Editar</button>
            </div>
            <table class="table">
              <tbody>
                <tr class="no-border">
                  <td><strong>Tipo</strong></td>
                  <td>{{specie.typology}}</td>
                </tr>
                <tr *ngIf="showSize">
                  <td><strong>Porte</strong></td>
                  <td>{{specie.size}}</td>
                </tr>
                <tr>
                  <td><strong>Tipo de hoja</strong></td>
                  <td>{{specie.leaves_kind}}</td>
                </tr>
                <tr>
                  <td><strong>Velocidad de crecimiento</strong></td>
                  <td>{{specie.growth_speed}}</td>
                </tr>
                <tr>
                  <td><strong>Longevidad (años)</strong></td>
                  <td>{{specie.longevity}}</td>
                </tr>
                <tr>
                  <td><strong>Uso potencial</strong></td>
                  <td>{{specie.usage}}</td>
                </tr>
                <tr>
                  <td><strong>Descripción de la hoja</strong></td>
                  <td>{{specie.leaves_description}}</td>
                </tr>
                <tr>
                  <td><strong>Copa</strong></td>
                  <td>{{specie.top_description}}</td>
                </tr>
                <tr>
                  <td><strong>Cualidades ornamentales</strong></td>
                  <td>{{specie.ornamental_properties}}</td>
                </tr>
                <tr>
                  <td><strong>Hábitat natural</strong></td>
                  <td>{{specie.habitat}}</td>
                </tr>
                <tr>
                  <td><strong>Admisión de poda</strong></td>
                  <td>{{specie.prunable ? 'Si. ' : 'No'}}<span *ngIf="specie.prunable">{{ specie.pruning_observations}}</span></td>
                </tr>
                <tr>
                  <td><strong>Afectación potencial al pavimento</strong></td>
                  <td>{{specie.pavement_affectation}}</td>
                </tr>
                <tr>
                  <td><strong>Generación de residuos</strong></td>
                  <td>{{specie.generates_waste ? 'Si. ' : 'No'}}<span *ngIf="specie.generates_waste">{{specie.waste_description}}</span></td>
                </tr>
                <tr>
                  <td><strong>Alergenicidad</strong></td>
                  <td>{{specie.allergenicity}}</td>
                </tr>
                <tr>
                  <td><strong>Observaciones</strong></td>
                  <td>{{specie.observations}}</td>
                </tr>
                <tr>
                  <td><strong>Altura (m)</strong></td>
                  <td>{{specie.height | number : '1.2-2' : 'es'}}</td>
                </tr>
                
                <tr *ngIf="user.permissions.indexOf('species.view_dbh') > -1 && specie.typology.toLowerCase() !== 'arbusto'">
                  <td><strong>DBH medio (cm)</strong></td>
                  <td>{{specie.dbh | number : '1.2-2' : 'es'}}</td>
                </tr>
                <tr *ngIf="user.permissions.indexOf('species.view_lai') > -1">
                  <td><strong>LAI</strong></td>
                  <td>{{specie.lai | number : '1.2-2' : 'es'}}</td>
                </tr>
                <tr>
                  <td><strong>Diámetro de copa medio (m) </strong></td>
                  <td>{{specie.crown_diameter}}</td>
                </tr>                
                <tr *ngIf="user.permissions.indexOf('species.view_la') > -1">
                  <td><strong>Area foliar media (m<sup>2</sup>)</strong></td>
                  <td>{{specie.leaf_area | number : '1.4-4' : 'es'}}</td>
                </tr>
                <tr *ngIf="user.permissions.indexOf('species.view_biomass') > -1">
                  <td><strong>Biomasa media (kg)</strong></td>
                  <td>{{specie.biomass_gr | number : '1.4-4' : 'es'}}</td>
                </tr>
              </tbody>
            </table>
          </ng-template>
        </li>
        <li [ngbNavItem]="'resilience'">
          <a ngbNavLink>Resiliencia</a>
          <ng-template ngbNavContent>
            <app-specie-resilience  [specie]="specie"></app-specie-resilience>
          </ng-template>
        </li>
        <li [ngbNavItem]="'thermalComfort'">
          <a ngbNavLink>Confort térmico</a>
          <ng-template ngbNavContent>
            <app-specie-efficiency [specie]="specie"></app-specie-efficiency>
          </ng-template>
        </li>
        <li [ngbNavItem]="'airQuality'">
          <a ngbNavLink>Calidad del aire</a>
          <ng-template ngbNavContent>
            <app-specie-air-quality [specie]="specie"></app-specie-air-quality>
          </ng-template>
        </li>
        <li [ngbNavItem]="'co2Absorption'">
          <a ngbNavLink>CO<sub>2</sub></a>
          <ng-template ngbNavContent>
            <app-specie-co2-absorption [specie]="specie"></app-specie-co2-absorption>
          </ng-template>
        </li>
        <li [ngbNavItem]="'biodiversity'">
          <a ngbNavLink>Biodiversidad</a>
          <ng-template ngbNavContent>
            <app-specie-biodiversity [specie]="specie"></app-specie-biodiversity>
          </ng-template>
        </li>
        <li [ngbNavItem]="'biophilia'">
          <a ngbNavLink>Biofilia</a>
          <ng-template ngbNavContent>
            <app-specie-biophilia [specie]="specie"></app-specie-biophilia>
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </div>
</div>
