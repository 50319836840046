<div class="container-fluid">
  <div class="col col-sm-12 col-md-12 d-flex flex-column no_padding">
    <div class="spaceslist--create-space d-flex align-items-center">
      <h2>Infraestructuras verdes</h2>
      <button type="button" class="btn btn-outline-success button_spaces" (click)="openCreateSpaceModal()">
        Añadir</button>  
    </div>
    <div class="row flex-container">
      <div class="col-md-3 space-filters">
        <h6>Filtrar por <strong>nombre</strong>:</h6>
        <input type="text" class="form-control" [(ngModel)]="filterName" placeholder="Nombre de la infraestructura">
      </div>
      <div class="col-md-3">
        <h6>Filtrar por <strong>tipo</strong>:</h6>
        <select class="form-control" [(ngModel)]="filter">
          <option value="Todas">Todas</option>
          <option value="Nuevas">Nuevas</option>
          <option value="Existentes">Existentes</option>
        </select>
      </div>
      <div class="col-md-3">
        <h6>Mostrar por <strong>orden</strong>:</h6>
        <select class="form-control" [(ngModel)]="alphabeticOrder" required #typeField="ngModel">
          <option value="Alfabetic">Alfabético</option>
          <option value="Cronologic">Cronológico</option>
        </select>
        <div *ngIf="typeField.errors?.required && typeField.touched" class="text-danger">
          Este campo es obligatorio.
        </div>
      </div>
      <div class="col-md-3">
        <h6>Mostrar por <strong>vista</strong>:</h6>
        <select class="form-control" [(ngModel)]="view">
          <option value="List">Lista</option>
          <option value="Mosaic">Mosaico</option>
        </select>
      </div>
    </div>
  </div>
  <div class="row row-table" *ngIf="view === 'List'">
    <div class="col col-lg-12 col-sm-12 col-md-12 d-flex flex-column justify-content-start">
      <div class="row row-headers">
        <h6 class="col"><u><strong>Nombre</strong></u></h6>
        <h6 class="col"><u><strong>Tipo</strong></u></h6>
        <h6 class="col"><u><strong>Nº ejemplares</strong></u></h6>
      </div>
      <div class="list-of-spaces" *ngFor="let space of getFilteredSpaces(); index as i">
        <div class="card card_atributes card-list " uiSref="home.spaces.detail" [uiParams]="{spaceId: space.id}" [ngClass]="{'card-green': space.kind == 'NEW', 'card-brown': space.kind !== 'NEW'}" >
            <h5 class="card-title"><strong>{{space.name}}</strong>{{space.inventory_count}}</h5>
            <span class="material-symbols-outlined">
              {{space.kind == 'NEW' ? 'park' : 'forest'}}
            </span>
            <h5>{{space.kind == 'NEW' ? 'Nueva' : 'Existente'}}</h5>
            <h5>{{spaceNameSpecimens[space.id]}}</h5>
        </div>
      </div>
   </div>
  </div>
  
  <div class="row" *ngIf="view === 'Mosaic'">
    <div class="col-lg-2 col-md-4 column_padding" *ngFor="let space of getFilteredSpaces(); index as i">
      <div class="card card_atributes" uiSref="home.spaces.detail" [uiParams]="{spaceId: space.id}" [ngClass]="{'card-green': space.kind == 'NEW', 'card-brown': space.kind !== 'NEW'}">
        <div class="card-body">
          <span class="material-symbols-outlined">
            {{space.kind == 'NEW' ? 'park' : 'forest'}}
          </span>
          <h6 class="card-title">
            {{space.name}}
          </h6>
          <h8>Número total de ejemplares: {{spaceNameSpecimens[space.id]}}</h8>
        </div>
      </div>
    </div>
  </div>
</div>
