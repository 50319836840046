export class SpecieBiodiversity {
    constructor(
        public id: number,
        public biodiversity_fructification_months?: string,
        public biodiversity_fructification_season?: string,
        public biodiversity_edible?: string,
        public biodiversity_blooming_months?: string,
        public biodiversity_blooming_season?: string,
        public biodiversity_pollinators_attraction?: string,
        public biodiversity_nutricia_lepidoptera?: string,
        public biodiversity_top_source?: string,
        public biodiversity_average_leaf_area?: string,
        public biodiversity_longevity?: string,
        public biodiversity_cavity_formation?: string,
        public biodiversity_leaves_permanence?: string,
        public biodiversity_thorns_presence?: string,
        public biodiversity_trophic_resource?: number,
        public biodiversity_shelter_resource?: number
    ) { }
}
