export class Zone {
    constructor(
        public id?: number,
        public name?: string,
        public total_surface?: number,
        public useful_surface?: number,
        public plantation_frame?: string,
        public space?: number
    ) {}
}
