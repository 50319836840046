<div class="container-fluid" *ngIf="co2AbsorptionData">
  <div class="edition_area" *ngIf="user.permissions.indexOf('species.edit_basic_data') > -1" >
    <button type="button" class="btn btn-outline-success float-right" (click)="editCo2Absorption()">Editar</button>
  </div>
  <table class="table">
    <tbody>
      <tr class="no-border">
        <td><strong>Absorción de CO<sub>2</sub> (Kg/año)</strong></td>
        <td>{{ co2AbsorptionData.co2_absorption }}</td>
      </tr>
      <tr>
        <td><strong>Almacenamiento CO<sub>2</sub> en 5 años (Kg)</strong></td>
        <td>{{ co2AbsorptionData.co2_5_years_projection }}</td>
      </tr>
      <tr>
        <td><strong>Almacenamiento CO<sub>2</sub> en 10 años (Kg)</strong></td>
        <td>{{ co2AbsorptionData.co2_10_years_projection }}</td>
      </tr>
      <tr>
        <td><strong>Almacenamiento CO<sub>2</sub> en 15 años (Kg)</strong></td>
        <td>{{ co2AbsorptionData.co2_15_years_projection }}</td>
      </tr>
      <tr>
        <td><strong>Almacenamiento CO<sub>2</sub> en 20 años (Kg)</strong></td>
        <td>{{ co2AbsorptionData.co2_20_years_projection }}</td>
      </tr>
      <tr>
        <td><strong>Almacenamiento CO<sub>2</sub> en 25 años (Kg)</strong></td>
        <td>{{ co2AbsorptionData.co2_25_years_projection }}</td>
      </tr>
      <tr>
        <td><strong>Almacenamiento CO<sub>2</sub> en 30 años (Kg)</strong></td>
        <td>{{ co2AbsorptionData.co2_30_years_projection }}</td>
      </tr>
      <tr>
        <td><strong>Almacenamiento CO<sub>2</sub> en 35 años (Kg)</strong></td>
        <td>{{ co2AbsorptionData.co2_35_years_projection }}</td>
      <tr>
        <td><strong>Almacenamiento CO<sub>2</sub> en 40 años (Kg)</strong></td>
        <td>{{ co2AbsorptionData.co2_40_years_projection }}</td>
      </tr>
    </tbody>
  </table>
</div>
