import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { UserListComponent } from './user-list/user-list.component';
import { UIRouterModule, Transition, StateService } from '@uirouter/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { UserPermissionsComponent } from './user-permissions/user-permissions.component';
import { UsersService } from './users.service';
import { User } from '../auth/user';

export function retrieveUser(trans: Transition, usersService: UsersService) {
  return usersService.users.find((user: User) => user.id === +trans.params().userId);
}

const userListState = {name: 'home.userlist', url: '/users', views: { content: {component: UserListComponent, data: {requiresAuth: true}}}};
const userPermissionsState = {
  name: 'home.permissions',
  url: '/permissions/:userId',
  views: {
    content: {
      component: UserPermissionsComponent,
      data: {requiresAuth: true}
    }
  },
  resolve: [
    {
      token: 'user',
      deps: [Transition, UsersService],
      resolveFn: retrieveUser
    }
  ]
};

@NgModule({
  declarations: [UserListComponent, UserPermissionsComponent],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    UIRouterModule.forChild({ states: [  userListState, userPermissionsState ]})
  ]
})
export class UsersModule { }
