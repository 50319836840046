<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar biofilia de {{ specie.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #editBiophiliaForm="ngForm" (ngSubmit)="save()">
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_leaves_colour">Color Hoja</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_leaves_colour" name="biophilia_leaves_colour" id="biophilia_leaves_colour"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_chromatic_variance">Variación Cromática</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_chromatic_variance" name="biophilia_chromatic_variance" id="biophilia_chromatic_variance"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_flower_colour">Color Flores</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_flower_colour" name="biophilia_flower_colour" id="biophilia_flower_colour"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_fruit_colour">Color Fruto</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_fruit_colour" name="biophilia_fruit_colour" id="biophilia_fruit_colour"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_flower_fragrance">Flores</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_flower_fragrance" name="biophilia_flower_fragrance" id="biophilia_flower_fragrance"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_plant_fragrance">Resto de la planta</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_plant_fragrance" name="biophilia_plant_fragrance" id="biophilia_plant_fragrance"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_leaves_permanence">Permanencia Hojas</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_leaves_permanence" name="biophilia_leaves_permanence" id="biophilia_leaves_permanence"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_flower_abundance">Abundancia Flores</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_flower_abundance" name="biophilia_flower_abundance" id="biophilia_flower_abundance"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_flower_size">Tamaño Flores</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_flower_size" name="biophilia_flower_size" id="biophilia_flower_size"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_fruit_size">Tamaño Fruto</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_fruit_size" name="biophilia_fruit_size" id="biophilia_fruit_size"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_top_density">Densidad de Copa</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_top_density" name="biophilia_top_density" id="biophilia_top_density"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_pendulum_shape">Forma Pendular</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_pendulum_shape" name="biophilia_pendulum_shape" id="biophilia_pendulum_shape"></textarea>
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_chromatism_resource">Cromatismo</label>
          <input type="number" class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_chromatism_resource" name="biophilia_chromatism_resource" id="biophilia_chromatism_resource" min="0">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_fragrance_resource">Aroma</label>
          <input type="number" class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_fragrance_resource" name="biophilia_fragrance_resource" id="biophilia_fragrance_resource" min="0">
        </div>
      </div>
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="biophilia_ornateness_resource">Vistosidad</label>
          <input type="number" class="form-control" rows="4" [(ngModel)]="biophiliaData.biophilia_ornateness_resource" name="biophilia_ornateness_resource" id="biophilia_ornateness_resource" min="0">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
  </div>
</div>
