import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecieThermalComfort } from '../specie-thermal-comfort';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-editor-termal',
  templateUrl: './specie-editor-termal.component.html',
  styleUrls: ['./specie-editor-termal.component.sass']
})
export class SpecieEditorTermalComponent implements OnInit {

  @Input() specie: SpecieThermalComfort;
  constructor(
    private activeModal: NgbActiveModal,
    private speciesService: SpecieService
  ) { }

  ngOnInit() { }
  save() {
      this.speciesService.updateEfficiency(this.specie).subscribe(
        specie => this.activeModal.close(specie),
        () => {}
      );
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
