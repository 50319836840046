import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'sortBy' })
export class SortByPipe implements PipeTransform {

  public transform( objectArray: object[], propertyName: string ) {

    if ( !objectArray || !propertyName ) { return objectArray; }

    const compareObjectsProperty = function( a: object, b: object ) {
      return ( 
        ( a[ propertyName ] > b[ propertyName ] )
          ? 1 
          : ( a[ propertyName ] == b[ propertyName ] ) 
            ? 0 
            : -1
      );
    }

    return objectArray.sort( compareObjectsProperty );

  }

}
