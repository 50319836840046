import { Component, OnInit, Input } from '@angular/core';
import { SpecieService } from '../specie.service';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { SpecieEditorBaseComponent } from '../specie-editor-base/specie-editor-base.component';
import { SpecieEditorResilienceComponent } from '../specie-editor-resilience/specie-editor-resilience.component';
import { SpecieEditorTermalComponent } from '../specie-editor-termal/specie-editor-termal.component';
import { SpecieEditorPollutionComponent } from '../specie-editor-pollution/specie-editor-pollution.component';
import { SpecieEditorBiodiversityComponent } from '../specie-editor-biodiversity/specie-editor-biodiversity.component';
import { Specie } from '../specie';
import { SpecieEditorBiophiliaComponent } from '../specie-editor-biophilia/specie-editor-biophilia.component';


@Component({
  selector: 'app-specie-detail',
  templateUrl: './specie-detail.component.html',
  styleUrls: ['./specie-detail.component.scss']
})
export class SpecieDetailComponent implements OnInit {
  @Input() specieId: number;

  specie: Specie;
  showSize: boolean;
  display = 'detail';
  user: User;
  efficiencyData;
  pollutionData;

  constructor(
    private speciesService: SpecieService,
    private authService: AuthService,
    private modalService: NgbModal,
    private $state: StateService) {
      this.user = this.authService.user;
    }

  ngOnInit() {
    this.speciesService.getSpecie(this.specieId).subscribe((specie: Specie) => {
      this.specie = specie;
      this.showSize = this.specie.typology === 'ARBOL';
      this.specie.leaf_area = this.specie.leaf_area * 0.0001
      this.specie.biomass_gr = this.specie.biomass_gr * 0.001
    });

    if (this.user.permissions.indexOf('species.view_efficiency_data') > -1) {
      this.speciesService.getAirQuality(this.specieId).subscribe(
        (data => this.pollutionData = data)
      );
    }
  }

  editBase() {
      const modalRef = this.modalService.open(SpecieEditorBaseComponent, {size: 'xl' as 'lg'});
      modalRef.componentInstance.specie = Object.assign({}, this.specie);
      modalRef.result.then(
            (newSpecie) => {
              if (newSpecie === null) {
                this.$state.go('home.species');
              } else {
                newSpecie.leaf_area = newSpecie.leaf_area*0.0001
                newSpecie.biomass_gr = newSpecie.biomass_gr*0.001
                Object.assign(this.specie, newSpecie);
              }
            },
            (reason: any) => {}
      );
  }
}
