import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppComponent } from './app.component';
import {AuthModule} from './auth/auth.module';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { HomeModule } from './home/home.module';
import { UIRouterModule } from '@uirouter/angular';
import { configUIRouter } from './router.config';
import { UsersModule } from './users/users.module';
import { AuthInterceptor } from './auth/auth.interceptor';
import { MessagesComponent } from './messages/messages.component';
import { SpeciesModule } from './species/species.module';
import { RangesModule } from './ranges/ranges.module';
import { SpacesModule } from './spaces/spaces.module';
import { registerLocaleData } from '@angular/common';
import localeEs from '@angular/common/locales/es';

registerLocaleData(localeEs, 'es');

@NgModule({
  declarations: [
    AppComponent,
    MessagesComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AuthModule,
    HomeModule,
    UsersModule,
    SpeciesModule,
    SpacesModule,
    RangesModule,
    UIRouterModule.forRoot({ states: [ ], useHash: false, otherwise: { state: 'home' }, config: configUIRouter })
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: AuthInterceptor,
    multi: true,
  }],
  bootstrap: [AppComponent]
})
export class AppModule { }
