<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Distribución de especie</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #form="ngForm" (ngSubmit)="close()">
      <div class="form-row form-group">
        <div class="col-md-6 col-sm-12">
          <label for="specie">Especie:</label>
          <input
            name="specie"
            id="specie"
            type="text"
            class="form-control"
            [(ngModel)]="distribution.specie"
            (ngModelChange)="setSpecie()"
            [ngbTypeahead]="search"
            [editable]='false'
            [resultFormatter]="specieFormatter"
            [inputFormatter]="specieFormatter"
            (focus)="focus$.next($event.target.value)"
          />
        </div>
        <div class="col-md-6 col-sm-12">
          <label for="amount">Número de ejemplares</label>
          <input type="number" step="any" [(ngModel)]="amount" class="form-control" id="amount" name="amount" (change)="updateInventoryRows()">
        </div>
      </div>
      <div class="form-row form-group">
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="useDBH" name="useDBH" class="custom-control-input" [(ngModel)]="useDBH" [value]=true>
          <label class="custom-control-label" for="useDBH">Usar dbh medio</label>
        </div>
        <div class="custom-control custom-radio custom-control-inline">
          <input type="radio" id="manual" name="manual" class="custom-control-input" [(ngModel)]="useDBH" [value]=false>
          <label class="custom-control-label" for="manual">Introducir valores manualmente</label>
        </div>
      </div>
      <div *ngIf="!useDBH">
        <div class="form-row form-group">
          <div class="col-md-12">
          <button type="button" (click)="updateCalculations()" class="btn btn-outline-primary">Recalcular valores</button>
        </div>
        </div>
        <div class="form-row form-group"  *ngFor="let inventoryLine of distribution.inventory; index as i">
          <div class="col-md-2 col-sm-12">
            <label for="{{i}}_unit_identification">Identificador</label>
            <input type="text" [(ngModel)]="inventoryLine.unit_identification" class="form-control" id="{{i}}_unit_identification" name="{{i}}_unit_identification">
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="{{i}}_dbh">DBH (cm) </label>
            <input type="number" step="any" required #dbh="ngModel" [(ngModel)]="inventoryLine.dbh" class="form-control" id="{{i}}_dbh" name="{{i}}_dbh" (change)="inventoryLine.calculateFromDBH(distribution.specie)" [class]="{'is-invalid': dbh.invalid, 'is-valid': dbh.valid}">
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="{{i}}_leaf_area">Superficie foliar (cm <sup>2</sup>)</label>
            <input type="number" step="any" min="0" #leafArea="ngModel" [(ngModel)]="inventoryLine.leaf_area" class="form-control" id="{{i}}_leaf_area" name="{{i}}_leaf_area" [class]="{'is-invalid': leafArea.invalid, 'is-valid': leafArea.valid}">
          </div>
          <div class="col-md-2 col-sm-12">
            <label for="{{i}}_biomass_gr">Biomasa (gr)</label>
            <input type="number" step="any" min="0" #biomass="ngModel" [(ngModel)]="inventoryLine.biomass_gr" class="form-control" id="{{i}}_biomass_gr" name="{{i}}_biomass_gr" [class]="{'is-invalid': biomass.invalid, 'is-valid': biomass.valid}">
          </div>
          <div class="col-md-2 pt-4">
            <label (click)="remLine(i)" class="btn btn-outline-danger">Quitar</label>
          </div>
        </div> 
      </div>
        <button type="submit" style="display: none;"></button>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-danger" (click)="remove()" *ngIf="distribution.id">Eliminar</button>
    <button type="button" class="btn btn-outline-success" [class]="{'disabled': form.invalid}" [disabled]="form.invalid" (click)="close()">Guardar</button>
  </div>
</div>