<div class="container-fluid">
  <div class="row">
  <div class="col col-lg-2">

    <nav id="simulation-menu" class="navbar">
      <nav class="nav nav-pills flex-column">
        <a class="nav-link" [ngClass]="{ 'active': currentSection === 'archive' }" (click)="scrollTo('archive')">Archivo</a>
        <a class="nav-link" [ngClass]="{ 'active': currentSection === 'filters' }" (click)="scrollTo('filters')" *ngIf="simulation.id">Filtrar especies</a>
        <a class="nav-link" [ngClass]="{ 'active': currentSection === 'select' }" (click)="scrollTo('select')" *ngIf="simulation.id">Selección y ordenación de especies</a>
        <a class="nav-link" [ngClass]="{ 'active': currentSection === 'result' }" (click)="scrollTo('result')" *ngIf="simulation.id">Resultado de la simulación</a>
        <div class="form-group col col-lg-12" *ngIf="simulation.id"> 
          <label for="unitsSelector">Unidades</label>
          <select class="form-control" id="unitsSelector" required [(ngModel)]="simulation.display_units"
            name="unitsSelector" (change)="changeUnits()">
            <option [value]="'g/día'">g/día</option>
            <option [value]="'Kg/año'">Kg/año</option>
          </select>
          </div>
        <button class="btn btn-outline-success my-4" type="button" (click)="saveSimulation()" *ngIf="simulation.id">Guardar</button>
      </nav>
    </nav>
  </div>
  <div class="col col-lg-10">
    <div appScrollSpy (sectionChange)="onSectionChange($event)" class="data-side">
      <div id="archive" class="container-fluid my-4">
        <div class="row">
          <div class="col col-lg-12">
            <h4>Archivo</h4>
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-12">  
            <nav aria-label="breadcrumb">
              <ol class="breadcrumb">
                <li class="breadcrumb-item active"><span class="text-primary action" (click)="resetZone()">Selección de zona</span></li>
                <li *ngIf="selectedZone" class="breadcrumb-item" >{{selectedZone.name}}</li>
                <li *ngIf="selectedZone" class="breadcrumb-item active"><span class="text-primary action" (click)="resetSimulation()">Selección de simulación</span></li>
                <li class="breadcrumb-item active" *ngIf="selectedZone && simulation.id"> {{simulation.name}} </li>
              </ol>
            </nav>
          </div>
        </div>
        <div class="row" *ngIf="!selectedZone">
          <div class="col col-lg-12">
            
            <form>
              <div class="form-group">
                <label for="zoneSelector">Selecciona una zona</label>
                <select class="form-control" id="zoneSelector" required [(ngModel)]="selectedZone"
                name="zoneSelector" (change)="selectZone()">
                <option *ngFor="let zone of zones" [ngValue]="zone">{{zone.name}}</option>
              </select>
            </div>
          </form>
        </div>
        </div>
        <div class="row" *ngIf="selectedZone && !simulation.id">
          <div class="col col-lg-12">
            <form class="container-fluid">
              <div class="row">
                <div class="col col-lg-12 my-4">
                  Crear una simulación nueva
                  
                </div>
              </div>
              <div class="form-row">

                <div class="form-group col col-lg-8">
                  <label for="name">Nombre</label>
                  <input type="text" class="form-control" id="name" name="name" aria-describedby="name" placeholder="Nombre de la simulación" [(ngModel)]="simulation.name">
                </div>
                <div class="form-group col col-lg-2">
                  <label for="unitsSelector">Unidades</label>
                  <select class="form-control" id="unitsSelector" required [(ngModel)]="simulation.display_units"
                  name="unitsSelector" (change)="changeUnits()">
                  <option [value]="'g/día'">g/día</option>
                  <option [value]="'Kg/año'">Kg/año</option>
                </select>
              </div>
              <div class="col col-lg-2 form-group create-button">
                <button class="btn btn-outline-success" type="button" (click)="saveSimulation()" *ngIf="!simulation.id">Crear</button>
                <button class="btn btn-outline-success" type="button" (click)="saveSimulation()" *ngIf="simulation.id">Actualizar</button>
              </div>
            </div>
            </form>
          </div>
        </div>
        <div class="row" *ngIf="selectedZone && !simulation.id">
          <div class="col col-lg-12 my-4">
            Cargar una simulación previa
            
          </div>
          <div class="col col-lg-12">
          <table class="table table-hover">
            <thead class="thead-dark">
              <tr>
                <th>id</th>
                <th>Nombre</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <tr  *ngFor="let sim of availableSimulations; index as i">
                <td>{{sim.id}}</td>
                <td>{{sim.name}}</td>
                <td><span *ngIf="simulation.id !== sim.id" (click)="loadSimulation(sim)" class="action">Cargar</span> <span *ngIf="simulation.id === sim.id">Cargada</span></td>
                <td><span (click)="deleteSimulation(sim)" class="action delete">Eliminar</span></td>
              </tr>
            </tbody>
          </table>
        </div>
        </div>
        
      </div>


      <div id="filters" class="container-fluid my-4" *ngIf="simulation.id">
        <div class="row">
          <div class="col col-lg-12">
            <h4>Filtrar especies</h4>
          </div>
          <div class="col col-lg-12 my-2">
            En esta sección puede filtrar las especies marcando los criterios que deben cumplir en lo referente a resiliencia, confort térmico/radiativo,  calidad del aire y biodiversidad y biofilia. Los criterios que no interesen puede dejarlos en blanco y no se aplicará ese filtro. Haga click en el checkbox de nulos para que no aparezcan especies que no tienen valor en el parámetro marcado.
            
          </div>
        </div>
        <div class="row">
          <div class="col col-lg-12">
            <ul ngbNav #filtersNav="ngbNav" [(activeId)]="activeFilter" class="nav nav-tabs nav-fill">
              <li [ngbNavItem]="'resilienceFilters'">
                <a ngbNavLink>Resiliencia</a>
                <ng-template ngbNavContent>
                  <div class="container-fluid">
                    <div class="my-3">Marque las características del entorno que deben soportar las especies
                    </div>
                    <div class="row">
                      <div class="form-group col col-md-3">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="freeze_tolerance"
                            (change)="filterSpecies()"
                            [(ngModel)]="simulation.filters.resilienceFilters.freeze_tolerance" name="freeze_tolerance">
                          <label class="custom-control-label" for="freeze_tolerance">Tolerante a las heladas</label>
                        </div>
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="shadow_tolerance"
                            (change)="filterSpecies()"
                            [(ngModel)]="simulation.filters.resilienceFilters.shadow_tolerance" name="shadow_tolerance">
                          <label class="custom-control-label" for="shadow_tolerance">Tolerante a la sombra</label>
                        </div>
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="compact_terrain_tolerance"
                            [(ngModel)]="simulation.filters.resilienceFilters.compact_terrain_tolerance"
                            (change)="filterSpecies()" name="compact_terrain_tolerance">
                          <label class="custom-control-label" for="compact_terrain_tolerance">Tolerante a suelos
                            pobres</label>
                        </div>
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="saline_terrain_tolerance"
                            [(ngModel)]="simulation.filters.resilienceFilters.saline_terrain_tolerance"
                            (change)="filterSpecies()" name="saline_terrain_tolerance">
                          <label class="custom-control-label" for="saline_terrain_tolerance">Tolerante a suelos
                            salinos</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col col-md-4 col-lg-4">
                          <div class="label-null">
                            <label for="heat_range_10">Índice de tolerancia térmica (10ºC) rango del 1 al 5:
                              {{simulation.filters.resilienceFilters.heat_tolerance_10}}</label>
                              <div *ngIf="check_if_parameter('heat_tolerance_10')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="heat_tolerance_10_null" name="heat_tolerance_10_null" (change)="changeValueOfAdmittedNull('heat_tolerance_10')" checked>
                                  <label class="custom-control-label" for="heat_tolerance_10_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        <input type="range" name="heat_range_10" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.resilienceFilters.heat_tolerance_10" id="heat_range_10"
                          min="1" max="5" step="1">
                      </div>
                      <div class="form-group col col-md-4 col-lg-4">
                          <div class="label-null">
                            <label for="heat_tolerance_20">Índice de tolerancia térmica (20ºC) rango del 1 al 5:
                              {{simulation.filters.resilienceFilters.heat_tolerance_20}}</label>
                              <div *ngIf="check_if_parameter('heat_tolerance_20')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="heat_tolerance_20_null" name="heat_tolerance_20_null" (change)="changeValueOfAdmittedNull('heat_tolerance_20')" checked>
                                  <label class="custom-control-label" for="heat_tolerance_20_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        <input type="range" name="heat_tolerance_20" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.resilienceFilters.heat_tolerance_20" id="heat_tolerance_20"
                          min="1" max="5" step="1">
                      </div>

                      <div class="form-group col col-md-4 col-lg-4">
                        <div class="label-null">
                            <label for="heat_tolerance_30">Índice de tolerancia térmica (30ºC) rango del 1 al 5:
                              {{simulation.filters.resilienceFilters.heat_tolerance_30}}</label>
                              <div *ngIf="check_if_parameter('heat_tolerance_30')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="heat_tolerance_30_null" name="heat_tolerance_30_null" (change)="changeValueOfAdmittedNull('heat_tolerance_30')" checked>
                                  <label class="custom-control-label" for="heat_tolerance_30_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        <input type="range" name="heat_tolerance_30" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.resilienceFilters.heat_tolerance_30" id="heat_tolerance_30"
                          min="1" max="5" step="1">
                      </div>
                    </div>
                    <div class="form-row">
                      <div class="form-group col-md-2">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="drought_resistance_se" [(ngModel)]="simulation.filters.resilienceFilters.drought_resistance_se"
                            name="drought_resistance_se" (change)="filterSpecies()">
                          <label class="custom-control-label" for="drought_resistance_se">Tolerante a la sequedad elevada</label>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="drought_resistance_sm" [(ngModel)]="simulation.filters.resilienceFilters.drought_resistance_sm"
                            name="drought_resistance_sm">
                          <label class="custom-control-label" for="drought_resistance_sm">Tolerante a la sequedad media</label>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="drought_resistance_sd" [(ngModel)]="simulation.filters.resilienceFilters.drought_resistance_sd"
                            name="drought_resistance_sd" (change)="filterSpecies()">
                          <label class="custom-control-label" for="drought_resistance_sd">Tolerante a la sequedad débil</label>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="drought_resistance_hd" [(ngModel)]="simulation.filters.resilienceFilters.drought_resistance_hd"
                            name="drought_resistance_hd" (change)="filterSpecies()">
                          <label class="custom-control-label" for="drought_resistance_hd">Tolerante a la humedad débil</label>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="drought_resistance_hm" [(ngModel)]="simulation.filters.resilienceFilters.drought_resistance_hm"
                            name="drought_resistance_hm" (change)="filterSpecies()">
                          <label class="custom-control-label" for="drought_resistance_hm">Tolerante a la humedad media</label>
                        </div>
                      </div>
                      <div class="form-group col-md-2">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="drought_resistance_he" [(ngModel)]="simulation.filters.resilienceFilters.drought_resistance_he"
                            name="drought_resistance_he"  (change)="filterSpecies()">
                          <label class="custom-control-label" for="drought_resistance_he">Tolerante a la humedad elevada</label>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="form-group col col-md-4 col-lg-4">
                        <div class="label-null">
                          <label for="wue_10">WUE (10ºC) rango del 1 al 5:
                            {{simulation.filters.resilienceFilters.wue_10}}</label>
                            <div *ngIf="check_if_parameter('wue_10')" class="nulls_checkboxes">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="wue_10_null" name="wue_10_null" (change)="changeValueOfAdmittedNull('wue_10')" checked>
                                <label class="custom-control-label" for="wue_10_null"></label>
                              </div>
                            </div>                                                       
                        </div>
                        <input type="range" name="wue_10" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.resilienceFilters.wue_10"
                          id="wue_10" min="1" max="5" step="1">       
                      </div>
                      
                      <div class="form-group col col-md-4 col-lg-4">
                        <div class="label-null">
                          <label for="wue_20">WUE (20ºC) rango del 1 al 5:
                            {{simulation.filters.resilienceFilters.wue_20}}</label>
                            <div *ngIf="check_if_parameter('wue_20')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="wue_20_null" name="wue_20_null" (change)="changeValueOfAdmittedNull('wue_20')" checked>
                                <label class="custom-control-label" for="wue_20_null"></label>
                              </div>
                            </div>                                                       
                        </div>
                        <input type="range" name="wue_20" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.resilienceFilters.wue_20"
                          id="wue_20" min="1" max="5" step="1">
                      </div>

                      <div class="form-group col col-md-4 col-lg-4">
                        <div class="label-null">
                          <label for="wue_30">WUE (30ºC) rango del 1 al 5:
                            {{simulation.filters.resilienceFilters.wue_30}}</label>
                            <div *ngIf="check_if_parameter('wue_30')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="wue_30_null" name="wue_30_null" (change)="changeValueOfAdmittedNull('wue_30')" checked>
                                <label class="custom-control-label" for="wue_30_null"></label>
                              </div>
                            </div>                                                       
                        </div>
                        <input type="range" name="wue_30" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.resilienceFilters.wue_30"
                          id="wue_30" min="1" max="5" step="1">
                      </div>
                    </div>

                    <div class="row">

                    </div>
                  </div>
                </ng-template>
              </li>

              <li [ngbNavItem]="'thermalComfortFilters'">
                <a ngbNavLink>Confort térmico y radiativo</a>
                <ng-template ngbNavContent>
                  <div class="container-fluid">
                    <div class="my-3">Marque los valores mínimos de confort térmico/radiativo que deben cumplir las especies</div>
                    <div class="row">
                      <div class="form-group col col-md-6">
                        <div class="label-null">
                          <label for="air_temp_reduction_pct">Reducción de la Tª del aire bajo dosel:
                            {{simulation.filters.comfortFilters.air_temp_reduction_pct}}</label>
                            <div *ngIf="check_if_parameter('air_temp_reduction_pct')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="air_temp_reduction_pct_null" name="air_temp_reduction_pct_null" (change)="changeValueOfAdmittedNull('air_temp_reduction_pct')" checked>
                                <label class="custom-control-label" for="air_temp_reduction_pct_null"></label>
                              </div>
                            </div>                                                       
                        </div>
                        
                        <input type="range" name="air_temp_reduction_pct" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.air_temp_reduction_pct" id="air_temp_reduction_pct" min="1"
                          max="5" step="1">
                      </div>
                      
                      <div class="form-group col-md-6">
                        <div class="label-null">
                          <label for="humidity_increase_pct">Incremento de la humedad relativa:
                            {{simulation.filters.comfortFilters.humidity_increase_pct}}</label>
                            <div *ngIf="check_if_parameter('humidity_increase_pct')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="humidity_increase_pct_null" name="humidity_increase_pct_null" (change)="changeValueOfAdmittedNull('humidity_increase_pct')" checked>
                                <label class="custom-control-label" for="humidity_increase_pct_null"></label>
                              </div>
                            </div>                                                       
                        </div>
                        
                        <input type="range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.humidity_increase_pct" class="form-control-range"
                          id="humidity_increase_pct" name="humidity_increase_pct" min="1" max="5" step="1">
                      </div>

                      </div>
                      <div class="row">
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="pavement_temp_reduction">Reducción de la Tª de la acera:
                            {{simulation.filters.comfortFilters.pavement_temp_reduction_pct}}</label>
                            <div *ngIf="check_if_parameter('pavement_temp_reduction_pct')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="pavement_temp_reduction_pct_null" name="pavement_temp_reduction_pct_null" (change)="changeValueOfAdmittedNull('pavement_temp_reduction_pct')" checked>
                                <label class="custom-control-label" for="pavement_temp_reduction_pct_null"></label>
                              </div>
                            </div>                                                       
                        </div>
                        <input type="range" name="pavement_temp_reduction_pct" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.pavement_temp_reduction_pct"
                          id="pavement_temp_reduction" min="1" max="5" step="1">
                      </div>

                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="asphalt_temp_reduction_pct">Reducción de la Tª de la del asfalto:
                            {{simulation.filters.comfortFilters.asphalt_temp_reduction_pct}}</label>
                            <div *ngIf="check_if_parameter('asphalt_temp_reduction_pct')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="asphalt_temp_reduction_pct_null" name="asphalt_temp_reduction_pct_null" (change)="changeValueOfAdmittedNull('asphalt_temp_reduction_pct')" checked>
                                <label class="custom-control-label" for="asphalt_temp_reduction_pct_null"></label>
                              </div>
                            </div>                                                       
                        </div>
                        
                        <input type="range" name="asphalt_temp_reduction_pct" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.asphalt_temp_reduction_pct"
                          id="asphalt_temp_reduction" min="1" max="5" step="1">
                      </div>

                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="grass_temp_reduction">Reducción de la Tª del césped:
                            {{simulation.filters.comfortFilters.grass_temp_reduction_pct}}</label>
                            <div *ngIf="check_if_parameter('grass_temp_reduction_pct')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="grass_temp_reduction_pct_null" name="grass_temp_reduction_pct_null" (change)="changeValueOfAdmittedNull('grass_temp_reduction_pct')" checked>
                                <label class="custom-control-label" for="grass_temp_reduction_pct_null"></label>
                              </div>
                            </div>                                                       
                        </div>
                        <input type="range" name="grass_temp_reduction_pct" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.grass_temp_reduction_pct" id="grass_temp_reduction"
                          min="1" max="5" step="1">
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="pipeclay_temp_reduction">Reducción de la Tª del albero:
                            {{simulation.filters.comfortFilters.pipeclay_temp_reduction_pct}}</label>
                            <div *ngIf="check_if_parameter('pipeclay_temp_reduction_pct')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="pipeclay_temp_reduction_pct_null" name="pipeclay_temp_reduction_pct_null" (change)="changeValueOfAdmittedNull('pipeclay_temp_reduction_pct')" checked>
                                <label class="custom-control-label" for="pipeclay_temp_reduction_pct_null"></label>
                              </div>
                            </div>                                                       
                        </div>

                        <input type="range" name="pipeclay_temp_reduction_pct" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.pipeclay_temp_reduction_pct"
                          id="pipeclay_temp_reduction" min="1" max="5" step="1">
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                        <label for="pavement_temp_radiation_reduction">Reducción de la Tª radiante en acera:
                          {{simulation.filters.comfortFilters.pavement_temp_radiation_reduction_pct}}</label>
                            <div *ngIf="check_if_parameter('pavement_temp_radiation_reduction_pct')" class="nulls_checkboxes ">
                              <span class="mr-2">¿Nulos?</span>
                              <div class="custom-control custom-checkbox">
                                <input type="checkbox" class="custom-control-input" id="pavement_temp_radiation_reduction_pct_null" name="pavement_temp_radiation_reduction_pct_null" (change)="changeValueOfAdmittedNull('pavement_temp_radiation_reduction_pct')" checked>
                                <label class="custom-control-label" for="pavement_temp_radiation_reduction_pct_null"></label>
                              </div>
                            </div>                                                       
                        </div>

                        <input type="range" name="pavement_temp_radiation_reduction" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.pavement_temp_radiation_reduction_pct"
                          id="pavement_temp_radiation_reduction" min="1" max="5" step="1">
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="asphalt_temp_radiation_reduction">Reducción de la Tª radiante en asfalto:
                            {{simulation.filters.comfortFilters.asphalt_temp_radiation_reduction_pct}}</label>
                              <div *ngIf="check_if_parameter('asphalt_temp_radiation_reduction_pct')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="asphalt_temp_radiation_reduction_pct_null" name="asphalt_temp_radiation_reduction_pct_null" (change)="changeValueOfAdmittedNull('asphalt_temp_radiation_reduction_pct')" checked>
                                  <label class="custom-control-label" for="asphalt_temp_radiation_reduction_pct_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        
                        <input type="range" name="asphalt_temp_radiation_reduction" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.asphalt_temp_radiation_reduction_pct"
                          id="asphalt_temp_radiation_reduction" min="1" max="5" step="1">
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="grass_temp_radiation_reduction">Reducción de la Tª radiante en césped:
                            {{simulation.filters.comfortFilters.grass_temp_radiation_reduction_pct}}</label>
                              <div *ngIf="check_if_parameter('grass_temp_radiation_reduction_pct')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="grass_temp_radiation_reduction_pct_null" name="grass_temp_radiation_reduction_pct_null" (change)="changeValueOfAdmittedNull('grass_temp_radiation_reduction_pct')" checked>
                                  <label class="custom-control-label" for="grass_temp_radiation_reduction_pct_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        <input type="range" name="grass_temp_radiation_reduction" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.grass_temp_radiation_reduction_pct"
                          id="grass_temp_radiation_reduction" min="1" max="5" step="1">
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="pipeclay_temp_radiation_reduction">Reducción de la Tª radiante en albero:
                            {{simulation.filters.comfortFilters.pipeclay_temp_radiation_reduction_pct}}</label>
                              <div *ngIf="check_if_parameter('pipeclay_temp_radiation_reduction_pct')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="pipeclay_temp_radiation_reduction_pct_null" name="pipeclay_temp_radiation_reduction_pct_null" (change)="changeValueOfAdmittedNull('pipeclay_temp_radiation_reduction_pct')" checked>
                                  <label class="custom-control-label" for="pipeclay_temp_radiation_reduction_pct_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="pipeclay_temp_radiation_reduction" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.pipeclay_temp_radiation_reduction_pct"
                          id="pipeclay_temp_radiation_reduction" min="1" max="5" step="1">
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="wgtb_pavement">Reducción del índice de estrés térmico en acera:
                            {{simulation.filters.comfortFilters.wgtb_pavement}}</label>
                              <div *ngIf="check_if_parameter('wgtb_pavement')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="wgtb_pavement_null" name="wgtb_pavement_null" (change)="changeValueOfAdmittedNull('wgtb_pavement')" checked>
                                  <label class="custom-control-label" for="wgtb_pavement_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="wgtb_pavement" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.wgtb_pavement"
                          id="wgtb_pavement" min="1" max="5" step="1">
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="wgtb_asphalt">Reducción del índice de estrés térmico en asfalto:
                            {{simulation.filters.comfortFilters.wgtb_asphalt}}</label>
                              <div *ngIf="check_if_parameter('wgtb_asphalt')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="wgtb_asphalt_null" name="wgtb_asphalt_null" (change)="changeValueOfAdmittedNull('wgtb_asphalt')" checked>
                                  <label class="custom-control-label" for="wgtb_asphalt_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        
                        <input type="range" name="wgtb_asphalt" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.wgtb_asphalt"
                          id="wgtb_asphalt" min="1" max="5" step="1">
                      </div>

                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="wgtb_grass">Reducción del índice de estrés térmico en césped:
                            {{simulation.filters.comfortFilters.wgtb_grass}}</label>
                              <div *ngIf="check_if_parameter('wgtb_grass')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="wgtb_grass_null" name="wgtb_grass_null" (change)="changeValueOfAdmittedNull('wgtb_grass')" checked>
                                  <label class="custom-control-label" for="wgtb_grass_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        
                        <input type="range" name="wgtb_grass" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.wgtb_grass"
                          id="wgtb_grass" min="1" max="5" step="1">
                      </div>
                      <div class="form-group col col-md-3">
                        <div class="label-null">
                          <label for="wgtb_pipeclay">Reducción del índice de estrés térmico en albero:
                            {{simulation.filters.comfortFilters.wgtb_pipeclay}}</label>
                              <div *ngIf="check_if_parameter('wgtb_pipeclay')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="wgtb_pipeclay_null" name="wgtb_pipeclay_null" (change)="changeValueOfAdmittedNull('wgtb_pipeclay')" checked>
                                  <label class="custom-control-label" for="wgtb_pipeclay_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="wgtb_pipeclay" class="form-control-range"
                          (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.wgtb_pipeclay"
                          id="wgtb_pipeclay" min="1" max="5" step="1">
                      </div>
                    </div>

                    <div class="row">
                      <div class="form-group col-md-3">
                        <div class="label-null">
                          <label for="uva_reduction_pct">Reducción de rayos UVA (400-315 nm):
                            {{simulation.filters.comfortFilters.uva_reduction_pct}}</label>
                              <div *ngIf="check_if_parameter('uva_reduction_pct')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="uva_reduction_pct_null" name="uva_reduction_pct_null" (change)="changeValueOfAdmittedNull('uva_reduction_pct')" checked>
                                  <label class="custom-control-label" for="uva_reduction_pct_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        
                        <input type="range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.uva_reduction_pct" class="form-control-range"
                          id="uva_reduction_pct" name="uva_reduction_pct" min="1" max="5" step="1">
                      </div>
                      <div class="form-group col-md-3">
                        <div class="label-null">
                          <label for="uvbc_reduction_pct">Reducción de rayos UV(B+C) (315-100 nm):
                            {{simulation.filters.comfortFilters.uvbc_reduction_pct}}</label>
                              <div *ngIf="check_if_parameter('uvbc_reduction_pct')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="uvbc_reduction_pct_null" name="uvbc_reduction_pct_null" (change)="changeValueOfAdmittedNull('uvbc_reduction_pct')" checked>
                                  <label class="custom-control-label" for="uvbc_reduction_pct_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.comfortFilters.uvbc_reduction_pct" class="form-control-range"
                          id="uvbc_reduction_pct" name="uvbc_reduction_pct" min="1" max="5" step="1">
                      </div>
                      
                    </div>
                  
                  
                  </div>
                </ng-template>
              </li>

              <li [ngbNavItem]="'ariQualityFilters'">
                <a ngbNavLink>Calidad del aire</a>
                <ng-template ngbNavContent>
                  <div class="container-fluid">
                    <div class="my-3">Marque los valores de absorción mínima que deban cumplir las especies
                    </div>
                    <div class="row">
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co_absorption">Absorción de CO rango del 1 al 5:
                            {{simulation.filters.qualityFilters.co_absorption}}</label>
                              <div *ngIf="check_if_parameter('co_absorption')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co_absorption_null" name="co_absorption_null" (change)="changeValueOfAdmittedNull('co_absorption')" checked>
                                  <label class="custom-control-label" for="co_absorption_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        <input type="range" name="co_absorption" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.qualityFilters.co_absorption" id="co_absorption"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="no2_absorption">Absorción de de NO<sub>2</sub> rango del 1 al 5:
                            {{simulation.filters.qualityFilters.no2_absorption}}</label>
                              <div *ngIf="check_if_parameter('no2_absorption')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="no2_absorption_null" name="no2_absorption_null" (change)="changeValueOfAdmittedNull('no2_absorption')" checked>
                                  <label class="custom-control-label" for="no2_absorption_null"></label>
                                </div>
                              </div>                                                       
                          </div>
                        
                        <input type="range" name="no2_absorption" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.qualityFilters.no2_absorption" id="no2_absorption"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="no_absorption">Absorción de de NO rango del 1 al 5:
                            {{simulation.filters.qualityFilters.no_absorption}}</label>
                              <div *ngIf="check_if_parameter('no_absorption')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="no_absorption_null" name="no_absorption_null" (change)="changeValueOfAdmittedNull('no_absorption')" checked>
                                  <label class="custom-control-label" for="no_absorption_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="no_absorption" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.qualityFilters.no_absorption" id="no_absorption"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="so2_absorption">Absorción de de SO<sub>2</sub> rango del 1 al 5:
                            {{simulation.filters.qualityFilters.so2_absorption}}</label>
                              <div *ngIf="check_if_parameter('so2_absorption')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="so2_absorption_null" name="so2_absorption_null" (change)="changeValueOfAdmittedNull('so2_absorption')" checked>
                                  <label class="custom-control-label" for="so2_absorption_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="so2_absorption" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.qualityFilters.so2_absorption" id="so2_absorption"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="pm1_absorption">Absorción de de PM<sub>1</sub> rango del 1 al 5:
                            {{simulation.filters.qualityFilters.pm1_absorption}}</label>
                              <div *ngIf="check_if_parameter('pm1_absorption')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="pm1_absorption_null" name="pm1_absorption_null" (change)="changeValueOfAdmittedNull('pm1_absorption')" checked>
                                  <label class="custom-control-label" for="pm1_absorption_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="pm1_absorption" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.qualityFilters.pm1_absorption" id="pm1_absorption"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="pm2_5_absorption">Absorción de de PM<sub>2,5</sub> rango del 1 al 5:
                            {{simulation.filters.qualityFilters.pm2_5_absorption}}</label>
                              <div *ngIf="check_if_parameter('pm2_5_absorption')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="pm2_5_absorption_null" name="pm2_5_absorption_null" (change)="changeValueOfAdmittedNull('pm2_5_absorption')" checked>
                                  <label class="custom-control-label" for="pm2_5_absorption_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="pm2_5_absorption" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.qualityFilters.pm2_5_absorption" id="pm2_5_absorption"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="pm10_absorption">Absorción de de PM<sub>10</sub> rango del 1 al 5:
                            {{simulation.filters.qualityFilters.pm10_absorption}}</label>
                              <div *ngIf="check_if_parameter('pm10_absorption')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="pm10_absorption_null" name="pm10_absorption_null" (change)="changeValueOfAdmittedNull('pm10_absorption')" checked>
                                  <label class="custom-control-label" for="pm10_absorption_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="pm10_absorption" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.qualityFilters.pm10_absorption" id="pm10_absorption"
                          min="1" max="5" step="1">
                      </div>
                    </div>
                  </div>
                </ng-template>
              </li>

              <li [ngbNavItem]="'co2_absorption_filters'">
                <a ngbNavLink>CO<sub>2</sub></a>
                <ng-template ngbNavContent>
                  <div class="container-fluid">
                    <div class="my-3">Elija el rango de absorción de los campos de CO2
                    </div>
                    <div class="row">
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_absorption">Absorción de CO<sub>2</sub> rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_absorption}}</label>
                              <div *ngIf="check_if_parameter('co2_absorption')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_absorption_null" name="co2_absorption_null" (change)="changeValueOfAdmittedNull('co2_absorption')" checked>
                                  <label class="custom-control-label" for="pm10_absorption_null"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="co2_absorption" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_absorption" id="co2_absorption"
                          min="1" max="5" step="1">
                      </div>
                    </div>
                    <div class="row filter_row">
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_5_years_projection">Almacenamiento CO<sub>2</sub> en 5 años rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_5_years_projection}}</label>
                              <div *ngIf="check_if_parameter('co2_5_years_projection')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_5_years_projection_null" name="co2_5_years_projection_null" (change)="changeValueOfAdmittedNull('co2_5_years_projection')" checked>
                                  <label class="custom-control-label" for="co2_5_years_projection"></label>
                                </div>
                              </div>                                                       
                          </div>
                        <input type="range" name="co2_5_years_projection" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_5_years_projection" id="co2_5_years_projection"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_10_years_projection">Almacenamiento CO<sub>2</sub> en 10 años rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_10_years_projection}}</label>
                              <div *ngIf="check_if_parameter('co2_10_years_projection')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_10_years_projection_null" name="co2_10_years_projection_null" (change)="changeValueOfAdmittedNull('co2_10_years_projection')" checked>
                                  <label class="custom-control-label" for="co2_10_years_projection"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="co2_10_years_projection" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_10_years_projection" id="co2_10_years_projection"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_15_years_projection">Almacenamiento CO<sub>2</sub> en 15 años rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_15_years_projection}}</label>
                              <div *ngIf="check_if_parameter('co2_15_years_projection')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_15_years_projection_null" name="co2_15_years_projection_null" (change)="changeValueOfAdmittedNull('co2_10_years_projection')" checked>
                                  <label class="custom-control-label" for="co2_15_years_projection"></label>
                                </div>
                              </div>                                                       
                          </div>
                        
                        <input type="range" name="co2_15_years_projection" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_15_years_projection" id="co2_15_years_projection"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_20_years_projection">Almacenamiento CO<sub>2</sub> en 20 años rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_20_years_projection}}</label>
                              <div *ngIf="check_if_parameter('co2_20_years_projection')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_20_years_projection_null" name="co2_20_years_projection_null" (change)="changeValueOfAdmittedNull('co2_20_years_projection')" checked>
                                  <label class="custom-control-label" for="co2_20_years_projection"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="co2_20_years_projection" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_20_years_projection" id="co2_20_years_projection"
                          min="1" max="5" step="1">
                      </div>
                    </div>
                    <div class="row filter_row">
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_25_years_projection">Almacenamiento CO<sub>2</sub> en 25 años rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_25_years_projection}}</label>
                              <div *ngIf="check_if_parameter('co2_25_years_projection')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_25_years_projection_null" name="co2_25_years_projection_null" (change)="changeValueOfAdmittedNull('co2_25_years_projection')" checked>
                                  <label class="custom-control-label" for="co2_25_years_projection"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="co2_25_years_projection" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_25_years_projection" id="co2_25_years_projection"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_30_years_projection">Almacenamiento CO<sub>2</sub> en 30 años rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_30_years_projection}}</label>
                              <div *ngIf="check_if_parameter('co2_30_years_projection')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_30_years_projection_null" name="co2_30_years_projection_null" (change)="changeValueOfAdmittedNull('co2_30_years_projection')" checked>
                                  <label class="custom-control-label" for="co2_30_years_projection"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="co2_30_years_projection" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_30_years_projection" id="co2_30_years_projection"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_35_years_projection">Almacenamiento CO<sub>2</sub> en 35 años rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_35_years_projection}}</label>
                              <div *ngIf="check_if_parameter('co2_35_years_projection')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_35_years_projection_null" name="co2_35_years_projection_null" (change)="changeValueOfAdmittedNull('co2_35_years_projection')" checked>
                                  <label class="custom-control-label" for="co2_35_years_projection"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="co2_35_years_projection" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_35_years_projection" id="co2_35_years_projection"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="co2_40_years_projection">Almacenamiento CO<sub>2</sub> en 40 años rango del 1 al 5:
                            {{simulation.filters.Co2Filters.co2_40_years_projection}}</label>
                              <div *ngIf="check_if_parameter('co2_40_years_projection')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="co2_40_years_projection_null" name="co2_40_years_projection_null" (change)="changeValueOfAdmittedNull('co2_40_years_projection')" checked>
                                  <label class="custom-control-label" for="co2_40_years_projection"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="co2_40_years_projection" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.Co2Filters.co2_40_years_projection" id="co2_40_years_projection"
                          min="1" max="5" step="1">
                      </div>
                    </div>  
                  </div>
                </ng-template>
              </li>

              <li [ngbNavItem]="'bioFilters'">
                <a ngbNavLink>Biodiversidad y Biofilia</a>
                <ng-template ngbNavContent>
                  <div class="container-fluid">
                    <div class="my-3">Filtre las especies por su valor como recurso de fomento de la biodiversidad y biofilia
                    </div>
                    <div class="row">
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="biodiversity_shelter_resource">Recurso de refugio rango del 1 al 5:
                            {{simulation.filters.bioFilters.biodiversity_shelter_resource}}</label>
                              <div *ngIf="check_if_parameter('biodiversity_shelter_resource')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="biodiversity_shelter_resource_null" name="biodiversity_shelter_resource_null" (change)="changeValueOfAdmittedNull('biodiversity_shelter_resource')" checked>
                                  <label class="custom-control-label" for="biodiversity_shelter_resource"></label>
                                </div>
                              </div>                                                       
                          </div>
                        
                        <input type="range" name="biodiversity_shelter_resource" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.bioFilters.biodiversity_shelter_resource" id="biodiversity_shelter_resource"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="biodiversity_trophic_resource">Recurso trófico rango del 1 al 5:
                            {{simulation.filters.bioFilters.biodiversity_trophic_resource}}</label>
                              <div *ngIf="check_if_parameter('biodiversity_trophic_resource')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="biodiversity_trophic_resource_null" name="biodiversity_trophic_resource_null" (change)="changeValueOfAdmittedNull('biodiversity_trophic_resource')" checked>
                                  <label class="custom-control-label" for="biodiversity_trophic_resource"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="biodiversity_trophic_resource" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.bioFilters.biodiversity_trophic_resource" id="biodiversity_trophic_resource"
                          min="1" max="5" step="1">
                      </div>
                      
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="biophilia_chromatism_resource">Cromatismo rango del 1 al 5:
                            {{simulation.filters.bioFilters.biophilia_chromatism_resource}}</label>
                              <div *ngIf="check_if_parameter('biophilia_chromatism_resource')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="biophilia_chromatism_resource_null" name="biophilia_chromatism_resource_null" (change)="changeValueOfAdmittedNull('biophilia_chromatism_resource')" checked>
                                  <label class="custom-control-label" for="biodiversity_trophic_resource"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="biophilia_chromatism_resource" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.bioFilters.biophilia_chromatism_resource" id="biophilia_chromatism_resource"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="biophilia_fragrance_resource">Aroma rango del 1 al 5:
                            {{simulation.filters.bioFilters.biophilia_fragrance_resource}}</label>
                              <div *ngIf="check_if_parameter('biophilia_fragrance_resource')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="biophilia_fragrance_resource_null" name="biophilia_fragrance_resource_null" (change)="changeValueOfAdmittedNull('biophilia_fragrance_resource')" checked>
                                  <label class="custom-control-label" for="biophilia_fragrance_resource"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="biophilia_fragrance_resource" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.bioFilters.biophilia_fragrance_resource" id="biophilia_fragrance_resource"
                          min="1" max="5" step="1">
                      </div>
                      <div class="col col-md-3">
                        <div class="label-null">
                          <label for="biophilia_ornateness_resource">Vistosidad cromático rango del 1 al 5:
                            {{simulation.filters.bioFilters.biophilia_ornateness_resource}}</label>
                              <div *ngIf="check_if_parameter('biophilia_ornateness_resource')" class="nulls_checkboxes ">
                                <span class="mr-2">¿Nulos?</span>
                                <div class="custom-control custom-checkbox">
                                  <input type="checkbox" class="custom-control-input" id="biophilia_ornateness_resource_null" name="biophilia_ornateness_resource_null" (change)="changeValueOfAdmittedNull('biophilia_ornateness_resource')" checked>
                                  <label class="custom-control-label" for="biophilia_ornateness_resource"></label>
                                </div>
                              </div>                                                       
                          </div>

                        <input type="range" name="biophilia_ornateness_resource" class="form-control-range" (change)="filterSpecies()"
                          [(ngModel)]="simulation.filters.bioFilters.biophilia_ornateness_resource" id="biophilia_ornateness_resource"
                          min="1" max="5" step="1">
                      </div>

                    </div>
                  </div>
                </ng-template>
              </li>

            </ul>
            <div [ngbNavOutlet]="filtersNav"></div>
          </div>
        </div>
      </div>
      
      <div class="container-fluid my-4" id="select" *ngIf="simulation.id">
        <div class="row">
          <div class="col col-lg-12">
            <h4>Selección y ordenación de especies</h4>
          </div>
          <div class="col col-lg12 my-2">
            En esta sección puede trabajar desde el punto de vista de la calidad del aire, del confort térmico/radiativo o beneficios para la biodiversidad y la biofilia, para obtener una simulación de su Infraestructura Verde. Se ordenarán las especies ya filtradas en la sección anterior en función de los criterios de calidad del aire y confort térmico/radiativo escogidos. Deberá seleccionar las especies que le interesen para generar la simulación          </div>
        </div>
        <div class="row">
          <div class="col col-lg-12">
            <ul ngbNav #sortingNav="ngbNav" [(activeId)]="activeSort" class="nav nav-tabs nav-fill">
              <li [ngbNavItem]="'airqualitySort'">
                <a ngbNavLink>Calidad del aire</a>
                <ng-template ngbNavContent>
                  <div class="col-lg-12">
                      <div class="form-row">
                        <div *ngFor="let target of qualityTargets" class="form-group col-lg-4">
                          <div class="custom-control custom-checkbox">
                            <input type="checkbox" class="custom-control-input" id="check_{{target.target}}"
                              (change)="orderSpecies()" [(ngModel)]="target.value" name="check_{{target.target}">
                              <label class="custom-control-label" for="check_{{target.target}}">{{target.label}}<sub>{{target.subindex}}</sub></label>
                          </div>
                        </div>
                    </div>
                    <div class="table-results scrollable">
                      <table class="sticky-table table table-sm table-hover table-borderless">
                        <thead class="thead-dark">
                          <tr>
                            <th rowspan="2" style="width:160px">Especie ({{orderedByAbsorption.length}})</th>
                            <th rowspan="2">Biomasa (Kg)</th>
                            <th rowspan="2">Sup. foliar (m<sup>2</sup>)</th>
                            <th colspan="7" class="centered spliter"> Absorción ({{simulation.display_units}})</th>
                            <th rowspan="2">Emisión COVs (ppm/15 min)</th>
                            <th rowspan="2"></th>
                          </tr>
                          <tr class="second-level">
                            <th>CO</th>
                            <th>NO<sub>2</sub></th>
                            <th>NO</th>
                            <th>SO<sub>2</sub></th>
                            <th>PM<sub>1</sub></th>
                            <th>PM<sub>2,5</sub></th>
                            <th class="spliter">PM<sub>10</sub></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr *ngFor="let specie of orderedByAbsorption">
                            <td><span class="specie_science_name"><i>{{specie.specie.science_name | lowercase | titlecase}}</i></span><br /><span
                                class="text-muted">{{specie.specie.name | lowercase | titlecase }}</span></td>
                            <td>{{specie.specie.biomass_gr /1000 | number: '1.2':'es'}}</td>
                            <td>{{specie.specie.leaf_area /10000 | number: '1.2':'es'}}</td> 
                            <td>{{specie.specie.leaf_area * specie.airQuality.co_absorption * getSpecieMultiplier(specie.specie) / 1e6 | number: '1.4':'es'}}</td>
                            <td>{{specie.specie.leaf_area * specie.airQuality.no2_absorption * getSpecieMultiplier(specie.specie) / 1e6 | number: '1.4':'es'}}</td>
                            <td>{{specie.specie.leaf_area * specie.airQuality.no_absorption * getSpecieMultiplier(specie.specie) / 1e6 | number: '1.4':'es'}}</td>
                            <td>{{specie.specie.leaf_area * specie.airQuality.so2_absorption * getSpecieMultiplier(specie.specie) / 1e6 | number: '1.4':'es'}}</td>
                            <td>{{specie.specie.leaf_area * specie.airQuality.pm1_absorption * getSpecieMultiplier(specie.specie) / 1e6 | number: '1.4':'es'}}</td>
                            <td>{{specie.specie.leaf_area * specie.airQuality.pm2_5_absorption * getSpecieMultiplier(specie.specie) / 1e6 | number: '1.4':'es'}}</td>
                            <td>{{specie.specie.leaf_area * specie.airQuality.pm10_absorption * getSpecieMultiplier(specie.specie) / 1e6 | number: '1.4':'es'}}</td>
                            <td>{{specie.specie.biomass_gr * specie.airQuality.cov_emission  | number: '1.4':'es'}}</td>
                            <td><span (click)="addSpecie(specie.specie)" class="action">Añadir</span></td>
                          </tr>
        
                        </tbody>
                      </table>
                    </div>
                  </div>
                </ng-template>
              </li>

              <li [ngbNavItem]="'thermalComfortSort'">
                <a ngbNavLink>Confort térmico y radiativo</a>
                <ng-template ngbNavContent>
                  <div class="col-lg-12">
                    <div class="form-row">
                      <div *ngFor="let target of confortTargets" class="form-group col-lg-4">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="check_{{target.target}}"
                            (change)="orderSpecies()" [(ngModel)]="target.value" name="check_{{target.target}">
                            <label class="custom-control-label" for="check_{{target.target}}">{{target.label}}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-results scrollable">
                    <table class="sticky-table  table table-sm table-hover table-borderless">
                      <thead class="thead-dark">
                        <tr>
                          <th rowspan="2" style="width:160px">Especie ({{orderedByConfort.length}})</th>
                          <th rowspan="2" >Anchura de copa (m)</th>
                          <th rowspan="2" class="centered spliter">Superficie de sombreamiento (m<sup>2</sup>)</th>
                          <th colspan="5" class="centered spliter">Reducción Tª (%)</th>
                          <th colspan="4" class="centered spliter">Reducción Tª radiante (%)</th>
                          <th rowspan="2" class="centered spliter">Incremento de la humedad relativa (%)</th>
                          <th colspan="4" class="centered spliter">Reducción del índice de estrés térmico (%)</th>
                          <th rowspan="2">Reduc. rayos UVA (400-315 nm) (%)</th>
                          <th rowspan="2" class="spliter">Reduc. rayos UV(B+C) (315-100 nm) (%)</th>
                          <th colspan="4" class="centered">Reducción radiación de salida (400-1200nm) (%)</th>
                          <th rowspan="2"></th>
        
                        </tr>
                        <tr class="second-level">
                          <th>aire bajo dosel</th>
                          <th>de la acera</th>
                          <th>del asfalto</th>
                          <th>del cesped</th>
                          <th class="spliter"> del albero</th>
                          <th>acera</th>
                          <th>asfalto</th>
                          <th>cesped</th>
                          <th class="spliter">albero</th>
                          <th>acera</th>
                          <th>asfalto</th>
                          <th>cesped</th>
                          <th class="spliter">albero</th>
                          <th>acera</th>
                          <th>asfalto</th>
                          <th>cesped</th>
                          <th>albero</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let specie of orderedByConfort">
                          <td><span class="specie_science_name"><i>{{specie.specie.science_name | lowercase | titlecase}}</i></span><br /><span
                            class="text-muted ">{{specie.specie.name | lowercase | titlecase }}</span></td>
                          
                          <td>{{ specie.specie.calculate_top_width() | number: '1.2':'es'}}</td>
                          <td>{{ specie.specie.calculate_shadow_area() | number: '1.2':'es'}}</td>
  
                          <td>{{ specie.thermalComfort.air_temp_reduction_pct | number: '1.2':'es'}}</td>
  
                          <td>{{ specie.thermalComfort.pavement_temp_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.asphalt_temp_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.grass_temp_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.pipeclay_temp_reduction_pct | number: '1.2':'es'}}</td>
                          
                          <td>{{ specie.thermalComfort.pavement_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.asphalt_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.grass_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.pipeclay_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>

                          <td>{{ specie.thermalComfort.humidity_increase_pct | number: '1.2':'es'}}</td>
                      
                          <td>{{ specie.thermalComfort.wgtb_pavement | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.wgtb_asphalt | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.wgtb_grass | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.wgtb_pipeclay | number: '1.2':'es'}}</td>
  
                          <td>{{ specie.thermalComfort.uva_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.uvbc_reduction_pct | number: '1.2':'es'}}</td>
  
                          <td>{{ specie.thermalComfort.pavement_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.asphalt_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.grass_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                          <td>{{ specie.thermalComfort.pipeclay_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                          <td><span (click)="addSpecie(specie.specie)" class="action">Añadir</span></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="'Absorción co2'">
                <a ngbNavLink>CO<sub>2</sub></a>
                <ng-template ngbNavContent>
                <div class="form-row">
                    <div *ngFor="let target of co2Targets" class="form-group col-md-3">
                      <div class="flex-container">
                        <div class="custom-control custom-checkbox">
                          <input type="checkbox" class="custom-control-input" id="check_{{target.target}}"
                            (change)="orderSpecies()" [(ngModel)]="target.value" name="check_{{target.target}">
                            <label class="custom-control-label" for="check_{{target.target}}">{{target.label}}<sub>{{target.subindex}}</sub></label>
                        </div>
                      </div>
                    </div>
                </div>

                <div class="table-results scrollable">
                  <table class="sticky-table  table table-sm table-hover table-borderless">
                    <thead class="thead-dark">
                      <tr>
                        <th style="width:160px">Especie ({{orderedByConfort.length}})</th>
                        <th>Absorción de CO<sub>2</sub>(Kg/año)</th>
                        <th>Almacenamiento CO<sub>2</sub> en 5 años</th>
                        <th>Almacenamiento CO<sub>2</sub> en 10 años</th>
                        <th>Almacenamiento CO<sub>2</sub> en 15 años</th>
                        <th>Almacenamiento CO<sub>2</sub> en 20 años</th>
                        <th>Almacenamiento CO<sub>2</sub> en 25 años</th>
                        <th>Almacenamiento CO<sub>2</sub> en 30 años</th>
                        <th>Almacenamiento CO<sub>2</sub> en 35 años</th>
                        <th>Almacenamiento CO<sub>2</sub> en 40 años</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let specie of orderedByCo2">
                          <td><span class="specie_science_name"><i>{{specie.specie.science_name | lowercase | titlecase }}</i></span><br /><span
                            class="text-muted ">{{specie.specie.name  | lowercase | titlecase }}</span></td>
                          <td>{{specie.Co2.co2_absorption  | number: '1.4':'es'}}</td>
                          <td>{{specie.Co2.co2_5_years_projection  | number: '1.4':'es'}}</td>
                          <td>{{specie.Co2.co2_10_years_projection  | number: '1.4':'es'}}</td>
                          <td>{{specie.Co2.co2_15_years_projection  | number: '1.4':'es'}}</td>
                          <td>{{specie.Co2.co2_20_years_projection  | number: '1.4':'es'}}</td>
                          <td>{{specie.Co2.co2_25_years_projection  | number: '1.4':'es'}}</td>
                          <td>{{specie.Co2.co2_30_years_projection  | number: '1.4':'es'}}</td>
                          <td>{{specie.Co2.co2_35_years_projection  | number: '1.4':'es'}}</td>
                          <td>{{specie.Co2.co2_40_years_projection  | number: '1.4':'es'}}</td>
                          <td><span (click)="addSpecie(specie.specie)" class="action">Añadir</span></td>
                        </tr>
                      </tbody>
                  </table>
                </div>
                </ng-template>
              </li>
              <li [ngbNavItem]="'biodiversity'">
                <a ngbNavLink>Biodiversidad y Biofilia</a>
                <ng-template ngbNavContent>

                <div class="table-results scrollable">
                  <table class="sticky-table  table table-sm table-hover table-borderless">
                    <thead class="thead-dark">
                      <tr>
                        <th style="width:160px">Especie ({{orderedByConfort.length}})</th>
                        <th>Recurso de refugio</th>
                        <th>Recurso trófico</th>
                        <th class="spliter">Cromatismo</th>
                        <th>Aroma</th>
                        <th>Vistosidad</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let specie of orderedByConfort">
                          <td><span class="specie_science_name"><i>{{specie.specie.science_name | lowercase | titlecase }}</i></span><br /><span
                            class="text-muted ">{{specie.specie.name  | lowercase | titlecase }}</span></td>
                          <td>{{specie.biodiversity.biodiversity_shelter_resource | number: '1.1':'es'}}</td>
                          <td>{{specie.biodiversity.biodiversity_trophic_resource | number: '1.1':'es'}}</td>
                          <td>{{specie.biophilia.biophilia_chromatism_resource | number: '1.1':'es'}}</td>
                          <td>{{specie.biophilia.biophilia_fragrance_resource | number: '1.1':'es'}}</td>
                          <td>{{specie.biophilia.biophilia_ornateness_resource | number: '1.1':'es'}}</td>
                          <td><span (click)="addSpecie(specie.specie)" class="action">Añadir</span></td>
                        </tr>
                      </tbody>
                  </table>
                </div>
                </ng-template>
              </li>
                            
            </ul>
            <div [ngbNavOutlet]="sortingNav"></div>
          </div>
        </div>
      </div>

      <div class="container-fluid my-4" id="result" *ngIf="simulation.id && distributionLoaded">
        <div class="row">
          <div class="col col-lg-12">
            <h4>Resultado de la simulación</h4>
          </div>
          <div class="col col-lg-12">
            En esta sección aparece el resultado de la simulación. Puede añadir y quitar ejemplares de la simulación y comparar los resultados obtenidos
            <button class="btn btn-outline-success mx-2 float-right" type="button" (click)="getReport()" *ngIf="simulation.id">Descargar informe</button>
          </div>
        </div>
        <div class="row pt-4 mb-2 align-items-center">
          <div class="col-auto mr-auto">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="scpecie_view" name="visualization" class="custom-control-input" [(ngModel)]="visualization" [value]="0">
              <label class="custom-control-label" for="scpecie_view">Ver por especie</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="inventory_view" name="visualization" class="custom-control-input" [(ngModel)]="visualization" [value]="1">
              <label class="custom-control-label" for="inventory_view">Ver por ejemplar</label>
            </div>
          </div>
          <div class="col-auto">
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="air_view" name="dataView" class="custom-control-input" [(ngModel)]="dataView" [value]="0">
              <label class="custom-control-label" for="air_view">Ver calidad del aire</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="temp_view" name="dataView" class="custom-control-input" [(ngModel)]="dataView" [value]="1">
              <label class="custom-control-label" for="temp_view">Ver confort térmico y radiativo</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="bio_view" name="dataView" class="custom-control-input" [(ngModel)]="dataView" [value]="2">
              <label class="custom-control-label" for="bio_view">Ver biodiversidad y  biofilia</label>
            </div>
            <div class="custom-control custom-radio custom-control-inline">
              <input type="radio" id="co2_view" name="dataView" class="custom-control-input" [(ngModel)]="dataView" [value]="3">
              <label class="custom-control-label" for="co2_view">Ver datos de CO<sub>2</sub></label>
            </div>            
          </div>
        
        </div>
        <div class="row">
          <div class="col-auto">
            <table class="table table-hover">

              <thead class="thead-dark">
                <tr>
                  <th>Ejemplar</th>
                  <th> <span *ngIf="visualization==0">Ejemplares</span><span *ngIf="visualization==1">DBH (cm)</span></th>
                  <th>Superficie foliar (m<sup>2</sup>)</th>
                  <th>Biomasa (Kg)</th>
                  <th>Sup. sombreamiento (m<sup>2</sup>)</th>
                  <th></th>
                  <th *ngIf="dataView == 0">Absorción CO  ({{simulation.display_units}})</th>
                  <th *ngIf="dataView == 0">Absorción NO<sub>2</sub> ({{simulation.display_units}})</th>
                  <th *ngIf="dataView == 0">Absorción NO  ({{simulation.display_units}})</th>
                  <th *ngIf="dataView == 0">Absorción SO<sub>2</sub>  ({{simulation.display_units}})</th>
                  <th *ngIf="dataView == 0">Absorción PM<sub>1</sub>  ({{simulation.display_units}})</th>
                  <th *ngIf="dataView == 0">Absorción PM<sub>2,5</sub>  ({{simulation.display_units}})</th>
                  <th *ngIf="dataView == 0">Absorción PM<sub>10</sub>  ({{simulation.display_units}})</th>
                  <th *ngIf="dataView == 0">Emisión de COVs (ppm/15 minutos)</th>
                  <th *ngIf="dataView == 0" class="spliter">Absorción de CO<sub>2</sub>(Kg/año)</th>

                  <th *ngIf="dataView == 1">Reduc. Tª bajo dosel (%)</th>
                  <th *ngIf="dataView == 1">Reduc. Tª de la acera (%)</th>
                  <th *ngIf="dataView == 1">Reduc. Tª del asfalto (%)</th>
                  <th *ngIf="dataView == 1">Reduc. Tª del césped (%)</th>
                  <th *ngIf="dataView == 1">Reduc. Tª del albero (%)</th>
                  <th *ngIf="dataView == 1">Reduc. Tª radiante en acera(%)</th>
                  <th *ngIf="dataView == 1">Reduc. Tª radiante en asfalto(%)</th>
                  <th *ngIf="dataView == 1">Reduc. Tª radiante en (%)</th>
                  <th *ngIf="dataView == 1">Reduc. Tª radiante en albero (%)</th>
                  <th *ngIf="dataView == 1">Incremento de la humedad relativa (%)</th>
                  <th *ngIf="dataView == 1">Reduc. del índice de estrés térmico en acera (%)</th>
                  <th *ngIf="dataView == 1">Reduc. del índice de estrés térmico en asfalto (%)</th>
                  <th *ngIf="dataView == 1">Reduc. del índice de estrés térmico en cesped (%)</th>
                  <th *ngIf="dataView == 1">Reduc. del índice de estrés térmico en albero (%)</th>
                  <th *ngIf="dataView == 1">Reduc. rayos UVA (400-315 nm) (%)</th>
                  <th *ngIf="dataView == 1">Reduc. rayos UV(B+C) (315-100 nm) (%)</th>
                  <th *ngIf="dataView == 1">Reduc. rad. salida (400-1200 nm) acera (%)</th>
                  <th *ngIf="dataView == 1">Reduc. rad. salida (400-1200 nm) asfalto (%)</th>
                  <th *ngIf="dataView == 1">Reduc. rad. salida (400-1200 nm) cesped (%)</th>
                  <th *ngIf="dataView == 1">Reduc. rad. salida (400-1200 nm) albero (%)</th>

                  <th *ngIf="dataView == 2">Recurso de refugio</th>
                  <th *ngIf="dataView == 2">Recurso trófico</th>
                  <th *ngIf="dataView == 2" class="spliter">Cromatismo</th>
                  <th *ngIf="dataView == 2">Aroma</th>
                  <th *ngIf="dataView == 2">Vistosidad</th>

                  <th *ngIf="dataView == 3">Absorción de CO<sub>2</sub>(Kg/año)</th>
                  <th *ngIf="dataView == 3">Almacenamiento de CO<sub>2</sub> a 5 años</th>
                  <th *ngIf="dataView == 3">Almacenamiento de CO<sub>2</sub> a 10 años</th>
                  <th *ngIf="dataView == 3">Almacenamiento de CO<sub>2</sub> a 20 años</th>
                  <th *ngIf="dataView == 3">Almacenamiento de CO<sub>2</sub> a 30 años</th>
                  <th *ngIf="dataView == 3">Almacenamiento de CO<sub>2</sub> a 40 años</th>

              </tr>
              </thead>
              <tbody>
                <tr  *ngFor="let specieUnit of simulationInventory; index as j" [ngClass]="{'table-secondary': specieUnit.specie && visualization==1, 'd-none': !specieUnit.specie && visualization==0}">
                  <td ><span *ngIf="specieUnit.specie"><span class="specie_science_name"><i>{{specieUnit.specie.science_name | lowercase | titlecase}}</i></span><br /><span
                    class="text-muted">{{specieUnit.specie.name  | lowercase | titlecase}}</span></span>
                  </td>
                  <td>
                    <span *ngIf="specieUnit.specie">{{specieUnit.amount}} <span *ngIf="visualization==1" >ejemplares</span>
                    <span class="variation" [ngClass]="{'positive': simulationVariation(specieUnit, 'amount') > 0, 'negative': simulationVariation(specieUnit, 'amount') < 0}">{{ simulationVariation(specieUnit, 'amount') | number:'0.2':'es'}}</span>
                    </span>
                    {{specieUnit.dbh | number:'0.2':'es'}}</td>
                  <td>{{specieUnit.leaf_area / 1e4 | number:'0.2':'es'}}<span *ngIf="specieUnit.specie" class="variation" [ngClass]="{'positive': simulationVariation(specieUnit, 'leaf_area') > 0, 'negative': simulationVariation(specieUnit, 'leaf_area') < 0}">{{ simulationVariation(specieUnit, 'leaf_area') / 1e4| number:'0.2':'es'}}</span></td>
                  <td>{{specieUnit.biomass_gr / 1000 | number:'0.2':'es'}}<span *ngIf="specieUnit.specie" class="variation" [ngClass]="{'positive': simulationVariation(specieUnit, 'biomass_gr') > 0, 'negative': simulationVariation(specieUnit, 'biomass_gr') < 0}">{{ simulationVariation(specieUnit, 'biomass_gr')/ 1000 | number:'0.2':'es'}}</span></td>
                  <td>{{specieUnit.shadow_area | number:'0.2':'es'}}</td>
                  <td><span *ngIf="specieUnit.specie" (click)="addSpecie(specieUnit.specie)" class="action">Añadir</span> <span *ngIf="specieUnit.specie"
                    (click)="remSpecie(specieUnit.specie)" class="action">Quitar</span>
                    <span *ngIf="!specieUnit.specie" (click)="remSpecieUnit(specieUnit)" class="action">Quitar</span></td>

                    <td *ngIf="dataView == 0 && specieUnit.specie">{{specieUnit.co_absorption * getSpecieMultiplier(specieUnit.specie) / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0 && specieUnit.specie">{{specieUnit.no2_absorption * getSpecieMultiplier(specieUnit.specie) / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0 && specieUnit.specie">{{specieUnit.no_absorption * getSpecieMultiplier(specieUnit.specie) / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0 && specieUnit.specie">{{specieUnit.so2_absorption * getSpecieMultiplier(specieUnit.specie) / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0 && specieUnit.specie">{{specieUnit.pm1_absorption * getSpecieMultiplier(specieUnit.specie) / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0 && specieUnit.specie">{{specieUnit.pm2_5_absorption * getSpecieMultiplier(specieUnit.specie) / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0 && specieUnit.specie">{{specieUnit.pm10_absorption * getSpecieMultiplier(specieUnit.specie) / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0 && specieUnit.specie">{{specieUnit.cov_emission | number: '1.4':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.air_temp_reduction_pct | number: '1.2':'es'}}</td>
                    
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pavement_temp_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.asphalt_temp_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.grass_temp_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pipeclay_temp_reduction_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pavement_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.asphalt_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.grass_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pipeclay_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.humidity_increase_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.wgtb_pavement | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.wgtb_asphalt | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.wgtb_grass | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.wgtb_pipeclay | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.uva_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.uvbc_reduction_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pavement_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.asphalt_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.grass_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pipeclay_out_radiation_reduction_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 2">{{specieUnit.biodiversity.biodiversity_shelter_resource | number: '1.1':'es'}}</td>
                    <td *ngIf="dataView == 2">{{specieUnit.biodiversity.biodiversity_trophic_resource | number: '1.1':'es'}}</td>
                    <td *ngIf="dataView == 2">{{specieUnit.biophilia.biophilia_chromatism_resource | number: '1.1':'es'}}</td>
                    <td *ngIf="dataView == 2">{{specieUnit.biophilia.biophilia_fragrance_resource | number: '1.1':'es'}}</td>
                    <td *ngIf="dataView == 2">{{specieUnit.biophilia.biophilia_ornateness_resource | number: '1.1':'es'}}</td>
                    
                    <td *ngIf="dataView == 3 && specieUnit.specie">{{specieUnit.co2_absorption | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3 && specieUnit.specie">{{specieUnit.co2_5_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3 && specieUnit.specie">{{specieUnit.co2_10_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3 && specieUnit.specie">{{specieUnit.co2_20_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3 && specieUnit.specie">{{specieUnit.co2_30_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3 && specieUnit.specie">{{specieUnit.co2_40_years_projection | number: '1.4':'es'}}</td>

                </tr>
                  
                <tr class="hover-trigger">
                  <td><strong>Total infraestructura verde</strong><span class="variation">Variación total</span></td>
                  <td>{{simulationSum('amount') | number:'0.2':'es'}} <span class="variation"
                    [ngClass]="{'positive': variationSum('amount') > 0, 'negative': variationSum('amount') < 0}">{{ variationSum('amount') | number:'0.2':'es'}}</span></td>
                  <td>{{simulationSum('leaf_area') / 1e4 | number:'0.2':'es'}}<span class="variation"
                    [ngClass]="{'positive': variationSum('leaf_area') > 0, 'negative': variationSum('leaf_area') < 0}">{{ variationSum('leaf_area') / 1e4 | number:'0.2':'es' }}</span></td>
                  <td>{{simulationSum('biomass_gr') / 1000 | number:'0.2':'es'}}<span class="variation"
                    [ngClass]="{'positive': variationSum('biomass_gr') > 0, 'negative': variationSum('biomass_gr') < 0}">{{ variationSum('biomass_gr')/1000 | number:'0.2':'es' }}</span></td>
                  <td></td>
                  <td></td>
                  <td *ngIf="dataView == 0">{{simulatedTotal.co_absorption / 1e6 | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (simulatedTotal.co_absorption - currentTotal.co_absorption) > 0, 'negative': (simulatedTotal.co_absorption - currentTotal.co_absorption) < 0}">{{(simulatedTotal.co_absorption- currentTotal.co_absorption)/ 1e6 | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 0">{{simulatedTotal.no2_absorption / 1e6 | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (simulatedTotal.no2_absorption - currentTotal.no2_absorption) > 0, 'negative': (simulatedTotal.no2_absorption - currentTotal.no2_absorption) < 0}">{{(simulatedTotal.no2_absorption- currentTotal.no2_absorption)/ 1e6 | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 0">{{simulatedTotal.no_absorption / 1e6 | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (simulatedTotal.no_absorption - currentTotal.no_absorption) > 0, 'negative': (simulatedTotal.no_absorption - currentTotal.no_absorption) < 0}">{{(simulatedTotal.no_absorption- currentTotal.no_absorption)/ 1e6 | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 0">{{simulatedTotal.so2_absorption / 1e6 | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (simulatedTotal.so2_absorption - currentTotal.so2_absorption) > 0, 'negative': (simulatedTotal.so2_absorption - currentTotal.so2_absorption) < 0}">{{(simulatedTotal.so2_absorption- currentTotal.so2_absorption)/ 1e6 | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 0">{{simulatedTotal.pm1_absorption / 1e6 | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (simulatedTotal.pm1_absorption - currentTotal.pm1_absorption) > 0, 'negative': (simulatedTotal.pm1_absorption - currentTotal.pm1_absorption) < 0}">{{(simulatedTotal.pm1_absorption- currentTotal.pm1_absorption)/ 1e6 | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 0">{{simulatedTotal.pm2_5_absorption / 1e6 | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (simulatedTotal.pm2_5_absorption - currentTotal.pm2_5_absorption) > 0, 'negative': (simulatedTotal.pm2_5_absorption - currentTotal.pm2_5_absorption) < 0}">{{(simulatedTotal.pm2_5_absorption- currentTotal.pm2_5_absorption)/ 1e6 | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 0">{{simulatedTotal.pm10_absorption / 1e6 | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (simulatedTotal.pm10_absorption - currentTotal.pm10_absorption) > 0, 'negative': (simulatedTotal.pm10_absorption - currentTotal.pm10_absorption) < 0}">{{(simulatedTotal.pm10_absorption- currentTotal.pm10_absorption)/ 1e6 | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 0">{{simulatedTotal.cov_emission | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (simulatedTotal.cov_emission - currentTotal.cov_emission) > 0, 'negative': (simulatedTotal.cov_emission - currentTotal.cov_emission) < 0}">{{(simulatedTotal.cov_emission- currentTotal.cov_emission) | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 1" colspan="20"></td>
                  <td *ngIf="dataView == 2" colspan="5"></td>
                  
                  <td *ngIf="dataView == 3">{{co2SimulatedTotal.co2_absorption | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (co2SimulatedTotal.co2_absorption - co2CurrentTotal.co2_absorption) > 0, 'negative': (co2SimulatedTotal.co2_absorption - co2CurrentTotal.co2_absorption) < 0}">{{(co2SimulatedTotal.co2_absorption- co2CurrentTotal.co2_absorption) | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 3">{{co2SimulatedTotal.co2_5_years_projection | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (co2SimulatedTotal.co2_5_years_projection - co2CurrentTotal.co2_5_years_projection) > 0, 'negative': (co2SimulatedTotal.co2_5_years_projection - co2CurrentTotal.co2_5_years_projection) < 0}">{{(co2SimulatedTotal.co2_5_years_projection- co2CurrentTotal.co2_5_years_projection) | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 3">{{co2SimulatedTotal.co2_10_years_projection | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (co2SimulatedTotal.co2_10_years_projection - co2CurrentTotal.co2_10_years_projection) > 0, 'negative': (co2SimulatedTotal.co2_10_years_projection - co2CurrentTotal.co2_10_years_projection) < 0}">{{(co2SimulatedTotal.co2_10_years_projection- co2CurrentTotal.co2_10_years_projection) | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 3">{{co2SimulatedTotal.co2_20_years_projection | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (co2SimulatedTotal.co2_20_years_projection - co2CurrentTotal.co2_20_years_projection) > 0, 'negative': (co2SimulatedTotal.co2_20_years_projection - co2CurrentTotal.co2_20_years_projection) < 0}">{{(co2SimulatedTotal.co2_20_years_projection- co2CurrentTotal.co2_20_years_projection) | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 3">{{co2SimulatedTotal.co2_30_years_projection | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (co2SimulatedTotal.co2_30_years_projection - co2CurrentTotal.co2_30_years_projection) > 0, 'negative': (co2SimulatedTotal.co2_30_years_projection - co2CurrentTotal.co2_30_years_projection) < 0}">{{(co2SimulatedTotal.co2_30_years_projection- co2CurrentTotal.co2_30_years_projection) | number: '1.4':'es'}}</span></td>
                  <td *ngIf="dataView == 3">{{co2SimulatedTotal.co2_40_years_projection | number: '1.4':'es'}}<span class="variation" [ngClass]="{'positive': (co2SimulatedTotal.co2_40_years_projection - co2CurrentTotal.co2_40_years_projection) > 0, 'negative': (co2SimulatedTotal.co2_40_years_projection - co2CurrentTotal.co2_40_years_projection) < 0}">{{(co2SimulatedTotal.co2_40_years_projection- co2CurrentTotal.co2_40_years_projection) | number: '1.4':'es'}}</span></td>
                  
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>