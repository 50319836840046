import { Injectable } from '@angular/core';

import { tap, catchError, map } from 'rxjs/operators';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { MessageService } from '../message.service';
import { Message } from '../message';
import { Observable, of } from 'rxjs';
import { SpecieResilience } from './specie-resilience';
import { Specie } from './specie';
import { SpecieThermalComfort } from './specie-thermal-comfort';
import { SpecieAirQuality } from './specie-air-quality';
import { SpecieBiodiversity } from './specie-biodiversity';
import { SpecieAggregator } from './specie-aggregator';
import { SpecieBiophilia } from './specie-biophilia';
import { SpecieCo2 } from './specie-co2-absorption';

@Injectable({
  providedIn: 'root'
})
export class SpecieService {
  species: Specie[] = [];

  constructor(
    private http: HttpClient,
    private messagesService: MessageService
  ) { }

  createSpecie(specie: Specie) {
    const url = `${environment.serverUrl}/species/`;
    return this.http.post(url, specie).pipe(
      map((json: any) => Object.assign(new Specie(), json)),
      tap((newSpec: Specie) => {
        this.species.push(newSpec);
        this.messagesService.add(new Message('ok', 'Creada correctamente'));
        return newSpec;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  deleteSpecie(specie: Specie) {
    const url = `${environment.serverUrl}/species/${specie.id}/`;
    return this.http.delete(url).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Eliminada correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  listSpecies(): Observable<Specie[]> {
    const url = `${environment.serverUrl}/species/`;
    return this.http.get(url).pipe(
      map((json: any) => {
        const result: Specie[] = [];
        for (const itm of json) {
          result.push(Object.assign(new Specie(), itm));
        }
        return result;
      }),
      tap((species: Specie[]) => {
        this.species.splice(0);
        [].push.apply(this.species, species);
        return species;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  listCompleteSpecies(): Observable<SpecieAggregator[]> {
    const url = `${environment.serverUrl}/species/complete/`;
    return this.http.get(url).pipe(
      map((json: any) => {
        const result: SpecieAggregator[] = [];
        for (const itm of json) {
          result.push(new SpecieAggregator(itm));
        }
        return result;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  getResilience(specieId: number): Observable<SpecieResilience> {
    const url = `${environment.serverUrl}/species/${specieId}/resilience/`;
    return this.http.get<SpecieResilience>(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  getEfficiency(specieId: number): Observable<SpecieThermalComfort> {
    const url = `${environment.serverUrl}/species/${specieId}/efficiency/`;
    return this.http.get<SpecieThermalComfort>(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  getAirQuality(specieId: number): Observable<SpecieAirQuality> {
    const url = `${environment.serverUrl}/species/${specieId}/pollution/`;
    return this.http.get<SpecieAirQuality>(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  getBiodiversity(specieId: number): Observable<SpecieBiodiversity> {
    const url = `${environment.serverUrl}/species/${specieId}/biodiversity/`;
    return this.http.get<SpecieBiodiversity>(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  getBiophilia(specieId: number): Observable<SpecieBiophilia> {
    const url = `${environment.serverUrl}/species/${specieId}/biophilia/`;
    return this.http.get<SpecieBiophilia>(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  getCo2Absorption(specieId: number): Observable<SpecieCo2> {
    const url = `${environment.serverUrl}/species/${specieId}/co2/`;
    return this.http.get<SpecieCo2>(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  getImage(specieId: number) {
    const url = `${environment.serverUrl}/species/${specieId}/image/`;
    return this.http.get(url).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  updateSpecie(specie: Specie): Observable<Specie> {
    const url = `${environment.serverUrl}/species/${specie.id}/`;
    return this.http.patch(url, specie).pipe(
      map((json: any) => Object.assign(new Specie(), json)),
      tap((updatedSpecie: Specie) => {
        this.messagesService.add(new Message('ok', 'Actualizado correctamente'));
        for (let i = 0; i < this.species.length; i++) {
          if (this.species[i].id === updatedSpecie.id) {
            this.species[i] = updatedSpecie;
            return updatedSpecie;
          }
        }
        return updatedSpecie;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  updateResilience(specie: SpecieResilience): Observable<SpecieResilience> {
    const url = `${environment.serverUrl}/species/${specie.id}/resilience/`;
    return this.http.patch<SpecieResilience>(url, specie).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  updateEfficiency(specie: SpecieThermalComfort): Observable<SpecieThermalComfort> {
    const url = `${environment.serverUrl}/species/${specie.id}/efficiency/`;
    return this.http.patch<SpecieThermalComfort>(url, specie).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  updatePollution(specie: SpecieAirQuality): Observable<SpecieAirQuality> {
    const url = `${environment.serverUrl}/species/${specie.id}/pollution/`;
    return this.http.patch<SpecieAirQuality>(url, specie).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  updateBiodiversity(specie: SpecieBiodiversity): Observable<SpecieBiodiversity> {
    const url = `${environment.serverUrl}/species/${specie.id}/biodiversity/`;
    return this.http.patch<SpecieBiodiversity>(url, specie).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  updateBiophilia(specie: SpecieBiophilia): Observable<SpecieBiophilia> {
    const url = `${environment.serverUrl}/species/${specie.id}/biophilia/`;
    return this.http.patch<SpecieBiophilia>(url, specie).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  updateCo2Absorption(specie: SpecieCo2): Observable<SpecieCo2> {
    const url = `${environment.serverUrl}/species/${specie.id}/co2/`;
    return this.http.patch<SpecieCo2>(url, specie).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Actualizado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  getSpecie(specieId: number): Observable<Specie> {
    for (const specie of this.species) {
      if (specie.id === specieId) {
        return of(specie);
      }
    }
    const url = `${environment.serverUrl}/species/${specieId}`;
    return this.http.get(url).pipe(
      map((json: any) => Object.assign(new Specie(), json))
    );
  }

  listSpeciesRange(): Observable<SpecieAggregator[]> {
    const url = `${environment.serverUrl}/species/complete/ranges`;
    return this.http.get(url).pipe(
      map((json: any) => {
        const result: SpecieAggregator[] = [];
        for (const itm of json) {
          result.push(new SpecieAggregator(itm));
        }
        return result;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

}
