import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable, Subject, merge } from 'rxjs';
import { debounceTime, distinctUntilChanged, map } from 'rxjs/operators';
import { Specie } from 'src/app/species/specie';
import { SpecieService } from 'src/app/species/specie.service';
import { Distribution } from '../distribution';
import { DistributionService } from '../distribution.service';
import { Inventory } from '../inventory';

@Component({
  selector: 'app-space-distribution',
  templateUrl: './space-distribution.component.html',
  styleUrls: ['./space-distribution.component.scss']
})
export class SpaceDistributionComponent implements OnInit {
  @Input() distribution: Distribution;
  @Input() spaceId: number;

  focus$ = new Subject<string>();
  speciesList: Specie[];
  amount = 0;
  useDBH = false;

  constructor(
    private speciesService: SpecieService,
    private distributionService: DistributionService,
    private activeModal: NgbActiveModal
  ) { }

  ngOnInit() {
    this.speciesService.listSpecies().subscribe(
      (species: Specie[]) => this.speciesList = species
    );
    if (this.distribution === undefined) {
      this.distribution = new Distribution(this.speciesService);
    }
    this.amount = this.distribution.amount;
  }
  updateInventoryRows() {
    let difference = this.distribution.inventory.length - this.amount;
    while (difference > 0) {
      this.distribution.popUnit();
      difference -= 1;
    }
    while (difference < 0) {
      this.distribution.addUnit();
      difference += 1;
    }
  }
  close() {
    if (this.distribution.id !== undefined) {
      this.distributionService.updateDistribution(this.distribution).subscribe(
        () => this.activeModal.close()
      );
    } else {
      this.distributionService.createDistribution(this.distribution).subscribe(
        (distribution: any) => this.distributionService.hidrateDistribution(distribution).subscribe(
          (hidratedDistribution: Distribution) => this.activeModal.close(hidratedDistribution))
      );
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  remove() {
    this.distributionService.removeDistribution(this.distribution).subscribe(
      () => this.activeModal.close(null),
      () => {}
    );
  }
  specieFormatter = (specie: Specie) => `${specie.name} (${specie.science_name}) `;

  search = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$ = this.focus$;
    return merge(debouncedText$, inputFocus$).pipe(
      map(term => this.getSpeciesMatching(term).slice(0, 10))
    );
  }

  private getSpeciesMatching(namePattern: string): Specie[] {
    if (namePattern === undefined || namePattern === null || namePattern === '') {
      return this.speciesList;
    } else {
      return this.speciesList.filter(
        (specie: Specie) => this.specieFormatter(specie).toLowerCase().indexOf(namePattern.toLowerCase()) > -1
      );
    }
  }
  remLine(index: number) {
    this.distribution.popUnit(index);
    this.amount = this.distribution.inventory.length;
  }
  setSpecie() {
    if (this.distribution.specie){
      this.distribution.updateSpecie(this.distribution.specie).subscribe();
    }
  }

  updateCalculations() {
    this.distribution.inventory.forEach((line: Inventory) => line.calculateFromDBH(this.distribution.specie));
  }
}
