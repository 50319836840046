<div class="container-fluid" *ngIf="specieAirQuality">

  <div class="edition_area" *ngIf="user.permissions.indexOf('species.edit_pollution_data') > -1">
    <button type="button" class="btn btn-outline-success float-right" (click)="editAirQuality()">Editar</button>
  </div>
  <table class="table">
    <tbody>
      <tr class="no-border">
        <td><strong>Absorción de CO (µg/cm<sup>2</sup>/día)</strong></td>
        <td>{{specieAirQuality.co_absorption | number : '1.4-4' : 'es'}}</td>
      </tr>

      <tr>
        <td><strong>Absorción de NO (µg/cm<sup>2</sup>/día)</strong></td>
        <td>{{specieAirQuality.no_absorption | number : '1.4-4' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Absorción de NO<sub>2</sub> (µg/cm<sup>2</sup>/día)</strong></td>
        <td>{{specieAirQuality.no2_absorption | number : '1.4-4' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Absorción de SO<sub>2</sub> (µg/cm<sup>2</sup>/día)</strong></td>
        <td>{{specieAirQuality.so2_absorption | number : '1.4-4' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Absorción de PM<sub>1</sub> (µg/cm<sup>2</sup>/día)</strong></td>
        <td>{{specieAirQuality.pm1_absorption | number : '1.4-4' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Absorción de PM<sub>2,5</sub> (µg/cm<sup>2</sup>/día)</strong></td>
        <td>{{specieAirQuality.pm2_5_absorption | number : '1.4-4' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Absorción de PM<sub>10</sub> (µg/cm<sup>2</sup>/día)</strong></td>
        <td>{{specieAirQuality.pm10_absorption | number : '1.4-4' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Emisión de COVs totales (ppm/g/15 minutos)</strong></td>
        <td>{{specieAirQuality.cov_emission | number : '1.4-4' : 'es'}}</td>
      </tr>

    </tbody>
  </table>

</div>
