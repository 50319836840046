export class Installation {
    pk: number;
    name: string;
    address: string;

    constructor(pk: number, name: string, address: string) {
        this.pk = pk;
        this.name = name;
        this.address = address;
    }
}
