import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SpeciesListComponent } from './species-list/species-list.component';
import { UIRouterModule, Transition } from '@uirouter/angular';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { SpecieDetailComponent } from './specie-detail/specie-detail.component';
import { SearchPipe } from './search.pipe';
import { SortByPipe } from './sortBy.pipe';
import { ToleranceEditorComponent } from './tolerance-editor/tolerance-editor.component';
import { SpecieEditorBaseComponent } from './specie-editor-base/specie-editor-base.component';
import { SpecieEditorResilienceComponent } from './specie-editor-resilience/specie-editor-resilience.component';
import { SpecieEditorTermalComponent } from './specie-editor-termal/specie-editor-termal.component';
import { SpecieEditorPollutionComponent } from './specie-editor-pollution/specie-editor-pollution.component';
import { SpecieEditorBiodiversityComponent } from './specie-editor-biodiversity/specie-editor-biodiversity.component';
import { SpecieResilienceComponent } from './specie-resilience/specie-resilience.component';
import { SpecieEfficiencyComponent } from './specie-efficiency/specie-efficiency.component';
import { SpecieAirQualityComponent } from './specie-air-quality/specie-air-quality.component';
import { SpecieEditorBiophiliaComponent } from './specie-editor-biophilia/specie-editor-biophilia.component';
import { SpecieBiophiliaComponent } from './specie-biophilia/specie-biophilia.component';
import { SpecieBiodiversityComponent } from './specie-biodiversity/specie-biodiversity.component';
import { SpecieCo2AbsorptionComponent } from './specie-co2-absorption/specie-co2-absorption.component';
import { SpecieEditorCo2AbsorptionComponent } from './specie-editor-co2-absorption/specie-editor-co2-absorption.component';

export function getSpecie(transition: Transition) {
  return transition.params().specieId;
}
const speciesListState = {
  name: 'home.species',
  url: '/species',
  views: { content: {component: SpeciesListComponent, data: {requiresAuth: true}}}
};
const specieDetailState = {
  name: 'home.species.detail',
  url: '/specie/:specieId',
  views: {'content@home': {component: SpecieDetailComponent, data: {requiresAuth: true}}},
  resolve: [
    {
      token: 'specieId',
      deps: [Transition],
      resolveFn: getSpecie
    }
  ]
};

@NgModule({
  declarations: [
    SpeciesListComponent,
    SpecieDetailComponent,
    SearchPipe,
    SortByPipe,
    ToleranceEditorComponent,
    SpecieEditorBaseComponent,
    SpecieEditorResilienceComponent,
    SpecieEditorTermalComponent,
    SpecieEditorPollutionComponent,
    SpecieEditorBiodiversityComponent,
    SpecieEditorBiophiliaComponent,
    SpecieResilienceComponent,
    SpecieEfficiencyComponent,
    SpecieAirQualityComponent,
    SpecieBiodiversityComponent,
    SpecieBiophiliaComponent,
    SpecieCo2AbsorptionComponent,
    SpecieEditorCo2AbsorptionComponent
  ],
  entryComponents: [
    SpecieEditorBaseComponent,
    SpecieEditorResilienceComponent,
    SpecieEditorTermalComponent,
    SpecieEditorPollutionComponent,
    SpecieEditorBiodiversityComponent,
    SpecieEditorBiophiliaComponent,
    SpecieResilienceComponent
  ],
  imports: [
    CommonModule,
    NgbModule,
    FormsModule,
    HttpClientModule,
    UIRouterModule.forChild({ states: [speciesListState, specieDetailState]})
  ]
})
export class SpeciesModule { }
