import { Component, OnInit, Input } from '@angular/core';
import { Installation } from '../installation';
import { InstallationsService } from '../installations.service';
import { SpaceService } from '../space.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-installation-loader',
  templateUrl: './installation-loader.component.html',
  styleUrls: ['./installation-loader.component.scss']
})
export class InstallationLoaderComponent implements OnInit {
  @Input() space;

  installations: Installation[];
  selectedInstallation: number;
  installation: any;
  selectedDevice: string;
  device: any;
  private sensorMap = {
    no2: 'NO2',
    co: 'CO',
    pm10: 'PM10',
    pm1_0: 'PM1',
    pm_2_5: 'PM2_5',
    pm_4: 'PM4',
    temperature: 'temperature_med',
    uva: 'UVA'
  };

  constructor(
    private installationService: InstallationsService,
    private activeModal: NgbActiveModal,
    private spaceService: SpaceService) { }

  ngOnInit() {
    this.installationService.listInstallations().subscribe(
      (installations: Installation[]) => this.installations = installations
    );
  }
  selectInstallation() {
    this.installationService.installationDetail(this.selectedInstallation).subscribe(
      (installationDetail => {
        this.installation = installationDetail;
        if (this.device === undefined) {
          this.device = this.installation.devices[0];
        } else {
          this.selectDevice();
        }
      })
    );
  }
  selectDevice() {
    for (const device of this.installation.devices) {
      if (device.id === this.selectedDevice) {
        this.device = device;
      }
    }
  }
  setValue(sensor) {
    this.space[this.sensorMap[sensor.type]] = sensor.value;
    this.spaceService.updateSpace(this.space).subscribe();
  }
  close() {
    this.activeModal.close(this.space);
  }
}
