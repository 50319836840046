<div class="container-fluid">
  <div class="col col-sm-12 my-2" id="actionBar">
    <a uiSref="home.spaces" class="btn btn-link"><i class="fas fa-caret-left"></i> Volver </a>
  </div>
  <div class="col col-sm-12" id="spaceDetail">
    <h1>{{space.name}}</h1>
    <ul ngbNav #nav="ngbNav" [(activeId)]="active" class="nav nav-tabs justify-content-end">
      <li [ngbNavItem]="'characteristics'">
        <a ngbNavLink>Caracterización</a>
        <ng-template ngbNavContent>
          <div class="container-fluid">
            <div class="row">
              <div class="col col-lg-12">
                <button type="button" class="btn btn-outline-success float-right my-4"
                (click)="editCaracterization()">Editar</button>
              </div>
            </div>
            <div class="row">
              <div class="col col-lg-6">
                <table class="table">              
                  <tbody>
                    <tr class="no-border">
                      <td><strong>País</strong></td>
                      <td>{{space.country}}</td>
                    </tr>
                    <tr>
                      <td><strong>Región</strong></td>
                      <td>{{space.region}}</td>
                    </tr>
                    <tr>
                      <td><strong>Ciudad</strong></td>
                      <td>{{space.city}}</td>
                    </tr>
                    <tr>
                      <td><strong>Latitud</strong></td>
                      <td>{{space.lat| number: '1.2':'es'}}</td>
                    </tr>
                    <tr>
                      <td><strong>Longitud</strong></td>
                      <td>{{space.lon| number: '1.2':'es'}}</td>
                    </tr>
                    <tr>
                      <td><strong>Altura (m)</strong></td>
                      <td>{{space.height| number: '1.2':'es'}}</td>
                    </tr>
                    <tr>
                      <td><strong>Superficie (ha)</strong></td>
                      <td>{{space.area * 1e-4| number: '1.2-2':'es'}}</td>
                    </tr>
                    <tr>
                      <td><strong>Superficie útil(ha)</strong></td>
                      <td>{{space.useful_surface * 1e-4 | number: '1.2-2':'es'}}</td>
                    </tr>
                    <tr>
                      <td><strong>Modelo de plantación</strong></td>
                      <td>{{plantationFrames[space.plantation_frame]}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="col col-lg-6">
                <img *ngIf="space.map" class="img-fluid img-thumbnail" [src]="space.map">
              </div>
            </div>
            <div class="row my-4">
              <div class="col col-lg-8"  *ngIf="showZones">
                <h2 >Zonas de la infraestructura verde</h2>
              </div>
              <div class="col col-lg-4">
                <button type="button" class="btn btn-outline-success float-right my-4"
                (click)="openEditZoneModal()" *ngIf="showZones">Añadir zona</button>
                <button type="button" class="btn btn-outline-success my-4"
                (click)="workInZones()" *ngIf="!showZones">Trabajar por zonas</button>
              </div>
              <div class="col col-lg-12">        
                <table class="table" *ngIf="showZones">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Nombre</th>
                      <th>Sup. total (ha)</th>
                      <th>Sup. útil (ha)</th>
                      <th>Modelo de plantación</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let zone of zones; index as i">
                      <td>{{i}}</td>
                      <td>{{zone.name}}</td>
                      <td>{{zone.total_surface * 1e-4 | number:'1.2-2':'es'}}</td>
                      <td>{{zone.useful_surface * 1e-4 | number:'1.2-2':'es'}}</td>
                      <td>{{plantationFrames[zone.plantation_frame]}}</td>
                      <td><span class="action" (click)="openEditZoneModal(zone)">Editar</span> <span class="action remove" (click)="deleteZone(zone)" *ngIf="i > 0">Eliminar</span></td>
                    </tr>
                  </tbody>
                </table>
            </div>
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="'climaticData'">
        <a ngbNavLink>Datos climatológicos</a>
        <ng-template ngbNavContent>
          <button type="button" class="btn btn-outline-success float-right my-4" (click)="editClima()">Editar</button>
          <table class="table">
            <tbody>
              <tr class="no-border">
                <td><strong>Tª Media máximas (ºC)</strong></td>
                <td>{{space.temperature_min| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Tª Media mínimas (ºC)</strong></td>
                <td>{{space.temperature_max| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Tª Media mensual (ºC)</strong></td>
                <td>{{space.temperature_med| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Nº días de Tª mínima &lt; 0ºC</strong></td>
                <td>{{space.days_under_0| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Nº días de Tª máxima &gt; 30ºC</strong></td>
                <td>{{space.days_over_35| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Precipitación total (mm)</strong></td>
                <td>{{space.rain| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Nº días nieve</strong></td>
                <td>{{space.snow_days| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Duración media periodo de heladas (días)</strong></td>
                <td>{{space.frozen_days| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Velocidad del viento (km/h)</strong></td>
                <td>{{space.wind_speed| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Humedad relativa medida (%)</strong></td>
                <td>{{space.relative_humidity| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>ETP (mm)</strong></td>
                <td>{{space.ETP| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Radiación solar (kW/m<sup>2</sup>)</strong></td>
                <td>{{space.sun_time_med| number: '1.2':'es'}}</td>
              </tr>
              <tr>
                <td><strong>Radiación UVA (kW/m<sup>2</sup>)</strong></td>
                <td>{{space.sun_time_med| number: '1.2':'es'}}</td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </li>

      <li [ngbNavItem]="'pollutionData'">
        <a ngbNavLink>Datos de contaminación</a>
        <ng-template ngbNavContent>
          <div class="container-fluid my-3">
            <div class="row">
              <div class="col col-lg-12 my-4">
                <button type="button" class="btn btn-outline-success float-right " (click)="editPollution()">Cargar
                  datos manualmente</button>
              </div>
            </div>
          </div>
          <table class="table">
            <tbody>
              <tr class="no-border">
                <td><strong>SO<sub>2</sub> (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.SO2| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>NO (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.NO| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>NO<sub>2</sub> (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.NO2| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>CO (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.CO| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>CO<sub>2</sub> (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.CO2| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>PM<sub>1</sub> (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.PM1| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>PM<sub>2.5</sub> (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.PM2_5| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>PM<sub>10</sub> (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.PM10| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>O<sub>3</sub> (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.O3| number: '1.4':'es'}}</td>
              </tr>
              <tr>
                <td><strong>COV (&#181;g/m<sup>3</sup>)</strong></td>
                <td>{{space.COV| number: '1.4':'es'}}</td>
              </tr>
            </tbody>
          </table>
        </ng-template>
      </li>

      <li [ngbNavItem]="'specieDistribution'" *ngIf="space.kind === 'EXIS'">
        <a ngbNavLink>Distribución de especies</a>
        <ng-template ngbNavContent>
          <div class="pt-4 mb-2" >
            <form>
              <div class="form-row d-flex justify-content-between">
                <div class="form-group" *ngIf="zones.length > 1">
                  <label for="zoneSelector">Selecciona una zona</label>
                  <select class="form-control" id="zoneSelector" required [(ngModel)]="selectedZone"
                    name="zoneSelector">
                    <option value="total">total</option>
                    <option *ngFor="let zone of zones" [value]="zone.id">{{zone.name}}</option>
                  </select>
                </div>
                <div class="form-group" *ngIf="selectedZone !== 'total'">
                  <label for="distributionFile">Cargar una distribución desde una hoja excel</label>
                  <input type="file" class="form-control-file" id="distributionFile" (change)="onSelectDistributionFile($event)">
                </div>
                <!-- <button class="btn btn-outline-success my-4" type="button" (click)="getDistributionReport()">Descargar sólo distribución actual</button>-->
              </div>
              
              
            </form>
          </div>
          <div class="row pt-4 mb-2 align-items-center">
            <div class="col-auto mr-auto">
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="air_view" name="dataView" class="custom-control-input" [(ngModel)]="dataView" [value]="0" (click)="toggleRadio(0)">
                <label class="custom-control-label" for="air_view">Ver calidad del aire</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="temp_view" name="dataView" class="custom-control-input" [(ngModel)]="dataView" [value]="1" (click)="toggleRadio(1)">
                <label class="custom-control-label" for="temp_view">Ver confort térmico y radiativo</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="bio_view" name="dataView" class="custom-control-input" [(ngModel)]="dataView" [value]="2" (click)="toggleRadio(2)">
                <label class="custom-control-label" for="bio_view">Ver biofilia y biodiversidad</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="co2_view" name="dataView" class="custom-control-input" [(ngModel)]="dataView" [value]="3">
                <label class="custom-control-label" for="co2_view">Ver datos CO<sub>2</sub></label>
              </div>              
            </div>
            <div class="col-auto " *ngIf="dataView == 0 || dataView == 3">
              <div class="custom-control custom-radio custom-control-inline" >
                <input type="radio" id="scpecie_view" name="visualization" class="custom-control-input" [(ngModel)]="visualization" [value]="0">
                <label class="custom-control-label" for="scpecie_view">Ver por especie</label>
              </div>
              <div class="custom-control custom-radio custom-control-inline">
                <input type="radio" id="inventory_view" name="visualization" class="custom-control-input" [(ngModel)]="visualization" [value]="1">
                <label class="custom-control-label" for="inventory_view">Ver por ejemplar</label>
              </div>
            </div>
            
          
          </div>
          <div class="row">
            <div class="col-auto">
              <table class="table table-hover">

                <thead class="thead-dark">
                  <tr>
                    <th>Ejemplar</th>
                    <th> <span *ngIf="visualization==0">Ejemplares</span><span *ngIf="visualization==1">DBH (cm)</span></th>
                    <th>Superficie foliar (m<sup>2</sup>)</th>
                    <th>Biomasa (Kg)</th>
                    <th>Sup. sombreamiento (m<sup>2</sup>)</th>
                    <th><span *ngIf="visualization==1">Identificador</span></th>
                    <th></th>
                    <th *ngIf="dataView == 0">Absorción CO (g/día)</th>
                    <th *ngIf="dataView == 0">Absorción NO<sub>2</sub> (g/día)</th>
                    <th *ngIf="dataView == 0">Absorción NO (g/día)</th>
                    <th *ngIf="dataView == 0">Absorción SO<sub>2</sub> (g/día)</th>
                    <th *ngIf="dataView == 0">Absorción PM<sub>1</sub> (g/día)</th>
                    <th *ngIf="dataView == 0">Absorción PM<sub>2,5</sub> (g/día)</th>
                    <th *ngIf="dataView == 0">Absorción PM<sub>10</sub> (g/día)</th>
                    <th *ngIf="dataView == 0">Emisión de COVs (ppm/15 minutos)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª bajo dosel (%)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª de la acera (%)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª del asfalto (%)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª del césped (%)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª del albero (%)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª radiante en acera(%)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª radiante en asfalto(%)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª radiante en (%)</th>
                    <th *ngIf="dataView == 1">Reduc. Tª radiante en albero(%)</th>
                    <th *ngIf="dataView == 1">Incremento de la humedad relativa (%)</th>
                    <th *ngIf="dataView == 1">Reduc. del índice estrés térmico en acera (%)</th>
                    <th *ngIf="dataView == 1">Reduc. del índice estrés térmico en asfalto (%)</th>
                    <th *ngIf="dataView == 1">Reduc. del índice estrés térmico en cesped (%)</th>
                    <th *ngIf="dataView == 1">Reduc. del índice estrés térmico en albero (%)</th>
                    <th *ngIf="dataView == 1">Reduc. rayos UVA (400-315 nm) (%)</th>
                    <th *ngIf="dataView == 1">Reduc. rayos UV(B+C) (315-100 nm) (%)</th>
                    <th *ngIf="dataView == 1">Reduc. rad. salida (400-1200 nm) acera(%)</th>
                    <th *ngIf="dataView == 1">Reduc. rad. salida (400-1200 nm) asfalto(%)</th>
                    <th *ngIf="dataView == 1">Reduc. rad. salida (400-1200 nm) cesped(%)</th>
                    <th *ngIf="dataView == 1">Reduc. rad. salida (400-1200 nm) albero(%)</th>

                    <th *ngIf="dataView == 2">Recurso de refugio</th>
                    <th *ngIf="dataView == 2">Recurso trófico</th>
                    <th *ngIf="dataView == 2" class="spliter">Cromatismo</th>
                    <th *ngIf="dataView == 2">Aroma</th>
                    <th *ngIf="dataView == 2">Vistosidad</th>

                    <th *ngIf="dataView == 3">Absorción de CO<sub>2</sub> (kg/año)</th>
                    <th *ngIf="dataView == 3">Proyección de almacenamiento CO<sub>2</sub> a 5 años</th>
                    <th *ngIf="dataView == 3">Proyección de almacenamiento CO<sub>2</sub> a 10 años</th>
                    <th *ngIf="dataView == 3">Proyección de almacenamiento CO<sub>2</sub> a 15 años</th>
                    <th *ngIf="dataView == 3">Proyección de almacenamiento CO<sub>2</sub> a 20 años</th>
                    <th *ngIf="dataView == 3">Proyección de almacenamiento CO<sub>2</sub> a 25 años</th>
                    <th *ngIf="dataView == 3">Proyección de almacenamiento CO<sub>2</sub> a 30 años</th>
                    <th *ngIf="dataView == 3">Proyección de almacenamiento CO<sub>2</sub> a 35 años</th>
                    <th *ngIf="dataView == 3">Proyección de almacenamiento CO<sub>2</sub> a 40 años</th>


                </tr>
                </thead>
                <tbody>
                  
                  <tr *ngFor="let specieUnit of zonesDistribution[selectedZone].distribution; index as j" [ngClass]="{'table-secondary': specieUnit.specie && visualization==1 && dataView==0, 'd-none': !specieUnit.specie && (visualization==0 || dataView==1)}">
                    <td ><span *ngIf="specieUnit.specie"><span class=" specie_science_name"><i>{{specieUnit.specie.science_name | lowercase | titlecase}}</i></span><br /><span
                      class="text-muted specie_nspecie_science_nameame">{{specieUnit.specie.name | lowercase | titlecase }}</span></span>
                    </td>
                    <td><span *ngIf="specieUnit.specie">{{specieUnit.amount}} ejemplares</span>{{specieUnit.dbh | number:'0.2':'es'}}</td>
                    <td>{{specieUnit.leaf_area / 1e4 | number:'0.2':'es'}}</td>
                    <td>{{specieUnit.biomass_gr / 1000 | number:'0.2':'es'}}</td>
                    <td>{{specieUnit.shadow_area | number:'0.2':'es'}}</td>
                    <td><span *ngIf="specieUnit.unit_identification"></span>{{specieUnit.unit_identification }}</td>
                    <td>
                      <span *ngIf="specieUnit.specie && selectedZone !== 'total'" class="action-link" (click)="openEditSpecieDistributionModal(specieUnit)">
                      Editar especie</span>
                      <span *ngIf="specieUnit.specie && selectedZone == 'total'" >
                        {{specieUnit.zone}}</span>
                    </td>
                      
                    <td *ngIf="dataView == 0">{{specieUnit.co_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{specieUnit.no2_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{specieUnit.no_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{specieUnit.so2_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{specieUnit.pm1_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{specieUnit.pm2_5_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{specieUnit.pm10_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{specieUnit.cov_emission | number: '1.4':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.air_temp_reduction_pct | number: '1.2':'es'}}</td>
                    
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pavement_temp_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.asphalt_temp_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.grass_temp_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pipeclay_temp_reduction_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pavement_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.asphalt_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.grass_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pipeclay_temp_radiation_reduction_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.humidity_increase_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.wgtb_pavement | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.wgtb_asphalt | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.wgtb_grass | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.wgtb_pipeclay | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.uva_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.uvbc_reduction_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pavement_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.asphalt_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.grass_out_radiation_reduction_pct | number: '1.2':'es'}}</td>
                    <td *ngIf="dataView == 1">{{specieUnit.thermalComfort.pipeclay_out_radiation_reduction_pct | number: '1.2':'es'}}</td>

                    <td *ngIf="dataView == 2">{{specieUnit.biodiversity.biodiversity_shelter_resource | number: '1.1':'es'}}</td>
                    <td *ngIf="dataView == 2">{{specieUnit.biodiversity.biodiversity_trophic_resource | number: '1.1':'es'}}</td>
                    <td *ngIf="dataView == 2">{{specieUnit.biophilia.biophilia_chromatism_resource | number: '1.1':'es'}}</td>
                    <td *ngIf="dataView == 2">{{specieUnit.biophilia.biophilia_fragrance_resource | number: '1.1':'es'}}</td>
                    <td *ngIf="dataView == 2">{{specieUnit.biophilia.biophilia_ornateness_resource | number: '1.1':'es'}}</td>

                    <td *ngIf="dataView == 3">{{specieUnit.co2_absorption | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{specieUnit.co2_5_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{specieUnit.co2_10_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{specieUnit.co2_15_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{specieUnit.co2_20_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{specieUnit.co2_25_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{specieUnit.co2_30_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{specieUnit.co2_35_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{specieUnit.co2_40_years_projection | number: '1.4':'es'}}</td>
                    



                  </tr>
                    
                  <tr class="hover-trigger">
                    <td><strong>Total infraestructura verde</strong></td>
                    <td>{{zonesDistribution[selectedZone].amount | number:'1.2-2':'es'}}</td>
                    <td>{{zonesDistribution[selectedZone].leaf_area / 1e4 | number:'1.2-2':'es'}}</td>
                    <td>{{zonesDistribution[selectedZone].biomass_gr / 1000 | number:'1.2-2':'es'}}</td>
                    <td>{{zonesDistribution[selectedZone].shadow_area | number:'1.2-2':'es'}}</td>
                    <td></td>
                    <td> <span *ngIf="selectedZone != 'total'" class="action-link " (click)="openEditSpecieDistributionModal()">
                      Añadir especie</span></td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.co_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.no2_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.no_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.so2_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.pm1_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.pm2_5_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.pm10_absorption / 1e6 | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.cov_emission | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 0">{{zonesDistribution[selectedZone].absorption.co2_absorption | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 1" colspan="20"></td>
                    <td *ngIf="dataView == 2" colspan="5"></td>
                    
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_absorption | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_5_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_10_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_15_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_20_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_25_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_30_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_35_years_projection | number: '1.4':'es'}}</td>
                    <td *ngIf="dataView == 3">{{zonesDistribution[selectedZone].co2.co2_40_years_projection | number: '1.4':'es'}}</td>
                    
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </ng-template>
      </li>

      <li [ngbNavItem]="'reports'">
        <a ngbNavLink>Informes</a>
        <ng-template ngbNavContent>
          <app-space-simulation [space]="space" [zones]="zones"></app-space-simulation>
        </ng-template>
      </li>

    </ul>
    <div [ngbNavOutlet]="nav"></div>
  </div>
</div>
