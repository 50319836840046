<div class="container-fluid">
  <div class="row specieslist--create-specie align-items-center">
    <div class="col-auto justify-content-end header-container">
      <h2>Listado especies</h2>
      <button type="button" class="btn btn-outline-success" (click)="openCreateSpecieModal()" *ngIf="user.permissions.indexOf('species.add_species') >= 0">
        Añadir
      </button>
    </div>
  </div>
  <div class="row flex-container">
    <div class="col col-md-3">
      <h6>Filtrar por <strong>nombre</strong>:</h6>
        <input type="text" class="form-control" id="searchBar" placeholder="Nombre de la especie" [(ngModel)]="query">
    </div>
    <div class="col col-md-3">
      <h6>Mostrar especies de<strong> tipo</strong>:</h6>
      <select id="speciesTypeSelector" [(ngModel)]="speciesTreeType" class="form-control">
        <option value="">Cualquiera</option>
        <option *ngFor="let treeType of treeTypes" [ngValue]="treeType.value" >
          {{ treeType.label }}
        </option>
      </select>
    </div>
    <div class="col col-md-3">
      <h6>Ordenadas por<strong> nombre</strong>:</h6>
      <select id="speciesSortingPropertySelector" [(ngModel)]="speciesSortingProperty" class="form-control">
        <option *ngFor="let sortingProperty of sortingProperties" [ngValue]="sortingProperty.value">
          {{ sortingProperty.label }}
        </option>
      </select>.
    </div>
    <div class="col col-md-3">
      <h6>Mostrar por <strong>vista</strong>:</h6>
        <select class="form-control" [(ngModel)]="view">
          <option value="List">Lista</option>
          <option value="Mosaic">Mosaico</option>
        </select>
    </div>
  </div>
  <div class="row" *ngIf="view === 'Mosaic'">
    <div class="col-lg-2 col-md-4 column_padding" *ngFor="let specie of speciesList | search:'name,science_name':query | search:'treeType':speciesTreeType | sortBy:speciesSortingProperty; index as i" [ngClass]="specie.treeType | lowercase">
      <div class="card card_atributes" [ngClass]="{'arbol': specie.treeType === 'ARBOL', 'arbusto': specie.treeType === 'ARBUSTO', 'herbacea': specie.treeType === 'HERBACEA', 'trepadora': specie.treeType === 'TREPADORA'}" uiSref="home.species.detail" [uiParams]="{specieId: specie.id}">
        <div class="card-body">
          <h5 class="specie_science_name">
            <i *ngIf="specie.treeType !== 'ARBUSTO'" [ngClass]="{
              'ARBOL':'fa fa-tree', 
              'HERBACEA':'fas fa-seedling', 
              'TREPADORA':'fa fa-leaf'
              }[specie.treeType]" class="specie-symbols"></i>
          
          <img *ngIf="specie.treeType === 'ARBUSTO'" src="assets/grass.svg" alt="grass" class="specie-symbols specie-symbols-arbusto">
            
          <i>{{specie.science_name }}</i>
          </h5>
          <h6 class="card-title specie_name">{{specie.name}}</h6>
        </div>
      </div>
    </div>
  </div>
  <div class="row" *ngIf="view === 'List'">
    <div class="col col-sm-12 col-md-12 d-flex flex-column justify-content-start" >
        <div class="row row-headers">
          <h6 class="col"><u><strong>Nombre científico</strong></u></h6>
          <h6 class="col"><u><strong>Nombre especie</strong></u></h6>
          <h6 class="col"><u><strong>Tipo</strong></u></h6>
        </div>
      <div class="list-of-spaces" *ngFor="let specie of speciesList | search:'name,science_name':query | search:'treeType':speciesTreeType | sortBy:speciesSortingProperty; index as i" [ngClass]="specie.treeType | lowercase">        
          <div class="card card_atributes card-list" [ngClass]="{'arbol': specie.treeType === 'ARBOL', 'arbusto': specie.treeType === 'ARBUSTO' , 'herbacea': specie.treeType === 'HERBACEA', 'trepadora': specie.treeType === 'TREPADORA'}" uiSref="home.species.detail" [uiParams]="{specieId: specie.id}">
            <div class="card-body list-row">
              <h5 class="specie_science_name">
                <i *ngIf="specie.treeType !== 'ARBUSTO'" [ngClass]="{
                  'ARBOL':'fa fa-tree', 
                  'HERBACEA':'fas fa-seedling', 
                  'TREPADORA':'fa fa-leaf'
                  }[specie.treeType]" class="specie-symbols"></i>
              
              <img *ngIf="specie.treeType === 'ARBUSTO'" src="assets/grass.svg" alt="grass" class="specie-symbols specie-symbols-arbusto-list ">
                <i><strong>{{specie.science_name | lowercase}}</strong></i>
              </h5>
              <h5 class="card-title specie_name">{{specie.name }}</h5>
              <h5 class="specie_treeType_name" *ngIf="specie.treeType === 'ARBOL'">Árbol</h5>
              <h5 class="specie_treeType_name" *ngIf="specie.treeType === 'ARBUSTO'">Arbusto</h5>
              <h5 class="specie_treeType_name" *ngIf="specie.treeType === 'HERBACEA'">Herbácea</h5>
              <h5 class="specie_treeType_name" *ngIf="specie.treeType === 'TREPADORA'">Trepadora</h5>
            </div>
          </div>
      </div>
    </div>
  </div>

</div>

