import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpaceService } from '../space.service';


@Component({
  selector: 'app-space-editor',
  templateUrl: './space-editor.component.html',
  styleUrls: ['./space-editor.component.scss']
})
export class SpaceEditorComponent implements OnInit {
  @Input() space;
  errorMessage: string = '';

  plantationFrames = [
    {value: 'ALI_VIA', label: 'Alineaciones en viario: distancia de 6-7 metros entre árboles'},
    {value: 'ALI_PRQ', label: 'Alineaciones en parques: distancia de 4-5 metros entre árboles'},
    {value: 'BOS_PRQ', label: 'Bosquetes en parques: distancia de 2 m entre árboles'},
    {value: 'SET_TOD', label: 'Setos (tanto en parques como en viarios): 3 árboles/metro lineal'},
    {value: 'MAZ_PRQ', label: 'Macizos de arbustos en parques: 2 arbustos/ metro lineal'}
  ];

  constructor(
    private activeModal: NgbActiveModal,
    private spacesService: SpaceService
  ) { }

  ngOnInit() {
  }

  save() {
    if (this.space.kind === undefined || this.space.name === undefined) {
      this.errorMessage = 'El campo nombre y tipo son obligatorios';
      return;
    }
    if (this.space.id !== undefined) {
      this.spacesService.updateSpace(this.space).subscribe(
        space => this.activeModal.close(space),
        () => {}
      );
    } else {
        this.spacesService.createSpace(this.space).subscribe(
          space => this.activeModal.close(space),
          () => {}
        );
    }
  }

  dismiss() {
    this.activeModal.dismiss();
  }

  delete() {
    this.spacesService.deleteSpace(this.space).subscribe(
      () => this.activeModal.close(null)
    );
  }

}
