<div>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-basic-title">Carga de datos</h4>
        <button type="button" class="close" aria-label="Close" (click)="dismiss()">
          <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <form>
            <div class="form-group">
                <label for="zoneSelector">Selecciona la columna que contiene el nombre de la especie</label>
                <select class="form-control" id="nameSelector" required [(ngModel)]="nameCol"
                  name="nameSelector">
                  <option *ngFor="let column of headers" [value]="column">{{column}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="zoneSelector">Selecciona la columna que contiene el DBH de la especie</label>
                <select class="form-control" id="dbhSelector" required [(ngModel)]="dbhCol"
                  name="dbhSelector">
                  <option *ngFor="let column of headers" [value]="column">{{column}}</option>
                </select>
              </div>
              <div class="form-group">
                <label>Seleccionar la forma de actuar si DBH igual a 0 o nulo</label>
                <select class="form-control" required [(ngModel)]="dbh_options" name="dbh_options">
                  <option value="minimum">Dbh mínimo</option>
                  <option value="medium">Dbh medio</option>
                </select>
              </div>
              <div class="form-group">
                <label for="zoneSelector">Selecciona la columna que contiene el altura de la especie</label>
                <select class="form-control" id="heightSelector" required [(ngModel)]="heightCol"
                  name="heightSelector">
                  <option value="">No incluir</option>
                  <option *ngFor="let column of headers" [value]="column">{{column}}</option>
                </select>
              </div>
              <div class="form-group">
                <label for="unitIDSelector">Selecciona la columna que contiene el identificador de la especie</label>
                <select class="form-control" id="unitIDSelector" required [(ngModel)]="unitIDCol"
                  name="heightSelector">
                  <option value="">No incluir</option>
                  <option *ngFor="let column of headers" [value]="column">{{column}}</option>
                </select>
              </div>
              <div class="form-group">
                  <label class="btn btn-outline-success" (click)="processRows()">Verificar</label>
              </div>
        </form>
        <table class="table table-sm">
            <thead>
                <tr>
                    <th>Nombre de la especie</th>
                    <th>Número de ejemplares</th>
                    <th>Info</th>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let specieRow of processedRows; index as i" [ngClass]="{'table-danger': specieRow.status == 'error', 'table-warning': specieRow.status=='updated', 'table-success': specieRow.status == 'processed'}">
                    <td>{{specieRow.specieName}}</td>
                    <td>{{specieRow.rows.length}}</td>
                    <td>
                        <span *ngIf="specieRow.status == 'error'">No existe la especie, seleccione una existente (la primera es la asimilada) 
                            <select class="form-control-inline" [(ngModel)]="specieRow.specieName" name="heightSelector" (change)="updateRow(i)">
                              <option value=""></option>
                                <option *ngFor="let specie of getAvaliableSpeciesBasedOnAsimilated(specieRow.specieName)" [value]="specie.science_name.toLowerCase()">{{specie.science_name.toLowerCase()}} ({{specie.name.toUpperCase()}})</option>
                            </select> 
                            o <button class="btn btn-link" (click)="deleteRow(i)">ignorar</button>
                        </span>
                      <span *ngIf="specieRow.status == 'updated'">Nombre original: {{specieRow.originalName}}. Cambiar selección  
                        <select class="form-control-inline" [(ngModel)]="specieRow.specieName" name="heightSelector" (change)="updateRow(i)">
                            <option *ngFor="let specie of availableSpecies" [value]="specie.science_name.toLowerCase()">{{specie.science_name.toLowerCase()}} ({{specie.name.toUpperCase()}})</option>
                        </select> o <button class="btn btn-link" (click)="deleteRow(i)">ignorar</button>
                      </span>
                    </td>
                </tr>
            </tbody>
        </table>
        <div class="row" *ngIf="processedRows.length">
            <div class="col col-md-12">
                <button [disabled]="errorCount > 0 || processing" class="btn btn-outline-success" (click)="import()">Cargar</button>
            </div>
        </div>
    </div>
</div>
