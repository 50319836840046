<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Sensores</h4>
    <button type="button" class="close" aria-label="Close" (click)="close()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form>
      <div class="form-group">
        <label for="installationSelector">Selecciona una instalación</label>
        <select class="form-control" id="installationSelector" required [(ngModel)]="selectedInstallation"
          name="installationSelector" (change)="selectInstallation()">
          <option *ngFor="let installation of installations" [value]="installation.pk">{{installation.name}}</option>
        </select>
      </div>
    </form>
    <div *ngIf="installation" class="row">
      <form class="col col-lg-12">
        <div class="form-group">
          <label for="deviceSelector">Selecciona un dispositivo</label>
          <select class="form-control" id="deviceSelector" required [(ngModel)]="selectedDevice" name="deviceSelector"
            (change)="selectDevice()">
            <option *ngFor="let device of installation.devices" [value]="device.id">{{device.id}}</option>
          </select>
        </div>
      </form>
      <div class="col col-lg-12 my-4" *ngIf="device">
        <button type="button" class="btn btn-outline-success" (click)="selectInstallation()">Refrescar</button>
      </div>
      <div class="col col-lg-12 my-4" *ngIf="device">

        <table class="table">
          <tbody>
            <tr *ngFor="let sensor of device.sensors">
              <td><strong>{{sensor.type}}</strong></td>
              <td>{{sensor.value}}</td>
              <td><button class="btn btn-outline-success" (click)="setValue(sensor)">Usar</button> </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="close()">Cerrar</button>
  </div>
</div>
