export class SpecieAirQuality {
    constructor(
        public id: number,
        public co_absorption?: number,
        public so2_absorption?: number,
        public no_absorption?: number,
        public no2_absorption?: number,
        public pm1_absorption?: number,
        public pm2_5_absorption?: number,
        public pm10_absorption?: number,
        public cov_emission?: number,
    ) { }
}
