export class SpecieBiophilia {
    constructor(
        public id: number,
        public biophilia_leaves_colour?: string,
        public biophilia_chromatic_variance?: string,
        public biophilia_flower_colour?: string,
        public biophilia_fruit_colour?: string,
        public biophilia_flower_fragrance?: string,
        public biophilia_plant_fragrance?: string,
        public biophilia_leaves_permanence?: string,
        public biophilia_flower_abundance?: string,
        public biophilia_flower_size?: string,
        public biophilia_fruit_size?: string,
        public biophilia_top_density?: string,
        public biophilia_pendulum_shape?: string,
        public biophilia_chromatism_resource?: number,
        public biophilia_fragrance_resource?: number,
        public biophilia_ornateness_resource?: number
    ) {
    }
}
