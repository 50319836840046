<div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Descargar el informe</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #selectSimulations="ngForm" (ngSubmit)="download()">
        <div *ngIf="download_options !== 1" >
          <div class="form-row" *ngFor="let zone of zones" >

            <div class="form-group col-sm-12" *ngIf="zone.id !== selectedZone.id">
              <label for="typology">{{ zone.name }}</label>
              <select class="form-control" id="simulations_{{zone.id}}" required [(ngModel)]="selectedSimulations[zone.id]" name="simulations_{{zone.id}}">
                <option *ngFor="let simulation of availableSimulations[zone.id]" [value]="simulation.id">{{simulation.name}}</option>
              </select>
            </div>
          </div>
        </div>
      </form>
      <div *ngIf="download_options === 1" >
        <form #zoneForm="ngForm" (ngSubmit)="onSubmit()">
          <div class="form-group">
            <label for="zoneSelect">Selecciona una zona</label>
            <select class="form-control" id="zoneSelect" required [(ngModel)]="zone_for_distribution_download" name="zone">
              <option *ngFor="let zone of zones" [value]="zone.id">{{zone.name}}</option>
              <option value="all">Todas las zonas</option>
            </select>
          </div>
        </form>
        <h6 *ngIf="error_flag" class="text-danger">Debes seleccionar una zona o todas</h6>
      </div> 
      

    <div class="form-row">      
      <ul ngbNav #downloadfiltersNav="ngbNav" [(activeId)]="active" class="nav nav-tabs justify-content-end">

        <li [ngbNavItem]="'parameters_characteristics'">
          <a ngbNavLink>Características</a>
          <ng-template ngbNavContent>
            <div class="col-lg-12">
              <div class="form-row">
                <div *ngFor="let target of parameters_characteristics" class="form-group col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="check_target{{target.target}}"
                       [(ngModel)]="target.value" name="check_target{{target.target}}">
                      <label class="custom-control-label" for="check_target{{target.target}}">{{target.label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="'parameters_biometric'">
          <a ngbNavLink>Datos biometricos</a>
          <ng-template ngbNavContent>
            <div class="col-lg-12">
              <div class="form-row">
                <div *ngFor="let target of parameters_biometric" class="form-group col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="check_target{{target.target}}"
                       [(ngModel)]="target.value" name="check_target{{target.target}}">
                      <label class="custom-control-label" for="check_target{{target.target}}">{{target.label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="'parameter_diversity'">
          <a ngbNavLink>Diversidad</a>
          <ng-template ngbNavContent>
            <div class="col-lg-12">
              <div class="form-row diversity-form">
                <div *ngFor="let target of parameter_diversity" class="form-group col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="check_target{{target.target}}"
                      [(ngModel)]="target.value" name="check_target{{target.target}}">
                    <label class="custom-control-label" for="check_target{{target.target}}">{{target.label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        <li [ngbNavItem]="'parameters_resilience'">
          <a ngbNavLink>Resiliencia</a>
          <ng-template ngbNavContent>
            <div class="col-lg-12">
              <div class="form-row">
                <div *ngFor="let target of parameters_resilience" class="form-group col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="check_target{{target.target}}"
                       [(ngModel)]="target.value" name="check_target{{target.target}}">
                      <label class="custom-control-label" for="check_target{{target.target}}">{{target.label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        
        <li [ngbNavItem]="'parameters_air_quality'">
          <a ngbNavLink>Calidad del aire</a>
          <ng-template ngbNavContent> 
            <div class="col-lg-12">
                <div class="form-row">
                  <div *ngFor="let target of parameters_air_quality" class="form-group col-lg-4">
                    <div class="custom-checkbox">
                      <input type="checkbox" class="custom-control-input" id="check_target{{target.target}}"
                         [(ngModel)]="target.value" name="check_target{{target.target}}">
                        <label class="custom-control-label" for="check_target{{target.target}}">{{target.label}}<sub>{{target.subindex}}</sub></label>
                    </div>
                  </div>
              </div>
            </div>
          </ng-template>
        </li>
  
        <li [ngbNavItem]="'parameters_thermal_confort'">
          <a ngbNavLink>Confort térmico y radiativo</a>
          <ng-template ngbNavContent>
            <div class="col-lg-12">
              <div class="form-row">
                <div *ngFor="let target of parameters_thermal_confort" class="form-group col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="check_target{{target.target}}"
                       [(ngModel)]="target.value" name="check_target{{target.target}}">
                      <label class="custom-control-label" for="check_target{{target.target}}">{{target.label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
  
        <li [ngbNavItem]="'parameters_biodiversity_biophilia'">
          <a ngbNavLink>Biodiversidad y biofilia</a>
          <ng-template ngbNavContent>
            <div class="col-lg-12">
              <div class="form-row">
                <div *ngFor="let target of parameters_biodiversity_biophilia" class="form-group col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="check_target{{target.target}}"
                       [(ngModel)]="target.value" name="check_target{{target.target}}">
                      <label class="custom-control-label" for="check_target{{target.target}}">{{target.label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>
        
  
        <li [ngbNavItem]="'parameter_co2'">
          <a ngbNavLink>CO<sub>2</sub></a>
          <ng-template ngbNavContent>
            <div class="col-lg-12">
              <div class="form-row">
                <div *ngFor="let target of parameter_co2" class="form-group col-lg-4">
                  <div class="custom-control custom-checkbox">
                    <input type="checkbox" class="custom-control-input" id="check_target{{target.target}}"
                       [(ngModel)]="target.value" name="check_target{{target.target}}">
                      <label class="custom-control-label" for="check_target{{target.target}}">{{target.label}}</label>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </li>

        

      </ul>
      <div [ngbNavOutlet]="downloadfiltersNav"></div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-success" (click)="download()">Descargar</button>
    </div>
  </div>
  