<div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Editar tabla rangos del parámetro: <strong>{{range.label}}</strong></h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #editRangeForm="ngForm" (ngSubmit)="save()">
        <div class="form-row">
            <div *ngFor="let item of range.table_of_ranges; let i = index; trackBy: trackByRangeId " class="col-md-12">
                <label for="Parameter" class="col-form-label col-12"> Valor 
                  {{i===0 ? 'mínimo del rango ' + (i + 1) + ' (Si se deja vacío ó 0 se considerará el mínimo entre las especies)' : 
                  i>0 && i<5  ? 'mínimo del rango ' + (i + 1) 
                  : 'máximo del rango ' + i + ' (Si se deja vacío ó 0 se considerará el máximo entre las especies)' }}    
                </label>
                <input type="number" [(ngModel)]="range.table_of_ranges[i]" class="form-control" [id]="'range_table_' + i" [name]="'range_table__' + i" min="0">
            </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <h6 *ngIf="error_flag" class="text-danger">Los valores intermedios no pueden estar vacíos o los valores siempre son mayores al anterior</h6>
      <!--
        <button type="button" class="btn btn-outline-success" (click)="addRow()">Añadir fila</button>
        <button type="button" class="btn btn-outline-danger" (click)="deleteRow()">Eliminar fila</button>
      -->
      <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>

    </div>
  </div>
  