import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tolerance-editor',
  templateUrl: './tolerance-editor.component.html',
  styleUrls: ['./tolerance-editor.component.scss']
})
export class ToleranceEditorComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
