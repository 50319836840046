import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecieService } from '../specie.service';
import { HttpErrorResponse } from '@angular/common/http';
import { SpecieEditorBaseComponent } from '../specie-editor-base/specie-editor-base.component';
import { Specie } from '../specie';

@Component({
  selector: 'app-species-list',
  templateUrl: './species-list.component.html',
  styleUrls: ['./species-list.component.scss']
})
export class SpeciesListComponent implements OnInit {
  user: User;
  specie: object;
  speciesList: object[];
  query: string;
  treeTypes = Specie.getTypologiesObject();
  speciesTreeType: string = "";
  view: string = "Mosaic";

  sortingProperties = [
    { value: "name", label: "Nombre común" },
    { value: "science_name", label: "Nombre científico" }
  ]
  speciesSortingProperty: string = this.sortingProperties[ 0 ].value;
  leafKinds = [
    {value: 'PERENNE', label: 'Perenne'},
    {value: 'PERENNE_CONIFERA', label: 'Perenne conifera'},
    {value: 'PERENNE_FRONDOSA', label: 'Perenne frondosa'},
    {value: 'CADUCA', label: 'Caduca'},
    {value: 'CADUCA FRONDOSA', label: 'Caduca frondosa'}
  ];
  sizes = [
    {value: 'PEQUEÑO', label: 'Pequeño'},
    {value: 'MEDIO', label: 'Mediano'},
    {value: 'GRANDE', label: 'Grande'}
  ];
  growingSpeed = [
    {value: 'LENTA', label: 'Lenta'},
    {value: 'MEDIA', label: 'Media'},
    {value: 'RAPIDA', label: 'Rápida'},
  ];
  affectationLevels = [
    {value: 'BAJA', label: 'Baja'},
    {value: 'MEDIA', label: 'Media'},
    {value: 'ALTA', label: 'Alta'},
  ];
  constructor(
    private authService: AuthService,
    private modalService: NgbModal,
    private speciesService: SpecieService
  ) {
    this.user = this.authService.user;
  }

  ngOnInit() {
    this.speciesService.listSpecies().subscribe(
      (species: Specie[]) => { 
        this.speciesList = species.map( function( specie: Specie ) {
          specie.treeType = Specie.getStandarisedTypology( specie.typology );
          return specie;
        } );
      },
      (error: HttpErrorResponse) => {}
    );
  }

  openCreateSpecieModal() {
    const modalRef = this.modalService.open(SpecieEditorBaseComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.specie = {};
    modalRef.result.then(
      (specie: any) => this.speciesList.push(specie),
      (reason: any) => {}
    );
  }
}
