<div>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Editar fomento de la biodiversidad de {{ specie.name }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #editResilienceForm="ngForm" (ngSubmit)="save()">
      <div class="form-row">
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_fructification_months">Nº meses Fructificación</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_fructification_months" name="biodiversity_fructification_months" id="biodiversity_fructification_months"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_fructification_season">Estación de Fructificación</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_fructification_season" name="biodiversity_fructification_season" id="biodiversity_fructification_season"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_edible">Parte Comestible</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_edible" name="biodiversity_edible" id="biodiversity_edible"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_blooming_months">Nº meses Floración</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_blooming_months" name="biodiversity_blooming_months" id="biodiversity_blooming_months"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_blooming_season">Estación Floración</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_blooming_season" name="biodiversity_blooming_season" id="biodiversity_blooming_season"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_pollinators_attraction">Atracción polinizadores</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_pollinators_attraction" name="biodiversity_pollinators_attraction" id="biodiversity_pollinators_attraction"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_nutricia_lepidoptera">Nutricia Lepidópteros</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_nutricia_lepidoptera" name="biodiversity_nutricia_lepidoptera" id="biodiversity_nutricia_lepidoptera"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_top_source">Fuente de copa</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_top_source" name="biodiversity_top_source" id="biodiversity_top_source"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_average_leaf_area">Área foliar media</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_average_leaf_area" name="biodiversity_average_leaf_area" id="biodiversity_average_leaf_area"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_average_leaf_area">Longevidad</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_longevity" name="biodiversity_average_leaf_area" id="biodiversity_average_leaf_area"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_cavity_formation">Formación de Cavidades</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_cavity_formation" name="biodiversity_cavity_formation" id="biodiversity_cavity_formation"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_leaves_permanence">Permanencia Hojas</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_leaves_permanence" name="biodiversity_leaves_permanence" id="biodiversity_leaves_permanence"></textarea>
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_thorns_presence">Presencia de Espinas</label>
          <textarea class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_thorns_presence" name="biodiversity_thorns_presence" id="biodiversity_thorns_presence"></textarea>
        </div>
     
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_shelter_resource">Recurso de refugio</label>
          <input type="number" class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_shelter_resource" name="biodiversity_shelter_resource" id="biodiversity_shelter_resource" min="0" max="6">
        </div>
        <div class="form-group col-md-6 col-sm-12">
          <label for="biodiversity_trophic_resource">Recurso trófico</label>
          <input type="number" class="form-control" rows="4" [(ngModel)]="biodiversityData.biodiversity_trophic_resource" name="biodiversity_trophic_resource" id="biodiversity_trophic_resource" min="0" max="7">
        </div>
      </div>
    </form>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
  </div>
</div>
