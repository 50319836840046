export class SpecieAbsorption {
    co_absorption: number;
    no_absorption: number;
    no2_absorption: number;
    so2_absorption: number;
    pm1_absorption: number;
    pm2_5_absorption: number;
    pm10_absorption: number;
    cov_emission: number;

    constructor() {
        this.co_absorption = 0;
        this.no_absorption = 0;
        this.no2_absorption = 0;
        this.so2_absorption = 0;
        this.pm1_absorption = 0;
        this.pm2_5_absorption = 0;
        this.pm10_absorption = 0;
        this.cov_emission = 0;
    }
}
