import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-editor-resilience',
  templateUrl: './specie-editor-resilience.component.html',
  styleUrls: ['./specie-editor-resilience.component.sass']
})
export class SpecieEditorResilienceComponent implements OnInit {
  @Input() specie;
  constructor(
    private activeModal: NgbActiveModal,
    private speciesService: SpecieService
  ) { }

  ngOnInit() {
  }
  save() {
      this.speciesService.updateResilience(this.specie).subscribe(
        specie => this.activeModal.close(specie),
        () => {}
      );
  }

  dismiss() {
    this.activeModal.dismiss();
  }
}
