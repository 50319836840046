import { Injectable } from '@angular/core';
import { MessageService } from '../message.service';
import { HttpClient, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Installation } from './installation';
import { environment } from 'src/environments/environment';
import { map, catchError } from 'rxjs/operators';
import { Message } from '../message';

@Injectable({
  providedIn: 'root'
})
export class InstallationsService {

  constructor(
    private http: HttpClient,
    private messagesService: MessageService) { }

    listInstallations(): Observable<Installation[]> {
      const url = `${environment.serverUrl}/installations/`;
      return this.http.get(url).pipe(
        map((installations: any[]) => {
          const result = [];
          installations.forEach(installation => {
            result.push(new Installation(installation.pk, installation.name, installation.address));
          });
          return result;
        }),
        catchError((httpError: HttpErrorResponse) => {
          this.messagesService.add(new Message('error', httpError.message));
          throw httpError;
        })
      );
    }

    installationDetail(id: number): Observable<any> {
      const url = `${environment.serverUrl}/installations/${id}/`;
      return this.http.get(url).pipe(
        catchError((httpError: HttpErrorResponse) => {
          this.messagesService.add(new Message('error', httpError.message));
          throw httpError;
        })
      );
    }
}


