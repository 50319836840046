<div class="container-fluid" *ngIf="resilienceData">
  <div class="edition_area" *ngIf="user.permissions.indexOf('species.edit_resilience_data') > -1">
    <button type="button" class="btn btn-outline-success float-right" (click)="editResilience()">Editar</button>
  </div>
  <table class="table">
    <tbody>
      <tr class="no-border">
        <td><strong>Tolerante a las heladas</strong></td>
        <td>{{resilienceData.freeze_tolerance ? 'Si': 'No'}}</td>
      </tr>
      <tr>
        <td><strong>Resistencia a plagas y enfermedades</strong></td>
        <td>{{resilienceData.plague_tolerance}}</td>
      </tr>
      <tr>
        <td><strong>Tolerante a la sombra</strong></td>
        <td>{{resilienceData.shadow_tolerance ? 'Si':'No'}}</td>
      </tr>
      <tr>
        <td><strong>Tolerante a suelos pobres/compactos</strong></td>
        <td>{{resilienceData.compact_terrain_tolerance ? 'Si':'No'}}</td>
      </tr>
      <tr>
        <td><strong>Tolerante a suelos salinos</strong></td>
        <td>{{resilienceData.saline_terrain_tolerance ? 'Si': 'No'}}</td>
      </tr>
      <tr>
        <td><strong>Índice de tolerancia térmica (10ºC)</strong></td>
        <td>{{resilienceData.heat_tolerance_10 | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Índice de tolerancia térmica (20ºC)</strong></td>
        <td>{{resilienceData.heat_tolerance_20 | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Índice de tolerancia térmica (30ºC)</strong></td>
        <td>{{resilienceData.heat_tolerance_30 | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>WUE (10ºC)</strong></td>
        <td>{{resilienceData.wue_10 | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>WUE (20ºC)</strong></td>
        <td>{{resilienceData.wue_20 | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>WUE (30ºC)</strong></td>
        <td>{{resilienceData.wue_30 | number : '1.2-2' : 'es'}}</td>
      </tr>
      <tr>
        <td><strong>Resistencia a la sequedad elevada</strong></td>
        <td>{{resilienceData.drought_resistance_se ? 'Si': 'No'}}</td>
      </tr>
      <tr>
        <td><strong>Resistencia a la sequedad media</strong></td>
        <td>{{resilienceData.drought_resistance_sm ? 'Si': 'No'}}</td>
      </tr>
      <tr>
        <td><strong>Resistencia a la sequedad débil</strong></td>
        <td>{{resilienceData.drought_resistance_sd ? 'Si': 'No'}}</td>
      </tr>
      <tr>
        <td><strong>Resistencia a la humedad débil</strong></td>
        <td>{{resilienceData.drought_resistance_hd ? 'Si': 'No'}}</td>
      </tr>
      <tr>
        <td><strong>Resistencia a la humedad media</strong></td>
        <td>{{resilienceData.drought_resistance_hm ? 'Si': 'No'}}</td>
      </tr>
      <tr>
        <td><strong>Resistencia a la humedad elevada</strong></td>
        <td>{{resilienceData.drought_resistance_he ? 'Si': 'No'}}</td>
      </tr>
      <tr>
        <td><strong>Índice de estabilidad</strong></td>
        <td>{{resilienceData.stability_index | number : '1.2-2' : 'es'}}</td>
      </tr>
    </tbody>
  </table>
</div>
