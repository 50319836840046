import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Simulation } from './simulation';
import { environment } from 'src/environments/environment';
import { tap, map, catchError } from 'rxjs/operators';
import { MessageService } from '../message.service';
import { Message } from '../message';
import { CO2Filters } from './simulation-filter';

@Injectable({
  providedIn: 'root'
})
export class SimulationService {

  constructor(
    private http: HttpClient,
    private messagesService: MessageService
  ) { }

  serialize(simulation: Simulation): any {
    const serialized = {
      author: simulation.author,
      collaborators: [],
      distribution: [],
      zone: simulation.zone,
      air_filter: simulation.filters.qualityFilters,
      resilience_filter: simulation.filters.resilienceFilters,
      comfort_filter: simulation.filters.comfortFilters,
      co2_filter: simulation.filters.Co2Filters,
      bio_filter: simulation.filters.bioFilters,
      name: simulation.name,
      display_units: simulation.display_units
    };
    simulation.distribution.forEach(element => serialized.distribution.push(element.toServer()));
    return serialized;
  }

  hidrate(serverData: any): Simulation {
    const result = new Simulation();
    Object.assign(result, serverData);
    Object.assign(result.filters.qualityFilters, serverData.air_filter);
    Object.assign(result.filters.resilienceFilters, serverData.resilience_filter);
    Object.assign(result.filters.comfortFilters, serverData.comfort_filter);
    Object.assign(result.filters.Co2Filters, serverData.Co2Filters);
    Object.assign(result.filters.bioFilters, serverData.bioFilters)
    return result;
  }
  createSimulation(simulation: Simulation) {
    const url = `${environment.serverUrl}/simulations/`;
    return this.http.post(url, this.serialize(simulation)).pipe(
      map((newSimulation) => {
        this.messagesService.add(new Message('ok', 'Creada correctamente'));
        return this.hidrate(newSimulation);
      })
    );
  }
  updateSimulation(simulation: Simulation) {
    const url = `${environment.serverUrl}/simulations/${simulation.id}/`;
    return this.http.patch(url, this.serialize(simulation)).pipe(
      map((newSimulation) => {
        this.messagesService.add(new Message('ok', 'Guardado correctamente'));
        return this.hidrate(newSimulation);
      })
    );
  }
  loadSimulations(zoneID) {
    const url = `${environment.serverUrl}/simulations/zone/${zoneID}/`;
    return this.http.get(url).pipe(
      map((simulations: Simulation[]) => {
        const result = [];
        simulations.forEach(simulation => result.push(this.hidrate(simulation)));
        return result;
      })
    );
  }
  downloadReport(space: any, simulationIds: number[], selectdUnits: string,  parameters: string[], download_options: boolean, zone: number[] ) {
    const url = `${environment.serverUrl}/simulations/download_report/`;
    return this.http.post(url, {
      space_id: space.id,
      units: selectdUnits,
      simulations: simulationIds,
      parameters: parameters,
      download_options: download_options,
      zone: zone
    }, {
      responseType: 'blob'
    });
  }
  deleteSimulation(simulation: Simulation) {
    const url = `${environment.serverUrl}/simulations/${simulation.id}/`;
    return this.http.delete(url, this.serialize(simulation)).pipe(
      tap(
        () => this.messagesService.add(new Message('ok', 'Eliminada correctamente'))
      ),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', 'No se ha podido eliminar'));
        throw httpError;
      })
    );
  }
}
