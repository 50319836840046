import { Component, OnInit } from '@angular/core';
import { User } from '../user';
import { AuthService } from '../auth.service';
import { HttpErrorResponse } from '@angular/common/http';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app-new-user',
  templateUrl: './new-user.component.html',
  styleUrls: ['./new-user.component.scss']
})
export class NewUserComponent implements OnInit {
  user: User;
  password1: string;
  password2: string;
  updated = false;

  constructor(
    private authService: AuthService,
    private $state: StateService,
  ) { }

  ngOnInit() {
    this.user = this.authService.user;
  }
  saveUser() {
    this.authService.saveUser(this.user).subscribe(
      () => this.updated = true,
      (error: HttpErrorResponse) => console.log(error)
    );
  }

  setPassword() {
    this.authService.setPassword(this.password1, this.password2).subscribe(
      () => this.$state.go('home.species'),
      () => {}
    );
  }
}
