import { Injectable } from '@angular/core';
import { Message } from './message';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  messages: Message[] = [];

  add(message: Message) {
    this.messages.push(message);
    setTimeout(() => this.dismiss(message), 3000);
  }

  clear() {
    this.messages = [];
  }

  dismiss(message: Message) {
    const idx = this.messages.indexOf(message);
    if (idx >= 0) {
      this.messages.splice(idx, 1);
    }
  }
}
