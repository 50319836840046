import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { SpecieEditorResilienceComponent } from '../specie-editor-resilience/specie-editor-resilience.component';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-resilience',
  templateUrl: './specie-resilience.component.html',
  styleUrls: ['./specie-resilience.component.sass']
})
export class SpecieResilienceComponent implements OnInit {
  @Input() specie;

  resilienceData;
  user: User;

  constructor(
    private modalService: NgbModal,
    private speciesService: SpecieService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.user;
    this.speciesService.getResilience(this.specie.id).subscribe(
      (data => this.resilienceData = data)
    );
  }
  editResilience() {
    const modalRef = this.modalService.open(SpecieEditorResilienceComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.specie = Object.assign({}, this.resilienceData);
    modalRef.result.then(
      (newSpecie) => Object.assign(this.resilienceData, newSpecie),
      (reason: any) => {}
    );
  }
}
