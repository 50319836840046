import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AuthService } from 'src/app/auth/auth.service';
import { User } from 'src/app/auth/user';
import { Specie } from '../specie';
import { SpecieBiophilia } from '../specie-biophilia';
import { SpecieEditorBiophiliaComponent } from '../specie-editor-biophilia/specie-editor-biophilia.component';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-biophilia',
  templateUrl: './specie-biophilia.component.html',
  styleUrls: ['./specie-biophilia.component.sass']
})
export class SpecieBiophiliaComponent implements OnInit {
  @Input() specie: Specie;

  biophiliaData: SpecieBiophilia;
  user: User;

  constructor(
    private modalService: NgbModal,
    private speciesService: SpecieService,
    private authService: AuthService
  ) { }

  ngOnInit() {
    this.user = this.authService.user;
    this.speciesService.getBiophilia(this.specie.id).subscribe(
      (data => this.biophiliaData = data)
    );
  }
  editBiophilia() {
    const modalRef = this.modalService.open(SpecieEditorBiophiliaComponent, {size: 'xl' as 'lg'});
    modalRef.componentInstance.specie = Object.assign({}, this.specie);
    modalRef.componentInstance.biophiliaData = Object.assign({}, this.biophiliaData);
    modalRef.result.then(
      (biophiliaData: SpecieBiophilia) => Object.assign(this.biophiliaData, biophiliaData),
      (reason: any) => {}
    );
  }
}
