import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { SpecieService } from '../specie.service';

@Component({
  selector: 'app-specie-editor-pollution',
  templateUrl: './specie-editor-pollution.component.html',
  styleUrls: ['./specie-editor-pollution.component.sass']
})
export class SpecieEditorPollutionComponent implements OnInit {

  @Input() specie;
  constructor(
    private activeModal: NgbActiveModal,
    private speciesService: SpecieService
  ) { }

  ngOnInit() {
  }
  save() {
      this.speciesService.updatePollution(this.specie).subscribe(
        specie => this.activeModal.close(specie),
        () => {}
      );
  }

  dismiss() {
    this.activeModal.dismiss();
  }

}
