<div>
    <div class="modal-header">
      <h4 class="modal-title" id="modal-basic-title">Datos climáticos</h4>
      <button type="button" class="close" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <form #editClimaForm="ngForm" (ngSubmit)="save()">
        <div class="form-row">
          <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <label for="minTemp">Tª Media mínimas (ºC)</label>
            <input type="number" step="any" [(ngModel)]="space.temperature_min" class="form-control" id="minTemp"
              name="minTemp" placeholder="3.2">
          </div>
          <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <label for="maxTemp">Tª Media máximas (ºC)</label>
            <input type="number" step="any" [(ngModel)]="space.temperature_max" class="form-control" id="maxTemp"
              name="maxTemp" placeholder="43.5">
          </div>
          <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <label for="medTemp">Tª Media mensual (ºC)</label>
            <input type="number" step="any" [(ngModel)]="space.temperature_med" class="form-control" id="medTemp"
              name="medTemp" placeholder="23.5">
          </div>
          <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <label for="daysUnder0">Nº días de Tª mínima &lt; 0ºC</label>
            <input type="number" step="1" [(ngModel)]="space.days_under_0" class="form-control" id="daysUnder0"
              name="daysUnder0" placeholder="5">
          </div>
          <div class="form-group col-xl-4 col-lg-4 col-md-6 col-sm-12">
            <label for="daysOver35">Nº días de Tª máxima &gt; 30ºC</label>
            <input type="number" step="1" [(ngModel)]="space.days_over_35" class="form-control" id="daysOver35"
              name="daysOver35" placeholder="5">
          </div>
          <div class="form-group col-lg-4 col-md-6 col-sm-12">
            <label for="rain">Precipitación total (mm)</label>
            <input type="number" step="any" [(ngModel)]="space.rain" class="form-control" id="rain" name="rain"
              placeholder="255">
          </div>
          <div class="form-group col-lg-2 col-md-6 col-sm-12">
            <label for="snowDays">Nº días nieve</label>
            <input type="number" step="1" [(ngModel)]="space.snow_days" class="form-control" id="snowDays" name="snowDays"
              placeholder="4">
          </div>
          <div class="form-group col-lg-6 col-md-6 col-sm-12">
            <label for="frozenDays">Duración media periodo de heladas</label>
            <input type="number" step="1" [(ngModel)]="space.frozen_days" class="form-control" id="frozenDays"
              name="frozenDays" placeholder="5">
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-lg-3 col-md-6 col-sm-12">
            <label for="windSpeed">Velocidad del viento (km/h)</label>
            <input type="number" step="1" [(ngModel)]="space.wind_speed" class="form-control" id="windSpeed"
              name="windSpeed" placeholder="5">
          </div>
          <div class="form-group col-lg-3 col-md-6 col-sm-12">
            <label for="relativeHumidity">Humedad relativa medida (%)</label>
            <input type="number" step="any" [(ngModel)]="space.relative_humidity" class="form-control"
              id="relativeHumidity" name="relativeHumidity" placeholder="5">
          </div>
          <div class="form-group col-lg-3 col-md-6 col-sm-12">
            <label for="ETP">ETP (mm)</label>
            <input type="number" step="any" [(ngModel)]="space.ETP" class="form-control" id="ETP" name="ETP"
              placeholder="5">
          </div>
          <div class="form-group col-lg-3 col-md-6 col-sm-12">
            <label for="sunTimeMed">Radiación solar (Kw/ m<sup>2</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.sun_time_med" class="form-control" id="sunTimeMed"
              name="sunTimeMed" placeholder="5">
          </div>
          <div class="form-group col-lg-3 col-md-6 col-sm-12">
            <label for="UVA">UVA (Kw/m<sup>2</sup>)</label>
            <input type="number" step="any" [(ngModel)]="space.UVA" class="form-control" id="UVA" name="UVA"
              placeholder="5">
          </div>
        </div>
      </form>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-success" (click)="save()">Guardar</button>
    </div>
  </div>
  