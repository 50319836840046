import { Component, OnInit } from '@angular/core';
import { User } from '../auth/user';
import { AuthService } from '../auth/auth.service';
import { StateService } from '@uirouter/core';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  user: User;
  constructor(private authService: AuthService, private $state: StateService) {
    this.user = this.authService.user;
  }

  ngOnInit() {}

  logout() {
    this.authService.logout();
    this.$state.go('login', {}, {reload: true});
  }

}
