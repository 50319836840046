import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { tap, catchError, map } from 'rxjs/operators';
import { Message } from '../message';
import { MessageService } from '../message.service';
import { Observable, throwError } from 'rxjs';
import { Zone } from './zone';

@Injectable({
  providedIn: 'root'
})
export class SpaceService {
  spaces = [];
  zones: Zone[] = [];
  constructor(
    private http: HttpClient,
    private messagesService: MessageService) { }

  createSpace(space) {
    const url = `${environment.serverUrl}/spaces/`;
    return this.http.post(url, space).pipe(
      tap((newSpace) => {
        this.spaces.push(newSpace);
        this.messagesService.add(new Message('ok', 'Creado correctamente'));
        return newSpace;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  getSpace(spaceId) {
    const url = `${environment.serverUrl}/spaces/${spaceId}/`;
    return this.http.get(url).pipe(
      tap(space => {
        return space;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }
  updateSpace(space) {
    const url = `${environment.serverUrl}/spaces/${space.id}/`;
    return this.http.patch(url, space).pipe(
      tap(updatedSpace => {
        this.messagesService.add(new Message('ok', 'Actualizado correctamente'));
        return updatedSpace;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  deleteSpace(space) {
    const url = `${environment.serverUrl}/spaces/${space.id}/`;
    return this.http.delete(url).pipe(
      tap(() => this.messagesService.add(new Message('ok', 'Eliminado correctamente'))),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  listSpaces() {
    const url = `${environment.serverUrl}/spaces/`;
    return this.http.get(url).pipe(
      tap((spaces: []) => {
        this.spaces.splice(0);
        this.spaces.push(...spaces);
        return spaces;
      }),
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  addDistribution(zoneId: number, distributionId: number) {
    const url = `${environment.serverUrl}/zones/${zoneId}/distribution/${distributionId}/`;
    return this.http.post(url, {}).pipe(
      catchError((httpError: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', httpError.message));
        throw httpError;
      })
    );
  }

  listSpaceZones(spaceId: number): Observable<Zone[]> {
    const url = `${environment.serverUrl}/spaces/${spaceId}/zones/`;
    return this.http.get<Zone[]>(url).pipe(
      tap((zones: []) => {
        this.zones.splice(0);
        this.zones.push(...zones);
        return this.zones;
      }),
      catchError((error: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', error.message));
        throw error;
      })
    );
  }
  createZone(zone: Zone): Observable<Zone> {
    const url = `${environment.serverUrl}/zones/`;
    return this.http.post(url, zone).pipe(
      tap((newZone: Zone) => {
        this.zones.push(newZone);
        this.messagesService.add(new Message('ok', 'Zona creada'));
      }),
      catchError((error: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', error.message));
        throw error;
      })
    );
  }
  updateZone(zone: Zone): Observable<Zone> {
    const url = `${environment.serverUrl}/zones/${zone.id}/`;
    return this.http.patch(url, zone).pipe(
      tap((newZone: Zone) => {
        this.zones.forEach((zoneInList, i) => {
          if (zoneInList.id === newZone.id) {
            this.zones[i] = newZone;
            return;
          }
        });
        this.zones.push(newZone);
        this.messagesService.add(new Message('ok', 'Zona actualizada'));
      }),
      catchError((error: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', error.message));
        throw error;
      })
    );
  }
  deleteZone(zone: Zone) {
    const url = `${environment.serverUrl}/zones/${zone.id}/`;
    this.zones.splice(this.zones.indexOf(zone), 1);
    return this.http.delete(url).pipe(
      tap(() => {
        this.messagesService.add(new Message('ok', 'Zona eliminada'));
      }),
      catchError((error: HttpErrorResponse) => {
        this.messagesService.add(new Message('error', error.message));
        throw error;
      })
    );
  }

  async getSpecificNumberOfSpecimens(spaceId: number): Promise<number> {
    const url = `${environment.serverUrl}/spaces/${spaceId}/specimens/`;
    try {
      const response = await this.http.get<{ total: number }>(url).toPromise();
      return response.total;
    } catch (error) {
      this.messagesService.add(new Message('error', error.message));
      throw error;
    }
  }

  getAllNumberOfSpecimens(): Observable<{ [key: number]: number }>{
    const url = `${environment.serverUrl}/spaces/specimens/`;
    return this.http.get<{ dict_of_specimens: { [key: number]: number } }>(url).pipe(
      // Extract the dict_of_specimens from the response
      map(response => response.dict_of_specimens),
      catchError((error) => {
        console.error('Error fetching specimens:', error);
        return throwError(error);
      })
    );
    }
  


}
