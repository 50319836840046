<div class="container-fluid" *ngIf="biodiversityData">
  <div class="edition_area" *ngIf="user.permissions.indexOf('species.edit_basic_data') > -1" >
    <button type="button" class="btn btn-outline-success float-right" (click)="editBiodiversity()">Editar</button>
  </div>
  <table class="table">
    <tbody>
      <tr class="no-border">
        <td><strong>Nº meses fructificación</strong></td>
        <td>{{ biodiversityData.biodiversity_fructification_months }}</td>
      </tr>
      <tr>
        <td><strong>Estación de Fructificación</strong></td>
        <td>{{biodiversityData.biodiversity_fructification_season}}</td>
      </tr>
      <tr>
        <td><strong>Parte Comestible</strong></td>
        <td>{{biodiversityData.biodiversity_edible}}</td>
      </tr>
      <tr>
        <td><strong>Nº meses Floración</strong></td>
        <td>{{biodiversityData.biodiversity_blooming_months}}</td>
      </tr>
      <tr>
        <td><strong>Estación Floración</strong></td>
        <td>{{biodiversityData.biodiversity_blooming_season}}</td>
      </tr>
      <tr>
        <td><strong>Atracción polinizadores</strong></td>
        <td>{{biodiversityData.biodiversity_pollinators_attraction}}</td>
      </tr>
      <tr>
        <td><strong>Nutricia Lepidópteros</strong></td>
        <td>{{biodiversityData.biodiversity_nutricia_lepidoptera}}</td>
      </tr>
      <tr>
        <td><strong>Fuente de copa</strong></td>
        <td>{{biodiversityData.biodiversity_top_source}}</td>
      </tr>
      <tr>
        <td><strong>Área foliar media</strong></td>
        <td>{{biodiversityData.biodiversity_average_leaf_area}}</td>
      </tr>
      <tr>
        <td><strong>Longevidad</strong></td>
        <td>{{biodiversityData.biodiversity_longevity}}</td>
      </tr>
      <tr>
        <td><strong>Formación de Cavidades</strong></td>
        <td>{{biodiversityData.biodiversity_cavity_formation}}</td>
      </tr>
      <tr>
        <td><strong>Permanencia Hojas</strong></td>
        <td>{{biodiversityData.biodiversity_leaves_permanence}}</td>
      </tr>
      <tr>
        <td><strong>Presencia de Espinas</strong></td>
        <td>{{biodiversityData.biodiversity_thorns_presence}}</td>
      </tr>
    </tbody>
  </table>
</div>
